/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import { getSiteMeta } from '../util/providers';

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  featuredImage,
  headTags,
  children,
}) {
  // console.log('description', description);
  const siteMeta = getSiteMeta();
  const metaTitle = title ? title : siteMeta.siteName;
  const metaDescription = description ? description : siteMeta.siteDescription;
  const headTagsJsx = headTags
    ? ReactHtmlParser(`<meta name="robots" content="noindex">`)
    : '';
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={`%s | ${siteMeta.siteName}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@${siteMeta.primaryTwitter}`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          featuredImage
            ? [
                {
                  property: `og:image`,
                  content: featuredImage.file.fullUrl,
                },
                {
                  property: `og:image:width`,
                  content: featuredImage.file.width,
                },
                {
                  property: `og:image:height`,
                  content: featuredImage.file.height,
                },
                {
                  property: `og:image:alt`,
                  content: featuredImage.alt || '',
                },
              ]
            : []
        )
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      {headTagsJsx}
      {children}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
