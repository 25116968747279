import React from 'react';
// import SEO from '../../components/seo';
// import Layout from '../../layouts/DefaultLayout';

const Page = ({ pageContext, ...props }) => {
  return (
    <div>
      {/* <SEO title="Page Not found" description="Page Not found" /> */}
      <div className="container mt-3">Secure Information Collection</div>
    </div>
  );
};

export default Page;
