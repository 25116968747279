import React, { Component } from 'react';
import Helmet from 'react-helmet';
import HeaderMenu from '../../components/HeaderMenu';
import PropertySearchbarHeader from '../../components/PropertySearchbarHeader';
import Footer from '../../components/Footer';
import PropTypes from 'prop-types';
import { Modal as LastMinuteDealsModal } from '../../components/LastMinuteDeals';
import Modal from '../../components/Modal';
import './styles.scss';

const DefaultLayout = props => {
  const { children, footer, subheader, sidebar, stickyRow } = props;
  return (
    <>
      {sidebar && <Helmet htmlAttributes={{ className: 'has-sidebar' }} />}
      <HeaderMenu />
      <div className={`${subheader ? 'sticky-row' : ''}`}>
        <>
          <div className={`bg-primary`}>
            <div className="container">
              <div className="row">
                <div className="col">
                  {subheader && (
                    <PropertySearchbarHeader context="search bar" {...props} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
        {stickyRow && stickyRow()}
      </div>
      <main>{children}</main>
      {footer && <Footer />}
    </>
  );
};

DefaultLayout.defaultProps = {
  footer: true,
  subheader: true,
  sidebar: false,
};

export default DefaultLayout;
