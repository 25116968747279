import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styles from './styles.module.scss';

import './styles.scss';

const renderMarker = (mapMarker, map, maps, withMarkerImage) => {
  const childProperties = mapMarker?.childProperties;
  const thumbnailImage = mapMarker?.thumbnailImage || '';
  const location = {
    lat: Number(mapMarker.latitude),
    lng: Number(mapMarker.longitude),
  };

  const setMapMarker = (mapMarker, location) => {
    var infowindow;
    if (withMarkerImage) {
      infowindow = new maps.InfoWindow({
        content: `<img class='p-1plus' src='${thumbnailImage}' style="width: 300px; height: 169px;object-fit: cover;" />`,
      });
    } else {
      infowindow = new maps.InfoWindow({
        content: `<p class='text-center mb-0'>${mapMarker.name}</p>`,
      });
    }
    const marker = new maps.Marker({
      position: location,
      map,
      title: mapMarker.name,
    });
    map.setCenter(marker.getPosition());

    google.maps.event.addListener(marker, 'click', function() {
      infowindow.open(map, marker);
    });
    google.maps.event.addListener(infowindow, 'domready', function() {
      $('.gm-style-iw-d').css('overflow', 'hidden');
    });
    if (withMarkerImage) {
      infowindow.open(map, marker);
    }
  };

  childProperties
    ? childProperties.forEach(marker => {
        const location = {
          lat: Number(marker.latitude),
          lng: Number(marker.longitude),
        };
        setMapMarker(marker, location);
      })
    : setMapMarker(mapMarker, location);
};

const getMapOptions = (maps, withMarkerImage) => {
  return {
    fullscreenControl: false,
    styles: [
      {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
    // gestureHandling: 'greedy',
    gestureHandling: 'auto',
    // disableDoubleClickZoom: true,
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.TOP_LEFT,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },
    yesIWantToUseGoogleMapApiInternals: true,
    zoomControl: true,
    clickableIcons: false,
  };
};

const Map = ({ marker, markerPresent, withMarkerImage, zoom, viewType }) => {
  const [reRender, setRerender] = useState(false);

  useEffect(() => {
    setRerender(prevState => {
      return !prevState;
    });
  }, [marker?.name, marker?.latitude, marker?.longitude]);

  return (
    <div className={`google-map-wrapper ${styles.googleMapWrapper}`}>
      <GoogleMapReact
        key={reRender}
        className="h-100"
        yesIWantToUseGoogleMapApiInternals={true}
        options={maps => getMapOptions(maps, withMarkerImage)}
        bootstrapURLKeys={{
          key: 'AIzaSyBFRNkY6qNNsYNLN0uZfBESIe_amri73RI',
          language: 'en',
          region: 'us',
        }}
        defaultCenter={{ lat: 27.22, lng: -80.18 }}
        defaultZoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => {
          renderMarker(marker, map, maps, withMarkerImage);
          if (viewType) {
            map.setMapTypeId(viewType);
          }
        }}
      />
    </div>
  );
};

Map.defaultProps = {
  withMarkerImage: false,
  zoom: 11,
  viewType: null,
};

export default Map;
