import React from 'react';
import { Link } from 'gatsby';
import { getSiteMeta } from '../../util/providers';
import styles from './styles.module.scss';

const ContactUs = ({ onClose }) => {
  const siteMeta = getSiteMeta();
  return (
    <div className="container">
      <div className="row mb-auto mb-lg-2 justify-content-center">
        <div
          id={styles.formFirstColumn}
          className={`col col-10 col-lg-6 text-center px-3`}
        >
          <a className="mr-1plus " href={`mailto:${siteMeta.primaryEmail}`}>
            <img
              className="mb-2"
              width="80"
              height="80"
              src="/contact-email.svg"
            />
          </a>

          <p className="text-center">
            We'd love to help. Send us an email and we'll get back to you as
            soon as possible.
          </p>
          <a
            href={`mailto:${siteMeta.primaryEmail}`}
            className="w-100 text center btn btn-outline-primary text-nowrap mb-3 mb-lg-auto"
          >
            <span className={`font-weight-bold`}>Email us</span>
          </a>
        </div>
        <div className="col col-10 col-lg-6 text-center px-3">
          <Link to="/contact" onClick={() => onClose()} className="mr-1plus ">
            <img
              src="/contact-info.svg"
              className="mb-2"
              width="80"
              height="80"
            />
          </Link>
          <p className="">
            Have a question for us? Ask us about availability, pricing,
            amenities, and more.
          </p>
          <Link
            to="/contact"
            className="w-100 mb-2 mb-lg-auto btn btn-outline-primary text-center"
            onClick={() => onClose()}
          >
            <span className={`font-weight-bold`}>Request more info</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
