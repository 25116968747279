import React, { useEffect } from 'react';

import styles from '../../styles.module.scss';

const months = [
  {
    label: 'January',
    value: 1,
  },
  {
    label: 'February',
    value: 2,
  },
  {
    label: 'March',
    value: 3,
  },
  {
    label: 'April',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'June',
    value: 6,
  },
  {
    label: 'July',
    value: 7,
  },
  {
    label: 'August',
    value: 8,
  },
  {
    label: 'September',
    value: 9,
  },
  {
    label: 'October',
    value: 10,
  },
  {
    label: 'November',
    value: 11,
  },
  {
    label: 'December',
    value: 12,
  },
];

const MonthSelectList = ({
  // styles,
  selectedMonth,
  selectedYear,
  currentYear,
  currentMonth,
  yearRange,
  id,
}) => {
  const dataAttribute = {};
  dataAttribute[`data-month-dropdown${id}`] = 'true';
  useEffect(() => {
    const $monthSelectOptions = $(`#${styles.monthSelect}${id}`).find('option');
    $monthSelectOptions.each((index, option) => {
      if (
        selectedYear === currentYear &&
        Number($(option).val()) < currentMonth + 1
      ) {
        $(option).attr('hidden', true);
      } else {
        $(option).removeAttr('hidden');
      }
    });
  });

  return (
    <>
      <label htmlFor={`${styles.monthSelect}${id}`} className="sr-only">
        Month
      </label>
      <select
        // data-month-dropdown
        {...dataAttribute}
        id={`${styles.monthSelect}${id}`}
        className={
          'custom-select-list calendar border-0 font-weight-bold text-primary px-2'
        }
        value={selectedMonth}
        onChange={e => {
          const selectedDate = new Date(
            $(`[data-year-dropdown${id}]`).val(),
            e.target.value - 1,
            1
          );
          $(`#single-datepicker${id}`).datepicker(
            'setDate',
            new Date(selectedDate)
          );
        }}
      >
        {months.reduce((acc, month, index) => {
          const option = (
            <option
              key={month.value}
              value={month.value}
              // hidden={
              //   selectedYear === currentYear && month.value < currentMonth + 1
              // }
            >
              {month.label}
            </option>
          );

          if (currentYear + yearRange > selectedYear) {
            acc.push(option);
          } else if (month.value <= currentMonth + 1) {
            acc.push(option);
          }

          // acc.push(option);

          return acc;
        }, [])}
      </select>
    </>
  );
};

export default MonthSelectList;
