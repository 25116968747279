export const serializeSimple = formData => {
  const serializedFormData = Object.entries(formData)
    .reduce((acc, [key, value]) => {
      if (value) {
        acc.push(`${acc.length > 0 ? `&${key}=${value}` : `${key}=${value}`}`);
      }
      return acc;
    }, [])
    .join('');
  return serializedFormData;
};
