import React, { useEffect } from 'react';
import './styles.scss';

const loadiFrame = item => {
  var iframe = document.createElement('iframe');
  iframe.src =
    item.dataset.iframeSrc +
    (item.dataset.iframeSrc.includes('&')
      ? '&enablejsapi=1&rel=0'
      : '?enablejsapi=1&rel=0');
  iframe.allowFullscreen = true;
  iframe.frameBorder = 0;
  const iframeParent = item.parentNode;
  iframeParent.appendChild(iframe);
  iframe.className = 'lazy lazy-reveal';
};
function loadFullImage(item) {
  if (!item || !item.dataset.srcset) return;
  var img = item.cloneNode();
  img.onload = addImg;
  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if (item.getAttribute('data-srcset') || item.getAttribute('data-sizes')) {
    img.setAttribute('srcset', item.getAttribute('data-srcset') || '');
    img.setAttribute('sizes', item.getAttribute('data-sizes') || '');
    if (isIE11) {
      img.setAttribute('src', item.getAttribute('data-srcset').split(' ')[0]);
    }
  }
  img.classList.remove('lazy-preview', 'lazy');
  img.classList.add('lazy-reveal');
  function addImg() {
    const imageParent = item.parentNode;
    imageParent.appendChild(img).addEventListener('animationend', function (e) {
      var containsChild = imageParent.contains(item);
      if (containsChild && item) {
        e.target.alt = item.alt || '';
        imageParent.removeChild(item);
        e.target.classList.remove('lazy-reveal');
      }
    });
    img.onload = null;
  }
}

const LazyImage = ({ children, ...props }) => {
  useEffect(() => {
    if (!window.lazyLoadInstance) {
      window.lazyLoadInstance = (() => {
        const imageObserver = new IntersectionObserver(
          (entries, imgObserver) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                const lazyImage = entry.target;
                if (lazyImage.dataset.iframeSrc) {
                  loadiFrame(lazyImage);
                } else {
                  loadFullImage(lazyImage);
                }
                imgObserver.unobserve(lazyImage);
              }
            });
          },
          { rootMargin: '200px 0px' }
        );

        return {
          update: () => {
            /*
              In scenario where useEffect is called on each component render (removing
              the array as second argument in useEffect fuction), there are no elements
              with the '.lazy-preview' class, meaning the observer never gets set on these elements.
            */
            const elements = Array.from(
              document.querySelectorAll('.lazy-preview')
            );

            document.querySelectorAll('iframe').forEach(item => {
              if (
                `${item.src || window.location.origin}`.indexOf(
                  window.location.origin
                ) === 0
              ) {
                try {
                  const iFrameNestedElements = item.contentWindow.document.body.querySelectorAll(
                    '.lazy-preview'
                  );
                  elements.push(...Array.from(iFrameNestedElements));
                } catch (e) { }
              }
            });
            elements.forEach(element => {
              if (!element.dataset.lazyObserved) {
                element.dataset.lazyObserved = true;
                imageObserver.observe(element);
              }
            });
          },
        };
      })();
    }
    window.lazyLoadInstance.update();
    return () => {
      window.lazyLoadInstance = null;
    };
  }, []);

  const {
    alt,
    src,
    srcset,
    sizes = '100vw',
    iframeSrc,
    className = '',
    style,
    width,
    height,
    smallUrl,
    dataId,
    // siteFileInstanceId,
    partOfCollection,
    collectionType,
  } = props;

  // console.log('siteFileInstanceId', siteFileInstanceId);
  const fileInstance = {};
  const fileCollection = {};
  if (partOfCollection) {
    fileCollection[`data-file-collection-${collectionType}`] = 'true';
  } else {
    fileInstance[`data-file-instance`] = 'true';
  }

  return (
    <img
      data-id={props[`data-id`]}
      key={props.key ? props.key : props[`data-id`]}
      // data-site-file-instance
      {...fileCollection}
      {...fileInstance}
      alt={alt}
      className={`lazy lazy-preview ${className}`}
      src={src}
      width={width}
      height={height}
      data-src={src}
      data-srcset={srcset}
      data-sizes={sizes}
      data-iframe-src={iframeSrc}
      style={style}
    />
  );
};

LazyImage.defaultProps = {
  partOfCollection: false,
};

export default LazyImage;
