import React from 'react';
import SEO from '../components/seo';
import Layout from '../layouts/DefaultLayout';

const NotFoundPage = ({ pageContext, ...props }) => {
  return (
    <Layout searchbarLabel="Page Not found">
      <SEO title="Page Not found" description="Page Not found" />
      <div className="container mt-3"></div>
    </Layout>
  );
};

export default NotFoundPage;
