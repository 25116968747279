import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import * as Yup from 'yup';
import './styles.scss';
import UserFormInputs from '../../../page-share-photos/components/UserFormInputs';
import FormFeedback from '../../../../components/Forms/FormFeedback';

const RatingStar = ({ rating, onHover, onSelect }) => {
  const { index, active } = rating;
  return (
    <button
      type="button"
      className="btn btn-link p-0"
      onMouseEnter={e => {
        onHover(index);
      }}
      onClick={() => onSelect(index)}
    >
      {active ? <img src="/rating-on.svg" /> : <img src="/rating-off.svg" />}
    </button>
  );
};

const UPDATE_GUEST_MEMORY = gql`
  mutation updateGuestMemory(
    $guestInput: GuestMemoryUpdateInput!
    $token: String!
  ) {
    updateGuestMemory(
      # data: { fiveStarRating: 5, body: "Greatest Place Ever" }
      data: $guestInput
      where: { token: $token }
    ) {
      id
      emailAddress
      fiveStarRating
      body
    }
  }
`;

const CREATE_GUEST_MEMORY = gql`
  mutation createGuestMemory($guestInput: GuestMemoryCreateInput!) {
    createGuestMemory(data: $guestInput) {
      token
    }
  }
`;

const RatingForm = ({
  loading = null,
  token,
  create,
  onSuccess,
  location,
  properties,
}) => {
  const params = new URLSearchParams(location && location.search);

  const [propertyId, setPropertyId] = useState(null);
  useEffect(() => {
    setPropertyId(params.get('propertyId') || null);
  }, []);
  const validationSchemaCreate = Yup.object().shape({
    emailAddress: Yup.string()
      .email('Please enter a valid email')
      .required('Email is required'),
    fullName: Yup.string().required('Name is required'),
    propertyId: Yup.string().required('Property is required'),
    fiveStarRating: Yup.string().required('Rating is required'),
    body: Yup.string().required('Message is required'),
  });
  const validationSchemaUpdate = Yup.object().shape({
    fiveStarRating: Yup.string().required('Rating is required'),
    body: Yup.string().required('Message is required'),
  });
  const defaultRatings = Array.from({ length: 5 }).map((_, index) => ({
    index: index + 1,
    active: false,
  }));
  const [ratings, setRatings] = useState(defaultRatings);
  const [ratingSelected, setRatingSelected] = useState(false);
  const updateRatingsOnHover = ({ active, index: ratingIndex }) => {
    if (!ratingSelected) {
      return ratings.reduce((acc, rating, index) => {
        if (index + 1 <= ratingIndex) {
          acc.push({ ...rating, active: true });
        } else {
          acc.push({ ...rating, active: false });
        }
        return acc;
      }, []);
    }
    return ratings;
  };
  const updateRatingsOnLeaveHover = ratings => {
    if (!ratingSelected) {
      return ratings.map((rating, index) => {
        return { ...rating, active: false };
      });
    }
    return ratings;
  };

  const updateRatingsOnSelect = ratingIndex => {
    return ratings.map((rating, index) => {
      if (index + 1 <= ratingIndex) {
        return { ...rating, active: true };
      }
      return { ...rating, active: false };
    });
  };

  return (
    <Mutation mutation={create ? CREATE_GUEST_MEMORY : UPDATE_GUEST_MEMORY}>
      {(guestMemoryMutation, { loading, data: guestMemory }) => {
        return (
          <Formik
            enableReinitialize={true}
            validationSchema={
              create ? validationSchemaCreate : validationSchemaUpdate
            }
            initialValues={{
              fiveStarRating: '',
              fullName: params.get('name') ? params.get('name') : '',
              emailAddress: params.get('email') ? params.get('email') : '',
              // propertyId: params.get('propertyId')
              //   ? params.get('propertyId')
              //   : '',
              body: '',
              propertyId: propertyId ? propertyId : '',
            }}
            onSubmit={async (
              values,
              {
                setFieldValue,
                setSubmitting,
                setStatus,
                handleChange,
                resetForm,
                setErrors,
              }
            ) => {
              const input = { ...values };
              if (!create) {
                delete input.emailAddress;
                delete input.fullName;
                delete input.propertyId;
                // input.token = token;
                // guestInput.guestMemoryUpdateInput = valuesClone;
              } else {
                input.propertyId = Number(input.propertyId);
              }
              const guestInput = create
                ? {
                    variables: { guestInput: input },
                  }
                : {
                    variables: { guestInput: input, token },
                  };
              try {
                setSubmitting(true);
                const { data } = await guestMemoryMutation(guestInput);
                if (data) {
                  setSubmitting(false);
                  resetForm();
                  !create &&
                    setStatus({
                      success: 'Thank you for your submission.',
                    });
                  if (create) {
                    onSuccess(data?.createGuestMemory?.token || null);
                  }
                }
              } catch (err) {
                setStatus({
                  error:
                    'There was a problem with your submission. Please check your data and try again',
                });
                setErrors({
                  message:
                    'There was a problem with your submission. Please check your data and try again',
                });
                console.log('err', err);
              }
            }}
            render={formikProps => {
              const {
                values,
                handleChange,
                setFieldValue,
                handleSubmit,
                isSubmitting,
                errors,
                status,
                touched,
                handleBlur,
                submitCount,
              } = formikProps;

              return (
                <form onSubmit={handleSubmit}>
                  <>
                    <div>
                      <div className="form-group w-100 mr-1 mb-3">
                        <label htmlFor="name">
                          <b>How would you rate us?</b>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <div
                          className="d-flex"
                          onMouseLeave={() => {
                            const updatedRatings = updateRatingsOnLeaveHover(
                              ratings
                            );
                            setRatings(updatedRatings);
                          }}
                        >
                          {ratings.map((rating, index) => {
                            return (
                              <div key={index}>
                                <RatingStar
                                  onHover={index => {
                                    const updatedRatings = updateRatingsOnHover(
                                      rating
                                    );
                                    setRatings(updatedRatings);
                                  }}
                                  onSelect={index => {
                                    const updatedRatings = updateRatingsOnSelect(
                                      index
                                    );
                                    setRatings(updatedRatings);
                                    setRatingSelected(true);
                                    setFieldValue('fiveStarRating', index);
                                  }}
                                  rating={rating}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="small text-danger mt-1">
                          {touched.fiveStarRating &&
                            errors.fiveStarRating &&
                            errors.fiveStarRating}
                        </div>
                      </div>
                      <div className="form-group w-100 mr-1">
                        <label className="mb-2" htmlFor="message">
                          <b>Message</b>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <textarea
                          name="body"
                          id="message-text-area"
                          className="form-control"
                          onChange={handleChange}
                        />
                        <div className="small text-danger mt-1">
                          {touched.body && errors.body && errors.body}
                        </div>
                      </div>

                      <div className="d-flex flex-column flex-lg-row justify-content-between mt-3">
                        {create && (
                          <>
                            <UserFormInputs
                              properties={properties}
                              urlParams={params}
                              formikProps={formikProps}
                            />
                            {/* <div className="form-group w-100 mr-1 ">
                            <label className="mb-2" htmlFor="fullName">
                              <b>Name</b>
                              <span className="text-danger ml-1">*</span>
                            </label>
                            <input
                              defaultValue={values.fullName}
                              type="text"
                              className="form-control rounded"
                              name="fullName"
                              id="fullName"
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div className="small text-danger mt-1">
                              {touched.fullName &&
                                errors.fullName &&
                                errors.fullName}
                            </div>
                          </div>
                          <div className="form-group w-100 mx-1">
                            <label className="mb-2" htmlFor="email">
                              <b>Email</b>
                              <span className="text-danger ml-1">*</span>
                            </label>
                            <input
                              defaultValue={values.emailAddress}
                              name="emailAddress"
                              type="email"
                              className="form-control rounded"
                              id="email"
                              
                              placeholder=""
                              onChange={handleChange}
                            />
                            <div className=" small text-danger mt-1">
                              {touched.emailAddress &&
                                errors.emailAddress &&
                                errors.emailAddress}
                            </div>
                          </div>

                          <div className="form-group w-100 ml-1">
                            <label className="mb-2" htmlFor="property">
                              <b>Property</b>
                              <span className="text-danger ml-1">*</span>
                            </label>
                            <select
                              name="propertyId"
                              className="form-control custom-select-list"
                              defaultValue={values.propertyId}
                              onChange={handleChange}
                            >
                              <option hidden value="">
                                Select a property
                              </option>

                              <option value="1">Bella Vista</option>
                              <option value="2">Oceania</option>
                            </select>
                            <div className="small text-danger mt-1">
                              {touched.propertyId &&
                                errors.propertyId &&
                                errors.propertyId}
                            </div>
                          </div> */}
                          </>
                        )}
                      </div>
                      <FormFeedback
                        errors={status?.error}
                        isVisible={
                          status?.error ||
                          status?.success ||
                          (Object.keys(errors).length > 0 && submitCount > 0)
                        }
                        alert={
                          Object.keys(errors).length > 0 && submitCount > 0
                        }
                      >
                        {status?.success}
                      </FormFeedback>
                      <button
                        type="submit"
                        id="share-now-button"
                        className="btn btn-secondary text-primary font-weight-bold"
                        //   disabled={isSubmitting || !objectIsEmpty(errors)}
                        //   onClick={onSubmit}
                      >
                        {!loading && <span>Share Now</span>}
                        {loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </div>
                  </>
                </form>
              );
            }}
          />
        );
      }}
    </Mutation>
  );
};

export default RatingForm;
