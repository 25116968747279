import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const convertYoutubeUrlToEmbed = url => {
  if (url.match(/youtube|youtu\.be/)) {
    const parameterUrl = url.match(/\?v=(.*)/);
    if (parameterUrl) {
      return `https://www.youtube.com/embed/${parameterUrl[1]}`;
    }
    const shortUrl = url.match(/youtu.be\/(.*)/);
    if (shortUrl) {
      return `https://www.youtube.com/embed/${shortUrl[1]}`;
    }
    return url;
  }
  return null;
};

const Embed = ({ url, aspectRatio }) => {
  const normalizedUrl = convertYoutubeUrlToEmbed(url); // Only youtube embeds supported at this time.
  const aspectRatioClassSuffix = aspectRatio === '4x3' ? '4-3' : '16-9';
  return normalizedUrl ? (
    <figure
      className={`bhip-block-embed bhip-embed-aspect-${aspectRatioClassSuffix}`}
    >
      <div className="bhip-block-embed__wrapper">
        <iframe
          width="660"
          height="371"
          src={`${normalizedUrl}?feature=oembed`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </figure>
  ) : null;
};

Embed.propTypes = {
  url: PropTypes.string.isRequired,
  aspectRatio: PropTypes.oneOf(['16x9', '4x3']),
};
export default Embed;
