import React, { useState, useEffect } from 'react';

const UserFormInputs = ({ formikProps = {}, urlParams = {}, properties }) => {
  const { values, errors, touched, handleChange, handleBlur } = formikProps;

  //   const propertyId =
  //     urlParams.get && urlParams.get('propertyId')
  //       ? urlParams.get('propertyId')
  //       : '';

  return (
    <>
      <div className="form-group w-100 mr-1 ">
        <label className="mb-2" htmlFor="fullName">
          <b>Name</b>
          <span className="text-danger ml-1">*</span>
        </label>
        <input
          value={values?.fullName}
          type="text"
          className="form-control rounded"
          name="fullName"
          id="fullName"
          placeholder=""
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="small text-danger mt-1">
          {touched?.fullName && errors?.fullName && errors?.fullName}
        </div>
      </div>
      <div className="form-group w-100 mx-1">
        <label className="mb-2" htmlFor="email">
          <b>Email</b>
          <span className="text-danger ml-1">*</span>
        </label>
        <input
          value={values?.emailAddress}
          name="emailAddress"
          type="email"
          className="form-control rounded"
          id="email"
          placeholder=""
          onChange={handleChange}
        />
        <div className=" small text-danger mt-1">
          {touched?.emailAddress &&
            errors?.emailAddress &&
            errors?.emailAddress}
        </div>
      </div>
      <div className="form-group w-100 ml-1">
        <label className="mb-2" htmlFor="propertyId">
          <b>Property</b>
          <span className="text-danger ml-1">*</span>
        </label>
        <select
          name="propertyId"
          className="form-control custom-select-list"
          value={values?.propertyId}
          // defaultValue="2"
          onChange={handleChange}
          key={values?.propertyId}
        >
          <option hidden value="">
            Select a property
          </option>
          {properties &&
            properties
              .filter(property => !property.name.includes('+'))
              .map((property, index) => {
                return (
                  <option key={index} value={`${String(property.id)}`}>
                    {property.name}
                  </option>
                );
              })}

          <option value="2">Oceania</option>
        </select>
        <div className="small text-danger mt-1">
          {touched?.propertyId && errors?.propertyId && errors?.propertyId}
        </div>
      </div>
    </>
  );
};

export default UserFormInputs;
