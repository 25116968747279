import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../layouts/DefaultLayout';
import CMSContent from '../../components/CMSContent';
import FAQEntryList from '../../components/FAQEntryList';

const IndexPage = ({ data, pageContext, ...props }) => {
  const { sitePage } = data?.bhip;
  return (
    <Layout searchbarLabel={sitePage?.title}>
      <>
        <SEO
          title={sitePage?.seoTitle}
          description={sitePage?.seoDescription}
          featuredImage={sitePage?.featuredImage}
          headTags={sitePage?.headTags}
        />
        <div className="container mt-3">
          <CMSContent blockData={sitePage?.cmsContent?.blockData}>
            {sitePage?.cmsContent?.html}
          </CMSContent>
          <FAQEntryList
            faqEntries={sitePage?.faqEntries}
            className="mt-3"
            header={false}
          ></FAQEntryList>
        </div>
      </>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    #, $propertyForeignKey: Int
    bhip {
      # property(where: { id: $propertyForeignKey }) {
      #   id
      #   additionalMessaging
      #   priceOffSeason
      #   label
      #   key
      #   name
      #   description
      #   sleeps
      #   bedrooms
      #   bathrooms
      #   type
      #   latitude
      #   longitude
      #   group
      #   streetAddress1
      #   virtualTours {
      #     property {
      #       name
      #     }
      #     url
      #     additionalMessaging
      #     thumbnailImage {
      #       alt
      #       destination
      #       caption
      #       file {
      #         versions {
      #           key
      #           fullUrl
      #           height
      #           width
      #         }
      #       }
      #     }
      #   }
      #   propertyListings {
      #     propertyUrl
      #     platform {
      #       name
      #       key
      #     }
      #   }
      #   amenities {
      #     name
      #     key
      #   }
      # }
      sitePage(where: { slug: $slug }) {
        title
        excerpt
        slug
        cmsContent {
          html
          blockData {
            key
            data
          }
        }
        type
        status
        seoTitle
        seoDescription
        headTags
        references {
          type
          foreignKey
        }
        publishDate
        featuredImage {
          alt
          file {
            fullUrl
            width
            height
            versions {
              key
              width
              height
              fullUrl
              mimetype
            }
          }
        }
        featuredImageSecondary {
          alt
          file {
            versions {
              key
              fullUrl
              mimetype
            }
          }
        }
        featuredFileCollection {
          files {
            alt
            mimetype
            fullUrl
            width
            height
            versions {
              key
              fullUrl
              width
              height
              mimetype
            }
          }
        }
        faqEntries {
          id
          question
          answer
          sort
        }
        tags {
          key
          tagGroup {
            key
          }
        }
      }
    }
  }
`;

export default IndexPage;
