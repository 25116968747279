import React, { useState } from 'react';
import PropertySearchForm from '../PropertySearchForm';
import styles from './styles.module.scss';
import Modal from '../Modal';

const PropertySearchbar = ({
  searchbarLabel,
  showModalSearchToggleButton,
  withModal,
  pageName,
  includeOtherSearchParams,
  hideSearchBar,
  ...props
}) => {
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
  return (
    <div
      expand="md"
      className={`${styles.propertySearchbarHeader} pl-0 pr-0 d-flex justfiy-content-between align-items-center`}
    >
      <h1 className={`${styles.headerText} text-white my-1plus`}>
        <a href="#" className="link-unstyled">
          {searchbarLabel}
        </a>
      </h1>
      <div className={`${styles.navbarNav}`}>
        {showModalSearchToggleButton && pageName === 'default' && (
          <button
            id="mobile-searchbar-search-button"
            className={`${styles.mobileSearchIconButton} btn btn-secondary d-flex d-lg-none rounded font-weight-bold text-primary float-right`}
            onClick={() => setSearchModalIsOpen(true)}
          >
            <img src="/search.svg" height="21.8" width="21.8" />
          </button>
        )}
      </div>
      {
        hideSearchBar != true && <div className="d-none d-lg-flex">
          <PropertySearchForm
            modalIsOpen={searchModalIsOpen}
            id="header-search-form"
            onClose={() => setSearchModalIsOpen(false)}
          />
        </div>
      }
      <Modal
        isOpen={searchModalIsOpen}
        title={'Beach House Rentals'}
        onClose={() => setSearchModalIsOpen(false)}
      >
        <PropertySearchForm
          modalIsOpen={searchModalIsOpen}
          id="header-search-form"
          onClose={() => setSearchModalIsOpen(false)}
        />
      </Modal>
    </div>
  );
};

PropertySearchbar.defaultProps = {
  pageName: 'default',
  showModalSearchToggleButton: true,
  includeOtherSearchParams: false,
};

export default PropertySearchbar;
