import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

export const relatedSitePagesFragment = graphql`
  fragment RelatedSitePages on BHIP_SitePage {
    relatedSitePages {
      id
      title
      slug
      publishDate
      featuredImage {
        alt
        file {
          fullUrl
          width
          height
          versions {
            key
            width
            height
            fullUrl
            mimetype
          }
        }
      }
    }
  }
`;
export const sitePageDataFragment = graphql`
  fragment SitePageData on BHIP_SitePage {
    title
    excerpt
    slug
    cmsContent {
      html
      blockData {
        key
        data
      }
    }
    type
    status
    seoTitle
    seoDescription
    headTags
    references {
      type
      foreignKey
    }
    publishDate

    featuredImage {
      alt
      file {
        fullUrl
        width
        height
        versions {
          key
          width
          height
          fullUrl
          mimetype
        }
      }
    }
    featuredImageSecondary {
      alt
      file {
        versions {
          key
          fullUrl
          mimetype
        }
      }
    }
    featuredFileCollection {
      files {
        alt
        mimetype
        fullUrl
        width
        height
        versions {
          key
          fullUrl
          width
          height
          mimetype
        }
      }
    }
    faqEntries {
      id
      question
      answer
      sort
    }
    tags {
      key
      tagGroup {
        key
      }
    }
  }
`;

// import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
// import { renderToString } from 'react-dom/server';
// import photoswipe from '../../util/photoswipe';
// import { configureScrollSpy, removeScrollSpy } from '../../util/scrollSpy';
// import JumpMenu from '../JumpMenu';
// import './styles.scss';

// import { plugins, getPluginDataKey } from '../../util/cms';
import '../../styles/cms-blocks.scss';
// function flatMap(array, fn) {
//   var result = [];
//   for (var i = 0; i < array.length; i++) {
//     var mapping = fn(array[i]);
//     result = result.concat(mapping);
//   }
//   return result;
// }

// const generateKey = pre => {
//   return `${pre}_${new Date().getTime()}`;
// };
// /*
// Plugin code should be refactored and encapsualted once mature.

// Possible Interfaces.

// new Plugin({
//   name: String,
//   component: React.Component
// })

// OR

// cmsPlugins.registerPlugin({
//   name: String,
//   component: React.Component
// })

// */

// const NextArrow = props => {
//   const { className, style, onClick } = props;
//   return (
//     <button
//       className={'slick-next'}
//       style={{ ...style, display: 'block' }}
//       onClick={onClick}
//     >
//       {/* <Next width="23" /> */}
//     </button>
//   );
// };

// const PrevArrow = props => {
//   const { className, style, onClick } = props;
//   return (
//     <button
//       className={'slick-prev'}
//       style={{ ...style, display: 'block' }}
//       onClick={onClick}
//     >
//       {/* <Previous width="23" /> */}
//     </button>
//   );
// };

// const converOptionsToJSON = options => {
//   try {
//     return JSON.parse(options);
//   } catch (err) {
//     return {};
//   }
// };
// const findPlugin = node => {
//   const foundPlugin = plugins.find(plugin => {
//     const regEx = new RegExp(`\\sbhip:${plugin.name}`);
//     if (node.data.match(regEx)) {
//       return true;
//     }
//   });
//   if (foundPlugin) {
//     const optionsRegEx = new RegExp(`\\sbhip:${foundPlugin.name} (.*)`);
//     const optionsMatch = node.data.match(optionsRegEx);
//     const options = optionsMatch ? converOptionsToJSON(optionsMatch[1]) : {};
//     return {
//       ...foundPlugin,
//       options,
//     };
//   }

//   return null;
// };

// const getChildren = node => {
//   // console.log('node.type', node.type);
//   if (node.type === 'tag') {
//     //  console.log('RETURNING', node);
//     return node;
//   }
//   return getChildren(node.next);
// };

// const CMSContent = ({ children, cmsData, ...props }) => {
//   const foundPlugins = [];
//   function transform(node, index) {
//     if (node.type === 'comment') {
//       const foundPlugin = findPlugin(node);
//       if (foundPlugin) {
//         const pluginDataKey = getPluginDataKey(
//           foundPlugin,
//           foundPlugin.options
//         );
//         const pluginData = pluginDataKey ? cmsData[pluginDataKey] : {};
//         if (!pluginData) return null; // GraphQL query present, but unable to query successfully. Skipping component. May change error handling in future. Possible actions: Stop build?, Warning Logs?
//         const PluginComponent = foundPlugin.component();

//         return (
//           <>
//             <React.Fragment key={index}>
//               <PluginComponent {...pluginData} {...foundPlugin.options} />
//             </React.Fragment>
//           </>
//         );
//       }

//       return null;
//     } else {
//       // console.log('node', node);
//     }
//   }
//   const transformedContent = ReactHtmlParser(children, {
//     transform,
//   });
//   return transformedContent;
// };

/*

const CMSContent = ({ children, cmsData, ...props }) => {
  const foundPlugins = [];
  const transformedContent = ReactHtmlParser(children, {
    transform: function(node, index) {
      if (node.type === 'comment') {
        const foundPlugin = findPlugin(node);
        if (foundPlugin) {
          console.log('foundPlugin', foundPlugin);
          const pluginDataKey = getPluginDataKey(
            foundPlugin,
            foundPlugin.options
          );
          const pluginData = pluginDataKey ? cmsData[pluginDataKey] : {};
          if (!pluginData) return null; // GraphQL query present, but unable to query successfully. Skipping component. May change error handling in future. Possible actions: Stop build?, Warning Logs?
          const PluginComponent = foundPlugin.component();
          return (
            <React.Fragment key={index}>
              <PluginComponent
                data={pluginData.data}
                {...foundPlugin.options}
              ></PluginComponent>
            </React.Fragment>
          );
        }

        return null;
      }
    },
  });

  return transformedContent;
};
*/

import { CMSContent } from '../../../plugins/gatsby-bhip-cms';

export default CMSContent;
