import React from 'react';
// import { CheckCircle, XCircle, AlertTriangle } from 'react-feather';
import styles from './styles.module.scss';

// const FormFeedback = ({ errors, warningMessage, successMessage, onClose }) => {
//   console.group('warningMessage', warningMessage);
//   const errorMessage = message => {
//     return (
//       <div
//         className="alert alert-danger alert-dismissible fade show d-flex align-items-start"
//         role="alert"
//       >
//         <XCircle className="mr-1" />
//         <span className={`${styles.feedbackText}`}>
//           {' '}
//           <strong>Failed:</strong> {message}
//         </span>
//         <button
//           type="button"
//           className="close"
//           data-dismiss="alert"
//           aria-label="Close"
//         >
//           <span aria-hidden="true">&times;</span>
//         </button>
//       </div>
//     );
//   };
//   const getFormFeedback = () => {
//     if (errors) {
//       return Array.isArray(errors)
//         ? errors.map(message => errorMessage(message))
//         : errorMessage(errors);
//     }
//     if (successMessage) {
//       return (
//         <div
//           id="success-feedback"
//           className="alert form-feedback alert-success alert-dismissible fade show d-flex align-items-center"
//           role="alert"
//         >
//           <CheckCircle className="mr-1" />
//           <span className={`${styles.feedbackText} `}>
//             <strong className="mr-1">Success:</strong> {successMessage}
//           </span>
//           <button
//             onClick={() => onClose()}
//             type="button"
//             className="close"
//             data-dismiss="alert"
//             aria-label="Close"
//           >
//             <span aria-hidden="true">&times;</span>
//           </button>
//         </div>
//       );
//     }
//     if (warningMessage) {
//       const getWarningMessage = customMessage => {
//         return (
//           <div
//             className={`alert alert-warning alert-dismissible fade show d-flex align-items-center`}
//             role="alert"
//           >
//             <AlertTriangle className={`${styles.alertTriangle} mr-1`} />
//             <span className={`${styles.feedbackText}`}>
//               {' '}
//               <strong>Info:</strong>{' '}
//               {customMessage || 'Please confirm the fields and submit again.'}
//             </span>
//             <button
//               type="button"
//               className="close"
//               data-dismiss="alert"
//               aria-label="Close"
//             >
//               <span aria-hidden="true">&times;</span>
//             </button>
//           </div>
//         );
//       };
//       return warningMessage === true
//         ? getWarningMessage()
//         : getWarningMessage(warningMessage);
//     }
//     return null;
//   };

//   return <>{getFormFeedback()}</>;
// };

const getErrors = (errors = {}) => {
  if (errors === true) {
    return 'There was a problem with your submission. Please try again.';
  }
  if (errors === null) {
    return null;
  }
  if (errors.form) {
    errors = errors.form;
  }
  if (Object.entries(errors).length === 0 && errors.constructor === Object) {
    return null;
  }
  if (Array.isArray(errors.graphQLErrors)) {
    if (errors.graphQLErrors.length > 0) {
      return errors.graphQLErrors[0].message;
    }
  }
  if (Array.isArray(errors)) {
    // return errors[0].message;
    return errors.map(error => (
      <>
        <span>{error}</span>
        <br />
      </>
    ));
  }
  return <span>{String(errors)}</span>;
};
const FormFeedback = ({
  formikProps = {},
  errors,
  isVisible,
  children,
  alert,
  onClose,
}) => {
  const isOpen =
    ((formikProps.status || {}).isSubmitted && !formikProps.isSubmitting) ||
    isVisible ||
    false;
  //    const isVisible = (formikProps.status || {}).isSubmitted || formikProps.isSubmitting || false;
  const errorsFormatted = getErrors(errors);

  const color = errorsFormatted ? 'danger' : 'success';
  const message = errorsFormatted ? errorsFormatted : children;
  const getFeedbackIcon = () => {
    if (errorsFormatted) {
      return <img className="mr-1" src={'/alert-error.svg'} />;
    } else if (alert) {
      return <img className="mr-1" src={'/alert-info.svg'} />;
    }
    return <img className="mr-1" src={'/alert-success.svg'} />;
  };
  const getAlertColor = () => {
    if (errorsFormatted) {
      return 'danger';
    } else if (alert) {
      return 'warning';
    }
    return 'success';
  };

  const getFeedbackMessaging = () => {
    if (errorsFormatted) {
      return (
        <span className={`${styles.feedbackText}`}>
          <b className="mr-1">Failed:</b>
          {errorsFormatted}
        </span>
      );
    } else if (alert) {
      return (
        <span className={`${styles.feedbackText}`}>
          <b className="mr-1">Info:</b>Please confirm the fields and submit
          again.
        </span>
      );
    }
    return (
      <span className={`${styles.feedbackText}`}>
        <b className="mr-1">Success:</b>
        {children}
      </span>
    );
  };
  const getMessage = () => {
    if (errorsFormatted) {
      return 'Failed:';
    } else if (alert) {
      return 'Info:';
    }
    return 'Success:';
  };
  return (
    isOpen &&
    getMessage() && (
      <div
        className={`w-100 alert alert-${getAlertColor()} d-flex alert-dismissible d-flex align-items-center`}
        role="alert"
      >
        <span className="mr-1">
          {getFeedbackIcon()}
          {/* {errorsFormatted ? <XCircle /> : <CheckCircle />} */}
        </span>
        {/* <span> */}
        {/* <b className="mr-1">{errorsFormatted ? 'Failed:' : 'Success:'}</b> */}
        {getFeedbackMessaging()}
        <button
          onClick={() => onClose()}
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {/* </span> */}
      </div>
    )
  );
};

FormFeedback.defaultProps = {
  onClose: () => {},
  warningMessage: 'Please confirm the fields and submit again.',
};

export default FormFeedback;
