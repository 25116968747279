import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LightBox from '../LightBox';
import LazyImage from '../LazyImage';
import { getVersionMap, getThumbnailUrl } from '../../util/helpers';

const getLinkTarget = destination => {
  const isExternalUrl = destination =>
    destination.indexOf('http') === 0 &&
    !destination.match(/beachhousesinparadise\.com/i);
  if (destination) {
    if (isExternalUrl(destination)) {
      return '_blank';
    }
  }
  return null;
};

const getThumbnail = (file, versionMap) => {
  if (versionMap) {
    return file?.width > 640 ? versionMap?.large : versionMap?.medium;
  } else if (file?.mimetype === 'video/youtube') {
    return file?.fullUrl;
  }
};

const Media = ({
  siteFileInstance,
  align,
  alertMessage,
  children,
  tinymce,
  ...props
}) => {
  const { id, destination, alt, caption, file } = siteFileInstance || {};
  const href = destination || file?.fullUrl;
  const versionMap = getVersionMap(file) || {};

  const thumbnail = file?.width > 640 ? versionMap?.large : versionMap?.medium;

  const mimetype = file?.mimetype;
  // const thumbnail = getThumbnail(file, versionMap);
  const target = getLinkTarget(destination);
  const aspectRatioPadding = (thumbnail?.height / thumbnail?.width) * 100;
  const style = {
    paddingTop: aspectRatioPadding ? `${aspectRatioPadding}%` : '66.6992%',
  };

  const thumbnailUrl =
    file && !thumbnail
      ? getThumbnailUrl(file, file?.versions, 'small')
      : thumbnail?.fullUrl;

  const Figure = () => {
    return (
      <figure
        className={`bhip-block-image align${align}`}
        itemProp="associatedMedia"
        itemScope
        itemType="http://schema.org/ImageObject"
        style={{ maxWidth: thumbnail?.width || 640 }}
      >
        <a
          href={href}
          itemProp="contentUrl"
          data-size={`${file?.width}x${file?.height}`}
          data-size-mobile={`${(versionMap && versionMap?.large?.width) ||
            360}x${versionMap?.large?.height || 640}`}
          data-href-mobile={versionMap?.large?.fullUrl || thumbnailUrl}
          onClick={
            alertMessage
              ? () => {
                  alert(alertMessage);
                }
              : null
          }
          target={target}
          style={style}
          className="lazy-parent aspect"
        >
          {(versionMap || mimetype === 'video/youtube') && (
            <>
              <LazyImage
                tinymce={tinymce || null}
                src={versionMap?.tiny?.fullUrl || thumbnailUrl}
                srcset={`${thumbnailUrl} 1w`}
                alt={alt}
                data-id={id}
                className={`bhip-image-${id}`}
                itemProp="thumbnail"
              />
            </>
          )}
        </a>
        {caption && (
          <figcaption itemProp="caption description">{caption}</figcaption>
        )}
      </figure>
    );
  };

  return (
    <>
      {destination || mimetype === 'video/youtube' ? (
        <Figure figure={true} />
      ) : (
        <LightBox>
          <Figure />
        </LightBox>
      )}
    </>
  );
  /*
<!-- bhip:image {"id": {{id}} } -->
<figure {{#isFalsey destination}}data-lightbox{{/isFalsey}} class="bhip-block-image align{{options.align}}" itemProp="associatedMedia" itemScope itemType="http://schema.org/ImageObject">
<a
{{#if destination}}
href="{{{destination}}}"
{{else}}
href="{{{file.url}}}"
{{/if}}
itemProp="contentUrl" data-size="{{file.width}}x{{file.height}}">
  <img
  {{#eq ../options.size "large"}}
    src="{{{file.resizedUrlLarge}}}"
  {{else}}
    src="{{{file.resizedUrlLarge}}}"
  {{/eq}}
  {{#if alt}}
  alt="{{alt}}"
  {{/if}}
  data-id="{{id}}" class="bhip-image-{{id}}" itemProp="thumbnail"/>
</a>
{{#if caption}}
<figcaption itemProp="caption description">{{{caption}}}</figcaption>
{{/if}}
</figure>
<!-- /bhip:image -->`*/
};

export default Media;
