export const configureScrollSpy = target => {
  $('body').scrollspy({ target, offset: 250 });
  const callback = () => {
    const $activeElement = $(`${target} a.active`);

    var leftPos =
      ($activeElement.offset() || {}).left +
      $activeElement.width() +
      $(target).scrollLeft();
    if (leftPos > $(window).width()) {
      $(target).animate({ scrollLeft: leftPos });
    } else if (leftPos < $(window).width() && $(target).scrollLeft() !== 0) {
      $(target).animate({ scrollLeft: 0 });
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  $(window).on('activate.bs.scrollspy', debounce(callback, 1500));
};

export const removeScrollSpy = () => {
  $(window).off('activate.bs.scrollspy');
  $(window).off('click');
  $('body').scrollspy('dispose');
};
