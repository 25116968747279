import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module.scss';

const PseudoSelect = ({ label, children, id }) => {
  useEffect(() => {
    $(document).on(
      'click.bhip.pseudoselect',
      '.dropdown [data-keep-open]',
      function(event) {
        if (!event.target.dataset.close) {
          event.stopPropagation();
        }
      }
    );
    // $(window).on('click', function() {
    //   $(dropdownMenu.current).slideUp();
    // });
    // $('.dropdown-menu.keep-open').on('click.parent-dropdown', function(e) {
    //   $('#property-dropdown-menu').hide();
    //   $('#group-dropdown-menu').hide();
    //   if ($(e.target).attr('id') === 'property-list') {
    //     e.stopPropagation();
    //   }
    // });
    // if (params.get('group')) {
    //   setSelectedGroup(params.get('group').toUpperCase());
    // } else if (!params.get('group')) {
    //   setSelectedGroup(null);
    // }
    // const checkedValues = params.get('amenities')
    //   ? params.get('amenities').split(',')
    //   : [];
    // if (propertiesFromParent) {
    //   updateAmenityTotals(checkedValues, propertiesFromParent);
    // }
    // $(dropdownElement.current).on('click.parent-dropdown', function(e) {
    //   e.stopPropagation();
    // });
    return () => {
      $(document).off('click.bhip.pseudoselect');
    };
  });
  const selectId = {};
  if (id) {
    selectId.id = id;
  }
  return (
    <>
      <div className="dropdown">
        <button
          className={`btn ${styles.pseudoSelect} dropdown-toggle text-left w-100 d-flex align-items-center justify-content-between `}
          type="button"
          data-toggle="dropdown"
          //     ref={dropdownToggle}
          aria-haspopup="true"
          aria-expanded="false"
          {...selectId}
        >
          {label}
        </button>
        <div
          //  ref={dropdownMenu}
          className={`py-0  dropdown-menu w-100`}
          // aria-labelledby="property-list"
          //id="group-dropdown-menu"
        >
          <div data-keep-open>{children}</div>
        </div>
      </div>
    </>
  );
};

export default PseudoSelect;
