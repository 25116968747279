import { navigate } from 'gatsby';
import { serializeSimple } from '../serializeData';

const updatedParams = userInput => {
  const urlParams = new URLSearchParams(location.search);
  const urlDataObject = urlParams.entries
    ? Array.from(urlParams.entries()).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {})
    : {};
  return { ...urlDataObject, ...userInput };
};

const getAdditionalQueryArguments = () => {
  const urlParams = new URLSearchParams(location.search);
  return [
    'bedrooms',
    'sleeps',
    'baths',
    'mode',
    'checkIn',
    'checkOut',
    'flexibilityInDays',
    // 'preserveCheckIn',
  ].reduce((acc, arg) => {
    if (urlParams.get(arg)) {
      acc.push(`${arg}=${urlParams.get(arg)}`);
    }
    return acc;
  }, []);
};

export const updateUrlWithCommaDelimitedString = (
  routeName,
  serializedData = [],
  paramKey
) => {
  const additionalQueryArguments = getAdditionalQueryArguments();
  const serializedDataString =
    serializedData.length > 0 ? `${paramKey}=${serializedData}` : '';
  const queryString =
    additionalQueryArguments && `${additionalQueryArguments.join('&')}`;
  navigate(
    `/${routeName}?${
      queryString ? `${queryString}&` : ''
    }${serializedDataString}`
  );
};

export const updateUrl = (routeName, userInput) => {
  const queryString = serializeSimple(updatedParams(userInput));
  navigate(`/${routeName}${queryString ? `?${queryString}` : ''}`);
};

// export const removeCommaDelimitedUrlParameter = (arg, urlString, parentKey) => {
//   const valueList = urlString.split(`${parentKey}=`)[1].split(',');
//   const slice = urlString.split('');
//   const split = urlString.split(/[&|?]/);
// };
