// import 'cross-fetch/polyfill';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { createHttpLink } from 'apollo-link-http';
// import { InMemoryCache } from 'apollo-cache-inmemory';
import fetch from 'isomorphic-fetch';
// import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
// import introspectionQueryResultData from '../../fragmentTypes.json';

// import introspectionResult from '../introspection-result';

// const fragmentMatcher = new IntrospectionFragmentMatcher(
//   introspectionQueryResultData
// );

import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import Cookies from 'js-cookie';

// export const client = new ApolloClient({
//   // cache: new InMemoryCache({
//   //   possibleTypes: {
//   //     // Property: ['PropertyCombo', 'Property'],
//   //     IProperty: ['PropertyCombo', 'Property'],
//   //     // BHIP_Property: ['PropertyCombo', 'Property'],
//   //   },
//   // }),
//   // cache: new InMemoryCache({ fragmentMatcher }),
//   cache: new InMemoryCache(),
//   // cache: new InMemoryCache({ possibleTypes: introspectionQueryResultData }),
//   link: createUploadLink({
//     uri: `${process.env.BHIP_API_URL}/graphql`,
//     fetch,
//     fetchOptions: { credentials: 'include' },
//   }),
// });

export const client = (() => {
  // const headers = { rejectUnauthorized: false, strictSSL: false }; // authorization: access_token ? `Bearer ${access_token}` : null
  const authLink = setContext((_, { headers }) => {
    let token = new URLSearchParams(window.location.hash.substr(1)).get(
      'token'
    );
    if (token) {
      Cookies.set('token', token);
      window.location.replace(window.location.href.replace(/\#.*/, ''));
    } else {
      token = Cookies.get('token');
    }
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer: ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          },
        },
      }),
    }),
    link: authLink.concat(
      createUploadLink({
        uri: `${process.env.BHIP_API_URL}/graphql`,
        fetch,
        fetchOptions: { timeout: 15000, credentials: 'include' },
      })
    ),
  });
})();
