import React, { useState } from 'react';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';
import styles from './styles.module.scss';
import { getSiteMeta } from '../../util/providers';
import AddressDisplay from './components/AddressDisplay';
import ContactUs from '../ContactUs';
import Modal from '../Modal';
import WithLocation from '../WithLocation';
import { formatPhoneNumber } from '../../util/helpers';

const Footer = ({ location, search }) => {
  const siteMeta = getSiteMeta();
  const [contactUsModalIsOpen, setContactUsModalIsOpen] = useState(false);

  const nodisc = search.nodisc == '1' ? 1 : Cookies.get('nodisc');
  return (
    <footer className={`${styles.container} container mt-5`}>
      <div className={`${styles.innerContainer} container pt-2 pb-3`}>
        <div className="row d-flex flex-column flex-column-reverse flex-xl-row">
          <div
            className={`${styles.linksColumn} col col-12 col-xl-8 d-block d-xl-flex text-center align-items-center pl-0 pr-0`}
          >
            <div className="mr-sm-2 mb-3 mb-xl-0">
              <span>BHIP &#169; {new Date().getFullYear()}</span>
            </div>
            <div className="mr-sm-2 mb-3 mb-xl-0">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div className="mr-sm-2 mb-3 mb-xl-0">
              <Link to="/hurricane-100-refund-policy">Hurricane Policy</Link>
            </div>
            <div className="mr-sm-2 mb-3 mb-xl-0">
              <Link to="/terms">Terms</Link>
            </div>
            <div className="mr-sm-2 mb-3 mb-xl-0">
              <a
                style={{ cursor: 'pointer' }}
                className="btn btn-link text-primary"
                onClick={() => setContactUsModalIsOpen(true)}
                to="/contact"
              >
                Contact Us
              </a>
            </div>
            <div className="mr-sm-2 mb-3 mb-xl-0">
              <Link to="/faq">FAQ</Link>
            </div>
            <div className="mr-sm-2 mb-3 mb-xl-0">
              <Link to="/discount-programs">Top 10 Deals and Discounts</Link>
            </div>
          </div>
          <div className="text-center mt-3 d-block d-xl-none">
            <AddressDisplay siteMeta={siteMeta} />
            <p>{formatPhoneNumber(siteMeta.primaryPhone)}</p>
          </div>
          <div
            className={`${styles.socialColumn} pr-0 col col-12 col-xl-4 mt-2 mt-xl-auto text-center d-xl-flex justify-content-end d-inline-block pl-0 pr-0`}
          >
            <a
              target="_blank"
              href={`https://www.facebook.com/${siteMeta.primaryFacebook}`}
              title="Facebook"
            >
              <img src="/facebook.svg" height="40" width="30" alt="Facebook" />
            </a>
            <a
              target="_blank"
              href={`https://twitter.com/${siteMeta.primaryTwitter}`}
              title="Twitter"
            >
              <img
                src="/twitter.svg"
                alt="Twitter"
                className={`ml-xl-2 ml-2 ${styles.socialLink}`}
                height="40"
                width="30"
              />
            </a>
            <a
              target="_blank"
              href={`https://www.instagram.com/${siteMeta.primaryInstagram}`}
              title="Instagram"
            >
              <img
                className={`ml-xl-2 ml-2 ${styles.socialLink}`}
                alt="Instagram"
                src="/instagram.svg"
                height="40"
                width="30"
              />
            </a>
            <a
              target="_blank"
              title="Youtube"
              href={`https://www.youtube.com/c/${siteMeta.primaryYoutube}`}
            >
              <img
                className={`ml-xl-2 ml-2 ${styles.socialLink}`}
                alt="Youtube"
                src="/youtube.svg"
                height="40"
                width="30"
              />
            </a>

            <a
              target="_blank"
              href="https://www.pinterest.com/BeachHousesInParadise/"
              title="Pinterist"
            >
              <img
                className={`ml-xl-2 ml-2 ${styles.socialLink}`}
                alt="Pinterist"
                src="/pinterest.svg"
                height="40"
                width="30"
              />
            </a>
            <a
              target="_blank"
              href="https://g.page/BeachHousesinParadise"
              title="Google My Business"
            >
              <img
                className={`ml-xl-2 ml-2 ${styles.socialLink}`}
                src="/google-my-business.svg"
                alt="Google My Business"
                height="40"
                width="30"
              />
            </a>
            <a
              target="_blank"
              href="https://www.yelp.com/biz/beach-houses-in-paradise-stuart"
              title="Yelp"
            >
              <img
                className={`ml-xl-2 ml-2 ${styles.socialLink}`}
                alt="Yelp"
                src="/yelp.svg"
                height="40"
                width="30"
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div
            className={`col col-12 col-xl-8 d-none d-xl-flex text-center align-items-left pl-0 pr-0 pt-4 pt-xl-1`}
          >
            <AddressDisplay siteMeta={siteMeta} />
            <p>{formatPhoneNumber(siteMeta.primaryPhone)}</p>
          </div>
        </div>
        {nodisc != 1 && <div className="row">
          <div
            className={`col col-12 col-xl-8 text-left align-items-left pl-0 pr-0 pt-4 pt-xl-1`}
          >
            <p className="text-muted text-small">
              <i className="small">
                Legal notice: The number of bedrooms for our home listings may
                vary from data presented in conventional real-estate listings,
                and data on file with respect to each home's original
                construction. Extra sleep rooms may be based upon bonus rooms,
                lofts, wallbeds, sofabeds and/or other open or non-permanent
                fixtures. The number of bathrooms may also differ, based on the
                installation of outdoor showers, pet-washing facilities &
                similar amenities.
              </i>
            </p>
          </div>
        </div>}
      </div>
      <Modal
        isOpen={contactUsModalIsOpen}
        title={'Beach House Rentals'}
        onClose={() => setContactUsModalIsOpen(false)}
      >
        <ContactUs />
      </Modal>
    </footer>
  );
};

export default WithLocation(Footer);
