import React, { Component, useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const PropertySelectContext = React.createContext();

const PropertySelectProvider = ({ children }) => {
  const [bedroomsState, setBedrooms] = useState(null);
  const [bathsState, setBaths] = useState(null);
  const [sleepsState, setSleeps] = useState(null);
  const setPropertyTypeValue = (name, value) => {
    switch (name) {
      case 'bedrooms':
        setBedrooms(value);
        break;
      case 'baths':
        setBaths(value);
        break;
      case 'sleeps':
        setSleeps(value);
        break;
    }
  };

  return (
    <PropertySelectContext.Provider
      value={{
        state: {
          bedroomsState,
          bathsState,
          sleepsState,
          setBedrooms: bedrooms => setBedrooms(bedrooms),
          setBaths: baths => setBedrooms(baths),
          setSleeps: sleeps => setBedrooms(sleeps),
        },
        setPropertyTypeValue: (name, value) =>
          setPropertyTypeValue(name, value),
      }}
    >
      <>{children}</>
    </PropertySelectContext.Provider>
  );
};

const PropertySelectConsumer = PropertySelectContext.Consumer;

export default PropertySelectProvider;
export { PropertySelectConsumer };
