import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { overflowIndicator } from '../../util/overflowIndicator';
import { configureScrollSpy, removeScrollSpy } from '../../util/scrollSpy';
import './styles.scss';
import WithLocation from '../WithLocation';

const JumpMenu = ({ jumpMenuOptions, location }) => {
  const [isCMSPage, setIsCMSPage] = useState(false);
  let observer = null;
  let stickyTarget = null;
  const params = new URLSearchParams(location?.location?.search);
  const view = params.get('view') || null;

  const setIntersectionObserver = () => {
    // const stickyRow = document.querySelector('[data-cms-sticky-row]');
    stickyTarget = document.querySelector('#observer');
    if (stickyTarget) {
      const subheader = document.querySelector('#post-sub-header');
      $(subheader).css('margin-bottom', '0px');
      observer = new IntersectionObserver(
        (entries, stickyObserver) => {
          entries.forEach(entry => {
            if (entries[0].intersectionRatio === 0) {
              const offset =
                $('#observer').offset().top - $(window).scrollTop();
              offset <= 0 &&
                $(`#${styles.jumpMenuWrapper}`).addClass('sticky-is-floating');
            } else {
              $(`#${styles.jumpMenuWrapper}`).removeClass('sticky-is-floating');
            }
          });
        },
        {
          threshold: [0, 1],
        }
      );
      observer.observe(stickyTarget);
    }
  };

  // const setDropdownJumpMenuIntersectionObserver = () => {
  //   const observer = new IntersectionObserver(
  //     (entries, stickyObserver) => {
  //       entries.forEach(entry => {
  //         if (entries[0].isIntersecting) {
  //           console.log('intersecting!');
  //         }
  //         // if (entries[0].intersectionRatio === 0) {
  //         //   console.log('scrolled1');
  //         // } else {
  //         //   console.log('scrolled2');
  //         // }
  //       });
  //     }
  //     // {
  //     //   threshold: [0, 1],
  //     // }
  //   );
  //   observer.observe(document.querySelector('#tour'));
  // };

  useEffect(() => {
    const cmsContainer = document.querySelector('#cms-content-container');
    // $('.dropdown-toggle').click(e => {
    //   const options = document.querySelectorAll('.dropdown-toggle');
    //   options.forEach(option => {
    //     if (option.id !== e.target.id) {
    //       $(`[data-option="${option.id.slice(1)}"]`).removeClass('show');
    //     }
    //   });
    // });
    // setDropdownJumpMenuIntersectionObserver();

    if (cmsContainer) {
      setIsCMSPage(true);
      setIntersectionObserver();
    }
    overflowIndicator();

    configureScrollSpy('#jump-menu-navigation');

    // const dropdownMenus =
    //   document.querySelectorAll('[data-sticky-jump-menu] .dropdown-menu') || [];

    // dropdownMenus.forEach((dropdownMenu, index) => {
    //   const $dropdownMenu = $(dropdownMenu);
    //   const $dropdownMenuClone = $dropdownMenu.clone();
    //   const $dropdownMenuToggle = $dropdownMenu.prev();

    //   const dropdownToggleClick = e => {
    //     e.preventDefault();
    //     const toggleId = $dropdownMenuToggle.attr('id');
    //     const options = document.querySelectorAll('.dropdown-toggle');
    //     const headerNavOptions = document.querySelectorAll(
    //       '#header-nav .dropdown-menu'
    //     );

    //     headerNavOptions.forEach(option => $(option).removeClass('show'));

    //     options.forEach(option => {
    //       if (option.id.slice(1) !== toggleId.slice(1)) {
    //         $(option).removeClass('show');
    //       }
    //     });

    //     $(`[data-option="${toggleId.slice(1)}"][data-clone]`).toggleClass(
    //       'show'
    //     );
    //     return false;
    //   };

    //   const removeDropdownOnWindowClick = () => {
    //     $dropdownMenuClone.removeClass('show');
    //   };
    //   $dropdownMenuToggle.on('click', dropdownToggleClick);
    //   $dropdownMenu.click(function(event) {
    //     event.stopPropagation();
    //   });
    //   $dropdownMenu.removeAttr('[data-option]');

    //   $dropdownMenuClone.removeClass('d-none');
    //   $dropdownMenuClone.attr('data-clone', true);

    //   var menuItemPos = $dropdownMenu
    //     .closest('[data-jump-menu-item]')
    //     .position();
    //   $('#jump-menu-navigation').after($dropdownMenuClone);
    //   if (menuItemPos) {
    //     $dropdownMenuClone.css({
    //       left: menuItemPos.left,
    //       top: menuItemPos.top + 30,
    //     });
    //   }
    //   $(window).on('click', removeDropdownOnWindowClick);
    // });

    // console.log('window.popper', window.popper);
    return () => {
      observer && observer.unobserve(stickyTarget);
      // $('.dropdown-toggle').unbind();
      $('[data-fixed-row]').unbind('scroll');
      $(window).unbind('resize');
      // $(document)
      //   .find('.dropdown-toggle')
      //   .unbind('click');
      // $(document)
      //   .find('.dropdown-toggle')
      //   .unbind('click');
      // $(window).off(removeDropdownOnWindowClick);
      // $dropdownMenuToggle.off(dropdownToggleClick);
      removeScrollSpy();
    };
  }, []);

  // const JumpMenuItem = ({ option }) => {
  //   return (
  //     <div>
  //       <a
  //         className={`p-0 nav-link`}
  //         key={`anchor_${option?.key}`}
  //         data-anchor={`anchor_${option?.key}`}
  //         href={`#${option?.key}`}
  //       >
  //         {option?.name}
  //       </a>
  //     </div>
  //   );
  // };
  return (
    <>
      {/* {jumpMenuOptions.map((option, index) => {
        if (option?.hide) return null;
        console.log('option?.key', option?.key);
        return <JumpMenuItem option={option} key={`jump_${option?.key}`} />;
      })} */}
      <div
        id="observer"
        className={isCMSPage ? 'd-block' : 'd-none'}
        data-sticky-cms-observer="true"
      ></div>
      <div
        className={`position-sticky`}
        data-jump-menu-wrapper
        id={styles.jumpMenuWrapper}
      >
        <div
          id="jump-menu-navigation"
          data-fixed-row
          className={`${styles.jumpMenuNavbar} d-flex nav-bar bg-white w-100 p-0 navbar-expand-md`}
        >
          <div
            data-sticky-jump-menu="true"
            id="subheader-navigation"
            data-scrollable-row
            className={`${styles.navbarNavigation} d-flex w-auto nav justify-content-start py-2`}
          >
            {jumpMenuOptions
              .filter(option => !option?.hide)
              .map((option, index) => {
                // if (option.dropdownMenu) {
                //   return (
                //     <div
                //       key={`anchor_${option?.key}_${index}`}
                //       data-end-of-list={
                //         index + 1 === jumpMenuOptions.length ? true : false
                //       }
                //       data-jump-menu-item
                //       className={`${
                //         index === 0 ? (isCMSPage ? 'ml-2 ml-sm-0' : '') : 'ml-2'
                //       } mr-2 nav-item jump-menu-nav-item dropdown`}
                //     >
                //       <a
                //         className="dropdown-toggle nav-link p-0"
                //         data-sticky-jump-menu="true"
                //         // href="#"
                //         href={`#${option.key}`}
                //         role="button"
                //         id={`#${option.key}`}
                //         // data-toggle="dropdown"
                //         aria-haspopup="true"
                //         aria-expanded="false"
                //       >
                //         {option.name}
                //       </a>
                //       <div
                //         className="dropdown-menu d-none"
                //         aria-labelledby="dropdownMenuButton"
                //         data-option={`${option.key}`}
                //       >
                //         {option.dropdownMenu.map(dropdownOption => {
                //           return (
                //             <a
                //               key={dropdownOption.key}
                //               className="dropdown-item jump-menu-drop-down-item"
                //               data-option={`${dropdownOption?.slug}`}
                //               href={`#${option.key}`}
                //             >
                //               {dropdownOption.name}
                //             </a>
                //           );
                //         })}
                //       </div>
                //     </div>
                //   );
                // }
                const JumpMenuItem = ({ option }) => {
                  return (
                    <div
                      data-end-of-list={
                        index + 1 === jumpMenuOptions.length ? true : false
                      }
                      data-jump-menu-item
                      className={`${
                        index === 0 ? (isCMSPage ? 'ml-2 ml-sm-0' : '') : 'ml-2'
                      } mr-2 nav-item jump-menu-nav-item`}
                    >
                      <a
                        className={`p-0 nav-link`}
                        href={`#${option.key}`}
                        data-sticky-jump-menu="true"
                      >
                        {option.name}
                      </a>
                    </div>
                  );
                };
                return (
                  <JumpMenuItem
                    option={option}
                    key={`jump_${option?.key}_${index}`}
                  />
                );
              })}
          </div>
        </div>
        <div
          data-overflow-indicator
          style={{ display: 'none' }}
          className={`${styles.overflowArrow} position-absolute text-center`}
        >
          <img src="/arrow-scroll-right.svg" width="8" height="14" />
        </div>
      </div>
    </>
  );
};

export default WithLocation(JumpMenu);
