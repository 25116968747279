import React from 'react';
import Helmet from 'react-helmet';

const getFAQSchema = faqEntries => {
  const faqEntities = faqEntries.map(faqEntry => {
    return {
      '@type': 'Question',
      name: faqEntry.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faqEntry.answer,
      },
    };
  });

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqEntities,
  };
};
const FAQEntryList = ({ faqEntries = [], className, header = true }) => {
  return (
    faqEntries.length > 0 && (
      <div className={className}>
        <Helmet
          script={[
            {
              type: 'application/ld+json',
              innerHTML: JSON.stringify(getFAQSchema(faqEntries)),
            },
          ]}
        />

        {header && <h2>Frequently Asked Questions:</h2>}
        <dl>
          {faqEntries.map(faqEntry => {
            return (
              <React.Fragment key={faqEntry.id}>
                <dt className="mb-1">{faqEntry.question}</dt>
                <dd className="mb-3">
                  <span
                    dangerouslySetInnerHTML={{ __html: faqEntry.answer }}
                  ></span>
                </dd>
              </React.Fragment>
            );
          })}
        </dl>
      </div>
    )
  );
};

export default FAQEntryList;
