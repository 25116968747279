import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { overflowIndicator } from '../../util/overflowIndicator';

const ScrollTable = ({ children, ...props }) => {
  useEffect(() => {
    overflowIndicator();
    return () => {
      $('[data-fixed-row]').unbind('scroll');
      $(window).unbind('resize');
    };
  }, []);

  return (
    <>
      <div data-scroll-table-container className={styles.scrollTable}>
        <div style={{ display: 'none' }} data-overflow-indicator>
          <span
            className={`${styles.rightArrow} d-flex align-items-center position-absolute`}
          >
            <img src="/arrow-scroll-right.svg" width="8" height="30" />
          </span>
        </div>

        <div
          data-fixed-row
          className={`${styles.fixedThTableWrapper} fixedThTableWrapper`}
        >
          {children}
        </div>
        <div data-scrollable-row className={`${styles.scrolledTdTableWrapper}`}>
          {children}
        </div>
      </div>
    </>
  );
};

export default ScrollTable;
