import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../layouts/JumpMenuLayout';
import CMSContent from '../../components/CMSContent';
import styles from './styles.module.scss';
import { getSiteMeta } from '../../util/providers';

// const jumpMenuOptions = [
//   {
//     label: 'Activities',
//     href: '#activities',
//   },
//   {
//     label: 'Attractions',
//     href: '#attractions',
//   },
//   {
//     label: 'Eat and Drink',
//     href: '#eatdrink',
//   },
//   {
//     label: 'Events',
//     href: '#events',
//   },
//   {
//     label: 'Transportation',
//     href: '#transportation',
//   },
// ];

const GuidePage = ({ data, pageContext, ...props }) => {
  // const { siteTagGroup } = data.bhip || {};
  // const { siteTags } = siteTagGroup || {};
  const siteTags = data?.bhip?.siteTagGroup?.siteTags || [];
  const { sitePage } = data?.bhip;
  const siteMeta = getSiteMeta();
  const jumpMenuOptions = siteTags.map(tag => ({
    key: tag.key,
    name: tag.name,
  }));
  return (
    <Layout jumpMenuOptions={jumpMenuOptions} searchbarLabel="Local Area Guide">
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        headTags={sitePage.headTags}
      />
      <div className="container mt-3">
        <p>
          As a guest at {siteMeta.siteName} you enjoy being just minutes away
          from award-winning restaurants, shopping, activities, and attractions.
        </p>
        <p>
          Note: We do not endorse and have no financial relationship with the
          following local businesses, locations, and events
          <span
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            className={`${styles.hideContent}`}
          >
            ...
            <button
              className={`btn btn-link py-0 pl-0 align-baseline`}
              type="button"
            >
              more
            </button>
          </span>{' '}
        </p>
        <div className={`collapse`} id="collapseExample">
          <p>
            There are many things to do in Stuart, Florida's own "Mayberry"
            ("America's Happiest Seaside town"—Coastal Living Magazine, 2016
            Winner). Take in some of the local events and culture during your
            stay.
          </p>
        </div>
        {siteTags.map((siteTag, tagIndex) => {
          return (
            <div key={tagIndex} id={siteTag.key}>
              <h3 className="font-weight-bold">{siteTag.name}</h3>
              <div className="row">
                {siteTag.sitePages
                  .filter(sitePage => sitePage.status === 'PUBLISHED')
                  .map((sitePage = {}, pageIndex) => {
                    const smallUrl =
                      sitePage?.featuredImage?.file?.small?.fullUrl || '';
                    return (
                      <Link
                        key={pageIndex}
                        className="col-sm-12 col-md-6 col-lg-3 mb-3"
                        to={`/${sitePage.slug}`}
                      >
                        <div className="card shadow-sm h-100">
                          <div className="aspect ratio-16x9">
                            <img
                              src={smallUrl}
                              className="card-img-top"
                              alt={sitePage?.featuredImage?.alt || ''}
                            />
                          </div>
                          <div className="card-body">
                            <Link to={`/${sitePage.slug}`}>
                              {sitePage.title}
                            </Link>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </div>
          );
        })}
        <span>
          Looking for more to do near or around {siteMeta.siteName}? Click for a{' '}
          <a href="https://www.google.com/search?q=events+in+stuart">
            sampling of the events
          </a>{' '}
          going on in the Stuart, FL and surrounding Treasure Coast (Martin
          County, Jensen Beach, and Ft Pierce).
        </span>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SiteTagGroup($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      siteTagGroup(where: { key: "local-listing" }) {
        name
        key
        siteTags {
          name
          key
          ... on BHIP_SitePageTag {
            sitePages {
              title
              slug
              status
              featuredImage {
                alt
                file {
                  fullUrl
                  small: version(where: { key: "small" }) {
                    fullUrl
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GuidePage;
