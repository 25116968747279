import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import './styles.scss';
import { Query, Mutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import LazyImage from '../LazyImage';
import { getVersionMap } from '../../util/helpers';
import DynamicModal from '../Modal';
import RandomImageGroup from '../RandomImageGroup';
import Loading from '../Loading';
import { Link } from 'gatsby';
import moment from 'moment';
import ValentinesIcon from '../../images/svg/valentines.svg';

const GET_HOT_DEALS = gql`
  query lastMinuteDeals {
    lastMinuteDeals {
      start
      end
      nightsAvailable
      minStay
      lowestRate
      property {
        id
        key
        name
        calendar {
          start
          rates
          minStay
        }
        sitePage {
          slug
          featuredImageCollection {
            files {
              tiny: version(where: { key: "tiny" }) {
                fullUrl
              }
              medium: version(where: { key: "medium" }) {
                fullUrl
              }
              small: version(where: { key: "small" }) {
                fullUrl
              }
            }
          }
        }
      }
    }
  }
`;

const LastMinuteDeals = ({ onLoad = () => { } }) => {
  // const [hasHoliday, setHasHoliday] = useState(false);
  let hasHoliday = false;
  const { data, error, loading, refetch } = useQuery(GET_HOT_DEALS);
  const lastMinuteDeals = data?.lastMinuteDeals || [];
  console.log('lastMinuteDeals', lastMinuteDeals);
  // console.log(
  //   'lastMinuteDealsGrouped',
  //   lastMinuteDeals.reduce((acc, propertyVacancyRange) => {
  //     if (!acc[propertyVacancyRange.property.key]) {
  //       acc[propertyVacancyRange.property.key] = [];
  //     }
  //     acc[propertyVacancyRange.property.key].push(propertyVacancyRange);
  //     return acc;
  //   }, {})
  // );

  const lastMinuteDealsSorted = lastMinuteDeals.sort(function (a, b) {
    return new Date(a.start) - new Date(b.start); //a.lowestRate - b.lowestRate || a.property.id - b.property.id;
    // if (a.property.id < b.property.id) {
    //   return -1;
    // } else if (a.property.id > b.property.id) {
    //   return 1;
    // } else {
    //   if (a.lowestRate < b.lowestRate) {
    //     return -1;
    //   } else if (a.lowestRate > b.lowestRate) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // }
  });
  console.log('lastMinuteDealsSorted', lastMinuteDealsSorted);
  const lastMinuteDealsGroupedAndSorted = lastMinuteDealsSorted.reduce(
    (acc, propertyVacancyRange, index) => {
      const existingKeys = Object.keys(acc);
      // console.log('propertyVacancyRange.start', propertyVacancyRange.start);
      if (moment(propertyVacancyRange.start) < moment('2023-01-02')) {
        console.log('HOLIDAYs');
        hasHoliday = true;
      }
      if (
        moment('2022-02-14').isBetween(
          moment(propertyVacancyRange.start),
          moment(propertyVacancyRange.end),
          'days',
          '[]'
        )
      ) {
        hasHoliday = true;
        propertyVacancyRange.holiday = 'VALENTINES';
      }
      const keyFound = existingKeys.find(key =>
        key.includes(`-${propertyVacancyRange.property.key}`)
      );
      if (!keyFound) {
        acc[`${index}-${propertyVacancyRange.property.key}`] = [
          propertyVacancyRange,
        ];
      } else {
        acc[keyFound].push(propertyVacancyRange);
      }

      return acc;
    },
    {}
  );

  // let lastMinuteDealsCurrentKey;

  //   const lastMinuteDealsGroupedAndSorted2 = lastMinuteDealsSorted.reduce(
  //   (acc, propertyVacancyRange) => {
  //     if (lastMinuteDealsCurrentKey != propertyVacancyRange.property.key) {
  //       acc[propertyVacancyRange.property.key] = [];
  //     }
  //     acc.push(propertyVacancyRange);
  //     return acc;
  //   },
  //   []
  // );
  // console.log(
  //   'lastMinuteDealsGroupedAndSorted',
  //   lastMinuteDealsGroupedAndSorted
  // );
  useEffect(() => {
    if (loading == false) {
      onLoad({ hasHoliday });
    }
  }, [loading]);

  return (
    // <DynamicModal
    //   disableFocusTrap={true}
    //   title={`Last-Minute ${hasHoliday ? 'Holiday' : 'Staycation'} Deals`}
    //   expanded={true}
    //   isOpen={lastMinuteDeals.length > 0 && modalIsOpen}
    //   onClose={() => setModalIsOpen(false)}
    //   id="holiday-special"
    // >
    <div>
      <p className="mb-2">
        Save on incredible last-minute Florida
        {hasHoliday ? ' Holiday' : ' Staycation'} deals. Book a spontaneous
        beach getaway with your family, friends, or group!
      </p>
      {loading && (
        <div>
          <Loading />
        </div>
      )}
      {lastMinuteDeals.length > 0 &&
        !loading &&
        Object.values(lastMinuteDealsGroupedAndSorted).map(
          (rangesByProperty, index) => {
            console.log(rangesByProperty);
            const property = rangesByProperty[0]?.property;
            //const file = property?.sitePage?.featuredImage?.file || {};
            // const versionMap = getVersionMap(property?.sitePage?.featuredImageCollection?.files[0]);
            // console.log('versionMap', property?.sitePage?.featuredImageCollection?.files[0])
            let lowestRateInRanges = rangesByProperty.reduce((acc, range) => {
              if (range.lowestRate < acc) acc = range.lowestRate;
              return acc;
            }, 100000);
            let lowestMinStayInRanges = rangesByProperty.reduce(
              (acc, range) => {
                if (range.minStay < acc) acc = range.minStay;
                return acc;
              },
              100000
            );
            return (
              <Link
                key={property?.sitePage?.slug}
                to={`/${property?.sitePage?.slug}?source=lastminutedeals`}
                className="d-flex mb-3 mb-lg-2 align-items-center"
              >
                {
                  <>
                    {/* <LazyImage
                      src={versionMap && versionMap.tiny.fullUrl}
                      srcset={`${versionMap &&
                        versionMap.small.fullUrl} ${versionMap &&
                        versionMap.small.width}w`}
                      itemProp="thumbnail"
                      className="no-resize"
                      width="160"
                      //   style={{ width: '92px' }}
                    /> */}
                    <div>
                      <div class="position-relative property-image-parent">
                        {/* <RandomImageGroup
                          key={`thumb_${property?.sitePage?.slug}`}
                          files={
                            property?.sitePage?.featuredImageCollection?.files
                          }
                          className="property-image no-resize "
                        /> */}
                        <img
                          src={property?.sitePage?.featuredImageCollection?.files[0]?.small?.fullUrl}
                          className="property-image no-resize "
                        />
                      </div>
                      <div className="badge discount-label">
                        ${lowestRateInRanges}/night
                      </div>
                    </div>
                    <div className="d-block ml-2">
                      <p className="text-primary font-weight-bold mb-0">
                        {property?.name}, from{' '}
                        <span className="text-red">
                          ${lowestRateInRanges}/night
                        </span>{' '}
                        ({lowestMinStayInRanges}-night minimum)
                      </p>
                      {rangesByProperty.map(range => {
                        return (
                          <>
                            <p className="mb-0 text-dark">
                              {moment(range.start).format('ddd MMM Do')} -{' '}
                              {moment(range.end).format('ddd MMM Do')}
                            </p>
                            {range?.holiday == 'VALENTINES' && (
                              <div className="mt-1plus text-red">
                                <ValentinesIcon /> Valentine's Day Getaway
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </>
                }
              </Link>
            );
          }
        )}
    </div>
  );
};

export const Modal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [hasHoliday, setHasHoliday] = useState(false);


  //let hasHoliday = false;
  const { data, error, loading, refetch } = useQuery(GET_HOT_DEALS);
  const lastMinuteDeals = data?.lastMinuteDeals || [];


  const lastMinuteDealsSorted = lastMinuteDeals.sort(function (a, b) {
    return new Date(a.start) - new Date(b.start); //a.lowestRate - b.lowestRate || a.property.id - b.property.id;
  });
  console.log('lastMinuteDealsSorted', lastMinuteDealsSorted);
  const lastMinuteDealsGroupedAndSorted = lastMinuteDealsSorted.reduce(
    (acc, propertyVacancyRange, index) => {
      const existingKeys = Object.keys(acc);

      const keyFound = existingKeys.find(key =>
        key.includes(`-${propertyVacancyRange.property.key}`)
      );
      if (!keyFound) {
        acc[`${index}-${propertyVacancyRange.property.key}`] = [
          propertyVacancyRange,
        ];
      } else {
        acc[keyFound].push(propertyVacancyRange);
      }

      return acc;
    },
    {}
  );





  useEffect(() => {
    //if (!Cookies.get('holiday-specials')) {
    if (window.location.pathname == '/') {
      const cookieValue = Cookies.get('holiday-specials');
      if (!cookieValue || moment(cookieValue).diff(moment(), 'days') != 0) {
        if (lastMinuteDeals.length > 0) {
          setModalIsOpen(true);
        }
      }
    }
    //}
  }, [lastMinuteDeals.length]);
  console.log('modalIsOpen', modalIsOpen)
  return (
    <DynamicModal
      disableFocusTrap={true}
      title={`Last-Minute Staycation Deals`}
      expanded={true}
      isOpen={modalIsOpen}
      //lastMinuteDeals.length > 0 &&
      onClose={() => setModalIsOpen(false)}
      id="holiday-special"
    >
      <>
        <LastMinuteDeals
          onLoad={({ hasHoliday }) => {
            if (hasHoliday) {
              //  setHasHoliday(true);
            }
          }}
        />

        <div>
          <button
            onClick={() => {
              setModalIsOpen(false);
            }}
            className="btn btn-secondary text-primary mt-3 mb-2 d-block font-weight-bold rounded mx-auto"
            style={{ width: '280px' }}
          >
            Close
          </button>
          <button
            onClick={() => {
              setModalIsOpen(false);
              Cookies.set('holiday-specials', moment());
            }}
            className="btn btn-link px-0 mb-4 m-auto  d-block text-muted"
          >
            Don't show this window again
          </button>
        </div>
      </>
    </DynamicModal>
  );
};

export default LastMinuteDeals;
