import React, { Component, useEffect, useRef } from 'react';

import { Link, graphql } from 'gatsby';
import GoogleMapReact from 'google-map-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MarkerClusterer from 'node-js-marker-clusterer';
import 'react-perfect-scrollbar/dist/css/styles.css';
import styles from './styles.module.scss';
import { Location } from '@reach/router';
import PropertyDetailsList from '../../../../components/PropertyDetailsList';
import { Dots } from '../../../../components/Loading';

function allMarkersInView(map, mapMarkers) {
  map.setCenter({ lat: 27.22, lng: -80.18 });
  map.setZoom(11);
  // var bounds = new google.maps.LatLngBounds();
  // const visibleMarkers = mapMarkers.filter(function(marker) {
  //     return marker.visible;
  // })
  // for (var i = 0; i < visibleMarkers.length; i++) {
  //     bounds.extend(visibleMarkers[i].getPosition());
  // }
  // mapMarkers.map(marker => marker.setVisible(true))
  // map.fitBounds(bounds);
}

const changeMapTypeBasedOnScreensize = map => {
  function resize() {
    if (window.innerWidth <= 600) {
      map.setMapTypeId('roadmap');
    }
  }

  window.onresize = resize;
};

const platformUrls = {
  HomeAway: 'HomeAway',
  Airbnb: 'AirBNB',
  'Booking.com': 'Booking',
  TripAdvisor: 'TripAdvisor',
  // BHIP: propertyDetails.bhipUrl,
};

const MapSearchRow = ({ children, params, data, noPropertyMatches }) => {
  console.log('data', data);
  return (
    <div
      className={`${
        styles.mapViewContainer
      } px-auto px-sm-0 mx-0 mx-sm-auto container h-100 ${
        params && params.get('mode') !== 'map' ? 'my-0 my-sm-3' : ''
      } `}
    >
      <div
        id="map-search-row"
        className={`row map-search-row ${styles.mapSearchRow} ${
          noPropertyMatches ? `${styles.withAlert}` : ``
        }`}

        // className={`row map-search-row`}
      >
        {children}
      </div>
    </div>
  );
};

class VacationRentalMap extends Component {
  constructor(props) {
    super(props);
    console.log('VacationRentalMap', props);
    this.mapSearchRow = React.createRef();
    this.state = {
      mapMarkers: null,
      selectedMarker: null,
      toggleMapRerender: false,
      mapIsLoading: true,
    };
  }
  // const mapRow = useRef(null)

  componentDidMount() {
    // const mapObserver = new IntersectionObserver(
    //   (entries, imgObserver) => {
    //     if (entries[0].isIntersecting) {
    //       this.setState({ mapIsLoading: false });
    //       // const mapContainer = document.querySelector('#map-container');
    //       // const mapLoadingContainer = document.querySelector(
    //       //   '#map-loading-container'
    //       // );
    //       // if ($(mapContainer).hasClass('d-none')) {
    //       //   $(mapContainer).removeClass('d-none');
    //       //   $(mapContainer).addClass('d-block');
    //       //   $(mapLoadingContainer).addClass('d-none');
    //       // }
    //       //   const lazyImage = entry.target;
    //       //   if (lazyImage.dataset.iframeSrc) {
    //       //     loadiFrame(lazyImage);
    //       //   } else {
    //       //     loadFullImage(lazyImage);
    //       //   }
    //       //   imgObserver.unobserve(lazyImage);
    //     }
    //   },
    //   { rootMargin: '200px 0px' }
    // );
    // const map = document.querySelector('#map-loading-container');
    // map && mapObserver.observe(map);
  }

  UNSAFE_componentWillReceiveProps(nextProps, state) {
    const mapSearchRowRef = this.mapSearchRow?.current;
    const nextJSON = JSON.stringify(nextProps.data);
    const propsJSON = JSON.stringify(this.props.data);
    if (nextJSON !== propsJSON) {
      this.setState({ toggleMapRerender: !this.state.toggleMapRerender });
    }
  }

  getMapOptions(maps) {
    return {
      fullscreenControl: false,
      styles: [
        {
          featureType: 'poi.business',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
      gestureHandling: 'greedy',
      // gestureHandling: 'auto',
      disableDoubleClickZoom: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.TOP_LEFT,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
      yesIWantToUseGoogleMapApiInternals: true,
      zoomControl: true,
      clickableIcons: false,
    };
  }

  getInfoViewContent(property) {
    let propertyListingsList = '';
    // const targetNode = document.querySelector('.header-container');

    // const homeAwayListItem = property.propertyListings.find(
    //   channel => channel.platform.name === 'HomeAway'
    // );
    // propertyListingsList = `<li class='d-inline ml-0 mr-3'><a class='platform-url' href=/${(
    //   homeAwayListItem || {}
    // ).propertyUrl || ''}>${
    //   platformUrls[homeAwayListItem && homeAwayListItem.platform.name]
    // }</a></li>`;
    // property.propertyListings.map((channel, index) => {
    //   if (
    //     channel.platform.name !== 'HomeAway' &&
    //     channel.propertyUrl &&
    //     channel.propertyUrl !== ''
    //   ) {
    //     propertyListingsList += `
    //         <li class='d-inline ml-3 mr-3'><a class='platform-url' href=${
    //           channel.propertyUrl
    //         }>${platformUrls[channel.platform.name]}</a></li>
    //     `;
    //   }
    // });
    return `
        <div class="container header-container position-sticky bg-white" style='top: 0; z-index: 1;'>
            <div class="row">
                <div class="col p-0">
                    <header class="h2 modal-header border-bottom-0 py-1 mb-1" style="font-size:20px;"><h5 class='m-0'>${
                      property.name
                    }</h5></header>
                </div>
            </div>
        </div>
        <div class="scrollable">
            <div class="container " style='width: 99%'>
            <img src="${
              property
                ? (property.sitePage.featuredImageCollection || {}).files[0]
                    .medium.fullUrl
                : ''
            }" width="100%" >
            <div class="text-center mt-2 mb-2"><a href="/${
              property.sitePage.slug
            }"  class="btn btn-primary btn-block book-property-button font-weight-bold rounded">Learn More</a></div>
                <div class="row m-0">
                    <div class="col p-0">
                        <hr class="mt-1 mb-1"/>
                        <div class="row ml-0 mr-0">
                            <ul class='p-0 mb-1plus mt-1plus d-flex justify-content-between w-100'>
                                <li class='d-inline'><span class='font-weight-bold'>${
                                  property.bedrooms
                                } bedrooms</span></li>
                                <li class='d-inline'><span class='font-weight-bold'>${
                                  property.bathrooms
                                } bathrooms</span></li>
                                <li class='d-inline'><span class='font-weight-bold'>${
                                  property.sleeps
                                } guests</span></li>
                            </ul>
                        </div>
                    <div class="description"><span>${
                      property?.sitePage?.excerpt
                    }</span></div>
                </div>
            </div>
        </div>
        <button draggable="false" title="Close" aria-label="Close" type="button" class="pt-1 pb-1 close position-absolute d-none custom-map-popover-close-button">&times;</button>
    </div>
        `;
  }

  buildMarkers(maps, map, property, infowindow, index = null, noImage = false) {
    console.log('buildMarkerts');
    var labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let location = { lat: property.latitude, lng: property.longitude };
    if (noImage) {
      return false;
    }
    const marker = new maps.Marker({
      position: location,
      map,
      title: property.name,
      label: index ? labels[index % labels.length] : 'A',
      content: this.getInfoViewContent(property),
      data: {
        description: property.sitePage?.excerpt,
        image: property.sitePage.featuredImageCollection
          ? property.sitePage.featuredImageCollection.files[0].medium.fullUrl
          : null,
        bedrooms: property.bedrooms,
        bathrooms: property.bathrooms,
        sleeps: property.sleeps,
        slug: property.sitePage.slug,
      },
    });
    window.google.maps.event.addListener(marker, 'click', () => {
      infowindow.setContent(marker.content);
      infowindow.open(map, marker);
    });
    return marker;
  }

  renderMarkers(properties, map, maps) {
    console.log('renderMarkers', properties);
    const { selectedMarker } = this.state;
    const infowindow = new window.google.maps.InfoWindow();
    let counter = -1;
    const mapMarkers = properties.reduce((acc, property, index) => {
      if (
        property.streetAddress1 &&
        property.sitePage &&
        property.sitePage.featuredImageCollection?.files.length > 0
      ) {
        counter += 1;
        console.log('counter', counter);
        acc.push(this.buildMarkers(maps, map, property, infowindow, counter));
      }
      return acc;
    }, []);
    console.log('mapMarkers!', mapMarkers);
    new MarkerClusterer(map, mapMarkers, {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    });
    if (selectedMarker) {
      const foundMarker = mapMarkers.find(
        marker => marker.label === selectedMarker.label
      );
      if (foundMarker) {
        map.setCenter(foundMarker.getPosition());
        map.setZoom(25);
        setTimeout(() => {
          infowindow.setContent(foundMarker.content);
          infowindow.open(map, foundMarker);
          map.setMapTypeId('satellite');
        }, '500');
      }
    } else {
      allMarkersInView(map, mapMarkers);
    }
    changeMapTypeBasedOnScreensize(map);
    this.setState({
      mapMarkers,
    });
  }

  render() {
    const { data } = this.props;
    const { bhip } = data || {};
    const { mapMarkers, toggleMapRerender, mapIsLoading } = this.state;
    console.log('IN-mapMarkers', mapMarkers);
    return (
      <Location>
        {({ location }) => {
          const params = new URLSearchParams(location.search);
          console.log('map', mapMarkers);
          return (
            <>
              {/* <div
                className={`${
                  styles.mapViewContainer
                } px-auto px-md-0 mx-0 mx-sm-auto container h-100 ${
                  params && params.get('mode') !== 'map' ? 'my-0 my-sm-3' : ''
                } `}
              > */}

              <MapSearchRow
                data={this.props.data}
                noPropertyMatches={this.props.noPropertyMatches}
                params={params}
              >
                <div
                  className={`d-none pl-3 d-lg-flex col-12 col-lg-6 col-xl-4 h-100 pr-3`}
                >
                  {console.log('mapMarkers', mapMarkers)}
                  <PerfectScrollbar>
                    <div className="list-group border-0 h-100 pr-2">
                      {mapMarkers &&
                        mapMarkers.map(marker => {
                          if (marker.data.image) {
                            return (
                              <div
                                className={`${
                                  styles.propertyListItem
                                } list-group-item border-0 p-0 mb-3`}
                                key={marker.label}
                                onClick={() =>
                                  this.setState({
                                    selectedMarker: marker,
                                    toggleMapRerender: !toggleMapRerender,
                                  })
                                }
                              >
                                <img src={marker.data.image} className="mb-2" />
                                {marker.data.status === 'COMING_SOON' && (
                                  <span className="thumbnail-label">
                                    Coming Soon
                                  </span>
                                )}
                                <Link
                                  to={`/${marker.data.slug}`}
                                  className="h2 text-primary font-weight-bold"
                                >
                                  {marker.label} - {marker.title}
                                </Link>
                                <div className="mt-1plus">
                                  <PropertyDetailsList property={marker.data} />
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                    </div>
                  </PerfectScrollbar>
                </div>
                {/* <div
                    style={{ height: '100px !important' }}
                    id="map-loading-container"
                    className="col-12 col-lg-6 col-xl-8 h-100 px-0 px-sm-2 px-md-3 pl-lg-2 mt-1"
                  >
                    <Dots />
                  </div> */}
                <div
                  id="map-container"
                  className="col-12 col-lg-6 col-xl-8 h-100 px-0 px-sm-2 px-md-2 pl-lg-2"
                >
                  <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals={true}
                    key={toggleMapRerender}
                    options={this.getMapOptions}
                    bootstrapURLKeys={{
                      key: 'AIzaSyBFRNkY6qNNsYNLN0uZfBESIe_amri73RI',
                      language: 'en',
                      region: 'us',
                    }}
                    defaultCenter={{ lat: 27.22, lng: -80.18 }}
                    defaultZoom={11}
                    onGoogleApiLoaded={({ map, maps }) => {
                      this.setState({ map });
                      this.renderMarkers(data, map, maps);
                    }}
                  />
                </div>
              </MapSearchRow>
              {/* </div> */}
            </>
          );
        }}
      </Location>
    );
  }
}

export default VacationRentalMap;
