import React, { useState } from 'react';
// import './styles.scss';
import { ChevronLeft, ChevronRight } from 'react-feather';
import {
  formatDateWithDashes,
  getDatesInMonth,
  monthNames,
  dayNames,
} from '../../util/dateHelper';

const DayCell = ({
  onClick,
  dayInMonth,
  classList,
  index,
  onMouseEnter = () => {},
}) => {
  return (
    <div
      data-date={formatDateWithDashes(dayInMonth)}
      className={`${classList.join(' ')} reserved-check-in`}
      onClick={onClick}
      key={`day_${index}`}
      onMouseEnter={e => {
        onMouseEnter(e);
      }}
    >
      {index + 1}
    </div>
  );
};

const Month = ({ monthNum, year, onDayRender, targetDate, index }) => {
  const targetDateClone = new Date(targetDate);
  var dtmMonth = targetDateClone.getMonth();
  var daysInMonth = getDatesInMonth(
    targetDateClone.getMonth(),
    targetDateClone.getFullYear()
  );

  var skipLength = daysInMonth[0].getDay();
  var preLength = daysInMonth.length + skipLength;
  var postLength = function() {
    if (preLength % 7 === 0) {
      return 0;
    } else {
      if (preLength < 35) {
        return 35 - preLength;
      } else {
        return 42 - preLength;
      }
    }
  };

  return (
    <div key={`_${index}`} id={`month_${index + 1}`} className="month w-100">
      <div className="month-header">
        {/* <h3>
          {monthNames[targetDateClone.getMonth()] +
            ' ' +
            targetDateClone.getFullYear()}
        </h3> */}
        <h3>{monthNames[targetDateClone.getMonth()]}</h3>
      </div>
      {dayNames.map((dayName, i) => {
        return (
          <div key={`key_${i}`} className="dow">
            {dayName}
          </div>
        );
      })}

      {[...Array(skipLength * 1).keys()].map((_, index) => {
        return (
          <div key={`_${index}_`} className="dummy-day day">
            {
              //daysPrevMonth.length - (skipLength - (index + 1))
            }
          </div>
        );
      })}

      {daysInMonth.map((dayInMonth, index) => {
        var today = new Date(new Date().setHours(0, 0, 0, 0));

        let classList = ['day'];
        var dow = new Date(dayInMonth).getDay();
        var dateParsed = Date.parse(dayInMonth);
        var todayParsed = Date.parse(today);

        if (dateParsed === todayParsed) classList.push('today');
        if (dateParsed > todayParsed) classList.push('future');
        if (dateParsed < todayParsed) classList.push('past');

        if (dow === 0 || dow === 6) classList.push('weekend');

        if (onDayRender) {
          return onDayRender({
            dayInMonth,
            classList,
            index,
          });
        }

        return (
          <DayCell
            dayInMonth={dayInMonth}
            classList={classList}
            key={index}
            index={index}
          />
        );
      })}
      {[...Array(postLength()).keys()].map((_, index) => {
        return <div key={`__${index}`} className="dummy-day"></div>;
      })}
    </div>
  );
};

const DateRangePicker = props => {
  const { numMonths, onDayRender, date, dateForDisplay } = props;

  return (
    // <div id="calendar">
    <div className="d-lg-flex d-block align-items-center">
      <div className="d-lg-flex d-block justify-content-center">
        {[...Array(numMonths * 1).keys()].map((_, index) => {
          const dateForDisplayClone = new Date(
            dateForDisplay.getFullYear(),
            dateForDisplay.getMonth(),
            1
          );

          dateForDisplayClone.setMonth(dateForDisplayClone.getMonth() + index);

          return (
            <React.Fragment key={index}>
              <Month
                // dateHoverRange={dateHoverRange}
                targetDate={dateForDisplayClone}
                index={index}
                onDayRender={onDayRender}
                // resetRangeOnClick={resetRangeOnClick}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>

    // </div>
  );
};

DateRangePicker.defaultProps = {
  numMonths: '2',
};

export default DateRangePicker;
