import React, { useEffect } from 'react';
import photoswipe from '../../util/photoswipe';

const LightBox = ({ children, ...props }) => {
  let galleryParent;

  useEffect(() => {
    photoswipe(galleryParent);
  }, [props.id]);
  return <div ref={el => (galleryParent = el)}>{children}</div>;
};

export default LightBox;
