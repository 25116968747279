import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import styles from './styles.module.scss';
import LazyImage from '../LazyImage';
import { getVersionMap } from '../../util/helpers';

const DynamicPropertyList = ({ properties, singleColumn, id }) => {
  useEffect(() => {
    if (singleColumn) {
      $(`#${id}`).removeClass('d-lg-flex');
    } else if (!$(`#${id}`).hasClass('d-lg-flex')) {
      $(`#${id}`).addClass('d-lg-flex');
    }
  }, []);
  const LeftColumn = ({ children }) => (
    <div className={`dropdown-column pr-lg-0`}>{children}</div>
  );
  const CenterColumn = ({ children }) => (
    <div className={`dropdown-column pl-lg-0`}>{children}</div>
  );
  const RightColumn = ({ children }) => (
    <div className={`dropdown-column pl-lg-0`}>{children}</div>
  );
  const leftColumnChildren = [];
  const centerColumnChildren = [];
  const rightColumnChildren = [];

  const comboProperties = properties.reduce((acc, property, index) => {
    if (
      property?.type === 'COMBO' &&
      property?.sitePage?.status === 'PUBLISHED'
    ) {
      acc.push(property);
    }
    return acc;
  }, []);

  const nonComboProperties = properties.reduce((acc, property, index) => {
    if (
      property?.type === 'STANDARD' &&
      property?.sitePage?.status === 'PUBLISHED'
    ) {
      acc.push(property);
    }
    return acc;
  }, []);

  const hasComboProperties = comboProperties.length > 0 ? true : false;

  const combinedProperties = [...nonComboProperties, ...comboProperties];

  const propertyListLength = combinedProperties.length;
  const splitPropertyColumnIndex = hasComboProperties
    ? propertyListLength % 3 === 0
      ? propertyListLength / 3
      : (propertyListLength + 1) / 3
    : propertyListLength % 2 === 0
    ? propertyListLength / 2
    : (propertyListLength + 1) / 2;

  // const splitPropertyColumnIndex =
  //   propertyListLength % 3 === 0
  //     ? propertyListLength / 3
  //     : (propertyListLength + 1) / 3;

  combinedProperties.forEach((property, index) => {
    const file = property?.sitePage?.featuredImageSecondary?.file || {};
    const versionMap = getVersionMap(file);

    const link = (
      <Link
        data-dropdown-link
        key={property.sitePage.slug}
        className={`${styles.propertyDropdownList} dropdown-item d-flex align-items-center`}
        to={`/${property.sitePage.slug}`}
      >
        <div className="position-relative overflow-hidden">
          {versionMap && (
            <>
              <LazyImage
                src={versionMap.tiny.fullUrl}
                srcset={`${versionMap.small.fullUrl} ${versionMap.small.width}w`}
                itemProp="thumbnail"
                className="no-resize"
                width="92"
                style={{ width: '92px' }}
              />
              {property.status === 'COMING_SOON' && (
                <span className="thumbnail-label text-small">Coming Soon</span>
              )}
            </>
          )}
        </div>
        <div
          className={`${styles.propertyList} flex-grow-1 ml-1plus property-list`}
        >
          <span className="text-primary font-weight-bold">
            {property.sitePage.title}
            {property.status === 'NEW' && (
              <span className="ml-1 text-primary">
                (<span className={`${styles.newPropertyText}`}>New</span>)
              </span>
            )}
          </span>
          <br />
          <div className="d-flex">
            <span className="mr-1plus" title="Bedrooms">
              <span className="mr-1 align-text-bottom">
                <img width="26" height="20" src={'/bed.svg'} alt="Bedrooms" />
              </span>
              <span>{property.bedrooms}</span>
            </span>
            <span className="mr-1plus" title="Baths">
              <span className="mr-1 align-text-bottom">
                <img width="20" height="20" src={'/bath.svg'} alt="Bathrooms" />
              </span>
              {property.bathrooms}
            </span>
            <span className="mr-1plus" title="Sleeps">
              <span className="mr-1 align-text-bottom">
                <img width="16" height="20" src={'/sleeps.svg'} alt="Sleeps" />
              </span>
              {property.sleeps}
            </span>
          </div>
        </div>
      </Link>
    );
    const getRightColumnData = index => {
      if (
        combinedProperties[index - 1]?.type === 'STANDARD' &&
        combinedProperties[index]?.type === 'COMBO'
        //  &&
        // combinedProperties[index]?.sitePage?.status === 'PUBLISHED'
      ) {
        rightColumnChildren.push(
          <div
            key={combinedProperties[index - 1]?.key}
            className={`${styles.compoundTextWrapper} px-2 px-lg-1plus my-1plus mx-2 mx-lg-auto`}
          >
            <p className="mb-0">
              <b>Beach-House Compounds</b>
            </p>
            <p className={`mb-0`}>
              Book multiple beach houses in a single reservation and Save. Learn
              more{' '}
              <a className="pl-0" href="/discount-programs">
                about discounts.
              </a>
            </p>
          </div>
        );
        rightColumnChildren.push(link);
      } else {
        rightColumnChildren.push(link);
      }
    };
    const getColumnsWithCombos = () => {
      if (index + 1 <= splitPropertyColumnIndex) {
        leftColumnChildren.push(link);
      } else if (
        index + 1 > splitPropertyColumnIndex &&
        index + 1 <= splitPropertyColumnIndex * 2
      ) {
        centerColumnChildren.push(link);
      } else {
        getRightColumnData(index);
      }
    };
    const getColumnsWithoutCombos = () => {
      if (index + 1 <= splitPropertyColumnIndex) {
        leftColumnChildren.push(link);
      } else {
        rightColumnChildren.push(link);
      }
    };
    hasComboProperties ? getColumnsWithCombos() : getColumnsWithoutCombos();
  });
  return (
    <div id={id} className="d-block my-lg-1">
      <LeftColumn>{leftColumnChildren}</LeftColumn>
      {hasComboProperties && (
        <CenterColumn>{centerColumnChildren}</CenterColumn>
      )}
      <RightColumn>{rightColumnChildren}</RightColumn>
    </div>
  );
};

DynamicPropertyList.defaultProps = {
  singleColumn: false,
};

export default DynamicPropertyList;
