import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import CMSContent from '../../components/CMSContent';
import Layout from '../../layouts/DefaultLayout';
import { Link } from 'gatsby';
import Map from '../../components/Map';
import styles from './styles.module.scss';

const LocalListingPage = ({ pageContext, data }) => {
  const [screenWidth, setScreenWidth] = useState(null);
  const { sitePage } = data.bhip;
  const handleWindowResize = () => {
    setScreenWidth($('body').innerWidth());
  };
  useEffect(() => {
    setScreenWidth($('body').innerWidth());
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const localListing = data?.bhip?.localListing || {};

  const imageVersions = sitePage?.featuredImage?.file?.versions || [];
  const featuredImage = (
    imageVersions.find(image => image.key === 'small') || {}
  ).fullUrl;
  const socialLinks = data?.bhip?.localListing?.socialLinks || [];
  const hasAddress = localListing?.streetAddress1?.length > 0 ? true : false;

  return (
    <Layout subheader={false} searchbarLabel={sitePage.title}>
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        featuredImage={sitePage.featuredImage}
        headTags={sitePage.headTags}
      />
      <div id="local-listing-map" className="mb-3">
        {!screenWidth || screenWidth > 420 ? (
          <Map
            zoom={14}
            withMarkerImage={true}
            marker={{ ...localListing, thumbnailImage: featuredImage }}
          />
        ) : (
          <img className="w-100" src={featuredImage} />
        )}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-9 mb-3">
            <article id="cms-content-container" className="col-12 pr-lg-3 p-0">
              <h1>{sitePage.title}</h1>
              <CMSContent>{sitePage.cmsContent.html}</CMSContent>

              <div className="my-2">
                {`${localListing.addressDisplay}`.length > 10 && hasAddress && (
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                      localListing.addressDisplay
                    )}`}
                  >
                    {localListing.addressDisplay}
                  </a>
                )}
                <p className="mb-0">{localListing.phone}</p>
              </div>
              <div className={`${styles.socialButtons}`}>
                {localListing.website && <a
                  href={localListing.website || ''}
                  className="btn btn-secondary d-block mb-2 font-weight-bold text-primary"
                  target="_blank"
                >
                  Visit Website
                </a>}
                {socialLinks.map(link => {
                  return (
                    <a
                      key={link.name}
                      href={link.url}
                      className="btn btn-outline-primary d-block mb-2 text-primary font-weight-bold"
                      target="_blank"
                    >
                      {`Visit ${link.name} Page`}
                    </a>
                  );
                })}
              </div>
            </article>
          </div>
          <aside className="col-12 col-lg-3">
            <>
              <p className="font-weight-bold mb-2">
                You might also be interested in
              </p>
              <>
                {(sitePage.relatedSitePages || []).reduce(
                  (acc, page, index) => {
                    const { versions = [] } =
                      (page.featuredImage || {}).file || {};
                    var thumbnailVersions = versions.reduce((acc, version) => {
                      acc[version.key] = { ...version };
                      return acc;
                    }, {});
                    if (index <= 2) {
                      acc.push(
                        <div
                          key={page.slug}
                          className={`card shadow-sm rounded mb-3`}
                        >
                          <div className="aspect ratio-16x9">
                            <Link to={`/${page.slug}`}>
                              <img
                                className="card-img-top"
                                src={
                                  thumbnailVersions?.medium?.fullUrl ||
                                  'https://via.placeholder.com/640x360'
                                }
                              />
                            </Link>
                          </div>
                          <div className="card-body">
                            <div className="card-text">
                              <p className="font-weight-bolder mb-1">
                                <Link to={`/${page.slug}`}>{page.title}</Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return acc;
                  },
                  []
                )}
              </>
              <Link to="/guide">More things to do in Stuart, FL</Link>
            </>
          </aside>
        </div>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query LocalListing($slug: String, $localListingForeignKey: Int) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...RelatedSitePages
        ...SitePageData
      }
      localListing(where: { id: $localListingForeignKey }) {
        id
        name
        website
        phone
        streetAddress1
        addressDisplay
        latitude
        longitude
        socialLinks {
          name
          url
        }
      }
    }
  }
`;

export default LocalListingPage;
