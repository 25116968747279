import React, { useState, useEffect } from 'react';
import { Mutation } from 'react-apollo';
import PreviewDropZone from '../../../../components/Admin/PreviewDropZone';
import { Formik, Field } from 'formik';
import gql from 'graphql-tag';
import styles from './styles.module.scss';
import './styles.scss';
import * as Yup from 'yup';
import Promise from 'bluebird';
import UserFormInputs from '../UserFormInputs';
import FormFeedback from '../../../../components/Forms/FormFeedback';
// const UPDATE_GUEST_MEMORY = gql`
//   mutation updateGuestMemory(
//     $guestInput: GuestMemoryUpdateInput!
//     $token: String!
//   ) {
//     updateGuestMemory(
//       # data: { fiveStarRating: 5, body: "Greatest Place Ever" }
//       data: $guestInput
//       where: { token: $token }
//     ) {
//       id
//       emailAddress
//       fiveStarRating
//       body
//     }
//   }
// `;

const UPLOAD_GUEST_MEMORY = gql`
  mutation UploadGuestMemoryPhoto(
    $file: Upload #$tagIds: [Int] #$siteFileCollectionId: Int
    $token: String!
  ) {
    uploadGuestMemoryPhoto(data: { file: $file }, where: { token: $token }) {
      originalFileName
    }
  }
`;

const CREATE_GUEST_MEMORY = gql`
  mutation createGuestMemory($guestMemoryCreateInput: GuestMemoryCreateInput!) {
    createGuestMemory(data: $guestMemoryCreateInput) {
      token
    }
  }
`;

const SharePhotosForm = ({
  loading = null,
  onSuccess,
  create,
  token: tokenFromParent,
  location,
  properties,
}) => {
  // const [urlParam, setUrlParam] = useState(false);

  const params = new URLSearchParams(location && location.search);
  const [propertyId, setPropertyId] = useState(null);
  useEffect(() => {
    setPropertyId(params.get('propertyId') || null);
  }, []);

  const validationSchemaCreate = Yup.object().shape({
    emailAddress: Yup.string()
      .email('Please enter a valid email')
      .required('Email is required'),
    fullName: Yup.string().required('Name is required'),
    propertyId: Yup.string().required('Property is required'),
    files: Yup.array()
      .min(1, 'At least one photo is required')
      .required('Photo is required'),
  });
  const validationSchemaUpdate = Yup.object().shape({
    files: Yup.array()
      .min(1, 'At least one photo is required')
      .required('Photo is required'),
  });
  return (
    <Mutation mutation={CREATE_GUEST_MEMORY}>
      {(createGuestMemory, { loading, data: guestMemory }) => {
        return (
          <Mutation mutation={UPLOAD_GUEST_MEMORY}>
            {(uploadGuestMemoryPhoto, { loading, data: uploadedData }) => {
              return (
                <Formik
                  enableReinitialize={true}
                  validationSchema={
                    create ? validationSchemaCreate : validationSchemaUpdate
                  }
                  initialValues={{
                    files: [],
                    emailAddress: params.get('email')
                      ? params.get('email')
                      : '',
                    fullName: params.get('name') ? params.get('name') : '',
                    // propertyId: params.get('propertyId')
                    //   ? params.get('propertyId')
                    //   : '',
                    propertyId: propertyId ? propertyId : '',
                    shouldResetFormDropzoneInput: false,
                  }}
                  onSubmit={async (
                    values,
                    {
                      setFieldValue,
                      setSubmitting,
                      setStatus,
                      handleChange,
                      resetForm,
                      setErrors,
                    }
                  ) => {
                    let errors = [];
                    let token;
                    try {
                      setSubmitting(true);
                      const guestMemoryCreateInput = { ...values };
                      delete guestMemoryCreateInput.files;
                      delete guestMemoryCreateInput.shouldResetFormDropzoneInput;
                      guestMemoryCreateInput.propertyId = Number(
                        guestMemoryCreateInput.propertyId
                      );

                      const { data } = create
                        ? await createGuestMemory({
                            variables: { guestMemoryCreateInput },
                          })
                        : { data: null };
                      token = data?.createGuestMemory?.token || tokenFromParent;

                      if (token) {
                        const dataPackages = values.files.map(file => {
                          const formDataPayload = { ...values };
                          delete formDataPayload.files;
                          return {
                            file,
                            ...formDataPayload,
                          };
                        });
                        Promise.each(
                          dataPackages,
                          (dataPackage, index) => {
                            return uploadGuestMemoryPhoto({
                              variables: { ...dataPackage, token },
                            })
                              .then(result => {
                                return result;
                              })
                              .catch(err => {
                                errors.push(err);
                                console.log('err', err);
                                setSubmitting(false);
                                setStatus({
                                  error:
                                    'There was a problem with your submission. Please try again.',
                                });
                              });
                          }
                          // { concurrency: 1 }
                        )
                          .then(result => {
                            if (errors.length === 0) {
                              setSubmitting(false);
                              resetForm();

                              setStatus({
                                success: 'Thank you for your submission.',
                              });
                              setFieldValue('files', []);
                              onSuccess(token);
                            } else {
                              setStatus({
                                error:
                                  'There was a problem with your submission. Please try again.',
                              });
                            }
                          })
                          .catch(err => {
                            errors.push(err);
                            console.log('err', err);
                            setSubmitting(false);
                            setStatus({
                              error:
                                'There was a problem with your submission. Please try again.',
                            });
                            setErrors({
                              message:
                                'There was a problem with your submission. Please try again.',
                            });
                          });
                      }
                    } catch (err) {
                      errors.push(err);
                      console.log('err', err);
                      setSubmitting(false);
                      setStatus({
                        error:
                          'There was a problem with your submission. Please try again.',
                      });
                      setErrors({
                        message:
                          'There was a problem with your submission. Please try again.',
                      });
                    }
                  }}
                  render={formikProps => {
                    const {
                      values,
                      handleChange,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      errors,
                      status,
                      touched,
                      handleBlur,
                      submitCount,
                    } = formikProps;

                    return (
                      <form onSubmit={handleSubmit} id="memories-form">
                        <>
                          <div
                            className={`form-group preview-drop-zone ${styles.previewDropZone}`}
                          >
                            <p className="font-weight-bold ">
                              Share photos/videos:
                            </p>
                            <Field name="files">
                              {({ field, form, meta }) => (
                                <div>
                                  <PreviewDropZone
                                    files={values?.files}
                                    customDropZone={({
                                      getRootProps,
                                      getInputProps,
                                      thumbsContainer,
                                      thumbs,
                                    }) => {
                                      return (
                                        <div
                                          {...getRootProps({
                                            className:
                                              'dropzone memories-form w-100 h-100 d-flex flex-column align-items-center justify-content-center p-2',
                                          })}
                                        >
                                          <div id="dropzone-child-wrapper">
                                            <div id="drop-zone-header">
                                              <h1 className="text-center">
                                                Drag a file here
                                              </h1>
                                              <p className="text-center mb-3">
                                                Or, if you prefer...
                                              </p>
                                            </div>
                                            <input
                                              // name="files"
                                              {...getInputProps()}
                                            />
                                            <div className="w-100 text-center">
                                              <button
                                                onClick={e =>
                                                  e.preventDefault()
                                                }
                                                className="btn btn-primary"
                                              >
                                                Select a file from your computer
                                              </button>
                                              <div className="small text-danger mt-1">
                                                {touched.files &&
                                                  errors.files &&
                                                  errors.files}
                                              </div>
                                            </div>

                                            <aside style={thumbsContainer}>
                                              {thumbs}
                                            </aside>
                                          </div>
                                        </div>
                                      );
                                    }}
                                    multiple={true}
                                    onDrop={acceptedFiles => {
                                      setFieldValue(
                                        'shouldResetFormDropzoneInput',
                                        false
                                      );
                                      setFieldValue(
                                        'files',
                                        values.files.concat(acceptedFiles)
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </Field>

                            <p className="mt-2">
                              Note: Photos you submit may be used by Beach
                              Houses in Paradise for promotional purposes.
                            </p>
                          </div>
                          <div className="d-flex flex-column flex-lg-row justify-content-between">
                            {create && (
                              <>
                                <UserFormInputs
                                  urlParams={params}
                                  formikProps={formikProps}
                                  properties={properties}
                                />
                              </>
                            )}
                          </div>

                          <FormFeedback
                            errors={status?.error}
                            isVisible={
                              status?.error ||
                              status?.success ||
                              (Object.keys(errors).length > 0 &&
                                submitCount > 0)
                            }
                            alert={
                              Object.keys(errors).length > 0 && submitCount > 0
                            }
                          >
                            {status?.success}
                          </FormFeedback>

                          {/* {errors.message && (
                            <div
                              className="alert alert-danger alert-dismissible fade show d-flex align-items-center"
                              role="alert"
                            >
                              <img src={'/alert-error.svg'} />
                              <strong className="ml-1plus">
                                {errors.message}
                              </strong>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                              />
                            </div>
                          )} */}

                          <button
                            type="submit"
                            id="submit-memories-button"
                            className="btn btn-secondary text-primary font-weight-bold"
                            disabled={
                              (submitCount > 0 &&
                                Object.keys(errors).length > 0) ||
                              isSubmitting
                            }

                            //   onClick={onSubmit}
                          >
                            {!loading && <span>Submit Memories</span>}
                            {loading && (
                              <span
                                className="spinner-border spinner-border-sm align-middle"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        </>
                      </form>
                    );
                  }}
                />
              );
            }}
          </Mutation>
        );
      }}
    </Mutation>
  );
};

export default SharePhotosForm;
