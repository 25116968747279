import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';

// I rolled back to react-dropzone version 10.1.9 as a result of the onReject function in version 10.1.9^ evaluating to true when files were successfully uploaded to the form
// https://github.com/react-dropzone/react-dropzone/issues/888

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#ccc',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '100%',
};
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};
const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};
const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const PreviewDropzone = ({
  multiple,
  customDropZone,
  customHeader,
  files,
  ...props
}) => {
  const onDrop = useCallback(props.onDrop, []);
  // const [files, setFiles] = useState(formValues?.values?.files || []);
  // const files = formValues?.values?.files || [];

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    multiple,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const acceptedFilesWithPreview = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      const allFiles = multiple
        ? [...files, ...acceptedFilesWithPreview]
        : acceptedFilesWithPreview;

      // setFiles(allFiles);
      onDrop(allFiles);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  const thumbs = files.map((file, index) => (
    <div style={thumb} data-file-upload-list key={`${file.name}_${index}`}>
      <img data-file-name={file.name} src={file.preview} style={img} />
    </div>
  ));
  const removeAllFiles = () => {
    console.log('remove files');
    // console.log('acceptedFiles', acceptedFiles);
    // acceptedFiles.length = 0;
    // acceptedFiles.splice(0, acceptedFiles.length);
    // inputRef.current.value = '';
    // console.log('acceptedFiles', acceptedFiles);
    // console.log('inputRef.current.value', inputRef.current.value);
    // setFiles([]);
  };

  useEffect(() => {
    // shouldResetForm && files.length > 0 && removeAllFiles();
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <section className="container" style={style}>
      {/* {customHeader && customHeader()} */}
      {customDropZone ? (
        customDropZone({ getInputProps, getRootProps, thumbsContainer, thumbs })
      ) : (
        <div>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />

            {multiple ? (
              <p>Drag 'n' drop some files here, or click to select files</p>
            ) : (
              <p>Drag 'n' drop a file here, or click to select a file</p>
            )}
          </div>

          <aside style={thumbsContainer}>{thumbs}</aside>
        </div>
      )}
    </section>
  );
};

PreviewDropzone.defaultProps = {
  multiple: true,
  customDropZone: null,
  customHeader: null,
};

export default PreviewDropzone;
