import React, { useState, useEffect } from 'react';
import LazyImage from '../LazyImage';

const RandomImageGroup = ({ files, className = '', alt = '' }) => {
  const [randomIndexToShow, setRandomIndexToShow] = useState(-1);
  useEffect(() => {
    setRandomIndexToShow(Math.floor(Math.random() * files.length));
  });
  return (
    <>
      {files.map((file, index) => {
        return (
          <>

            <LazyImage
              key={`lazy_${file.id}_${index}`}
              src={file.tiny.fullUrl}
              className={randomIndexToShow == index ? className : 'd-none'}
              alt={alt}
              srcset={`${file.medium.fullUrl} 1w`}
            />
          </>
        );
      })}
    </>
  );
};
export default RandomImageGroup;
