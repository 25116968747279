const PhotoSwipe = require('photoswipe');
const PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default');
require('photoswipe/dist/photoswipe.css');
require('photoswipe/dist/default-skin/default-skin.css');

const appendModal = () => {
  if ($('.pswp__scroll-wrap').length === 0) {
    const photoSwipeModal = `<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
        <!-- Background of PhotoSwipe.
            It's a separate element, as animating opacity is faster than rgba(). -->
        <div class="pswp__bg"></div>

        <!-- Slides wrapper with overflow:hidden. -->
        <div class="pswp__scroll-wrap">

            <!-- Container that holds slides. PhotoSwipe keeps only 3 slides in DOM to save memory. -->
            <!-- don't modify these 3 pswp__item elements, data is added later on. -->
            <div class="pswp__container">
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
            </div>

            <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
            <div class="pswp__ui pswp__ui--hidden">

                <div class="pswp__top-bar">

                    <!--  Controls are self-explanatory. Order can be changed. -->

                    <div class="pswp__counter"></div>

                    <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

                    <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

                    <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                    <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                    <!-- element will get class pswp__preloader--active when preloader is running -->
                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                          <div class="pswp__preloader__cut">
                            <div class="pswp__preloaclass=er__donut"></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip"></div>
                </div>
                <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button>
                <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button>
                <div class="pswp__caption">
                    <div class="pswp__caption__center"></div>
                </div>
              </div>
            </div>
      </div>`;
    $('body').append(photoSwipeModal);
  }
};
module.exports = gallerySelector => {
  appendModal();
  var pswpElement = document.querySelectorAll('.pswp')[0];

  var $galleries = $(gallerySelector);
  var iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
  $galleries.each(function(index, gallery) {
    var realViewportWidth,
      useLargeImages = false,
      firstResize = true,
      imageSrcWillChange;
    const galleryTagName = gallery.tagName.toUpperCase();
    const $figureElements =
      galleryTagName === 'FIGURE' ? $(gallery) : $(gallery).find('figure');

    const items = $figureElements.map((index, figureElement) => {
      // $(figureElement).on('click', () => {
      //   console.log('this', $(this).find('figure')[1]);
      // });
      const $anchorElement = $(figureElement).find('a');

      const $imgElement = $(figureElement).find('img[itemProp=thumbnail]');
      const size = $anchorElement.data('size').split('x');
      let mobileImage = {};
      if (
        $anchorElement.data('size-mobile') &&
        $anchorElement.data('href-mobile')
      ) {
        const mobileSize = $anchorElement.data('size-mobile').split('x');
        mobileImage = {
          src: $anchorElement.data('href-mobile'),
          w: parseInt(mobileSize[0], 10),
          h: parseInt(mobileSize[1], 10),
        };
      } else {
        console.log(
          'WARNING: Mobile size attributes missing in Slider for ios.'
        );
      }

      const item = {
        src: $anchorElement.attr('href'),
        w: parseInt(size[0], 10),
        h: parseInt(size[1], 10),
        msrc: $imgElement.attr('src'),
        el: figureElement,
        title: '',
        index,
        mobileImage,
      };
      return item;
    });

    $figureElements.each((index, figureElement) => {
      const domIndex = figureElement.dataset.index
        ? Number(figureElement.dataset.index)
        : index;

      $(figureElement)
        .find('a')
        .click(function(event) {
          // console.log('event', event.target);
          event.preventDefault();
          const photoswipe = new PhotoSwipe(
            pswpElement,
            PhotoSwipeUI_Default,
            items,
            {
              history: false,
              index,
              getThumbBoundsFn: function(index) {
                // Obtains coordinates so thumbnail click animation works properly. See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                  pageYScroll =
                    window.pageYOffset || document.documentElement.scrollTop,
                  rect = thumbnail.getBoundingClientRect();

                return {
                  x: rect.left,
                  y: rect.top + pageYScroll,
                  w: rect.width,
                };
              },
            }
          );
          photoswipe.listen('beforeResize', function() {
            console.log('beforeResize');
            // calculate real pixels when size changes
            realViewportWidth =
              photoswipe.viewportSize.x * window.devicePixelRatio;

            // Code below is needed if you want image to switch dynamically on window.resize

            // Find out if current images need to be changed
            console.log('realViewportWidth', realViewportWidth);
            if (iOS || (useLargeImages && realViewportWidth < 1000)) {
              useLargeImages = false;
              imageSrcWillChange = true;
            } else if (!useLargeImages && realViewportWidth >= 1000) {
              useLargeImages = true;
              imageSrcWillChange = true;
            }

            // Invalidate items only when source is changed and when it's not the first update
            if (imageSrcWillChange && !firstResize) {
              // invalidateCurrItems sets a flag on slides that are in DOM,
              // which will force update of content (image) on window.resize.
              photoswipe.invalidateCurrItems();
            }

            if (firstResize) {
              firstResize = false;
            }

            imageSrcWillChange = false;
          });
          photoswipe.listen('gettingData', function(index, item) {
            if (useLargeImages) {
              item.src = item.src;
              item.w = item.w;
              item.h = item.h;
            } else {
              item.src = item.mobileImage.src;
              item.w = item.mobileImage.w;
              item.h = item.mobileImage.h;
            }
          });
          photoswipe.init();
        });
    });
  });
};
