import React, { useState } from 'react';
import Media from '../Media';
import Slider from '../Slider';
import VirtualTour from '../VirtualTour';
// import styles from './styles.module.scss';
import ThemedSelectList from '../ThemedSelectList';
const BlockSelectList = ({
  property,
  blockType,
  defaultLabel,
  renderHeader,
}) => {
  //   const [selectedOption, setSelectedOption] = useState(
  //     property?.childProperties?.find(
  //       child => child?.key === property?.childProperties[0]?.key
  //     ).key
  //   );
  const [selectedOption, setSelectedOption] = useState(null);

  const getBlockData = () => {
    if (blockType === 'slider') {
      const foundBlock = selectedOption
        ? property?.childProperties.find(child => child.key === selectedOption)
        : property?.childProperties[0];
      const files = foundBlock?.sitePage?.featuredFileCollection?.files || [];

      return files.reduce((acc, file, index) => {
        acc.id = property?.sitePage?.featuredFileCollection?.id || index;
        if (file.mimetype.indexOf('image') !== -1) {
          if (acc.files) {
            acc.files.push(file);
          } else {
            acc.files = [file];
          }
        }
        return acc;
      }, {});
    } else if (blockType === 'virtualTour') {
      const foundBlock = selectedOption
        ? property?.childProperties.find(child => child.key === selectedOption)
        : property?.childProperties[0];

      return foundBlock?.virtualTours[0];
    }
    return property?.childProperties?.find(child => {
      const propertyToMatch = selectedOption
        ? selectedOption
        : property?.childProperties[0]?.key;
      return propertyToMatch === child.key;
    });
  };
  const blockData = getBlockData();
  const selectedProperty = property?.childProperties?.find(
    child => child?.key === selectedOption
  );

  return (
    <>
      {/* <div className={styles.dropdownWrapper}>
        <div
          // key={index}
          // data-end-of-list={index + 1 === jumpMenuOptions.length ? true : false}
          // data-jump-menu-item
          className={`mr-2 nav-item jump-menu-nav-item dropdown w-100`}
        >
          <a
            className={`${styles.toggleButton} dropdown-toggle nav-link w-100`}
            data-sticky-jump-menu="true"
            // href="#"
            //   href={`#${option.key}`}
            role="button"
            //   id={`#${option.key}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {selectedProperty?.name || defaultLabel}
          </a>
          <div
            className={`${styles.dropdownMenu} dropdown-menu mt-0 w-100`}
            aria-labelledby="dropdownMenuButton"
            //   data-option={`${option.key}`}
          >
            {property?.childProperties?.map(combo => {
              return (
                <a
                  key={combo?.key}
                  className={`${styles?.dropdownItem} text-primary dropdown-item jump-menu-drop-down-item`}
                  data-option={`${combo?.key}`}
                  // href={`#${option.key}`}
                  onClick={e => {
                    console.log('e', e.target?.dataset?.option);
                    const found = property?.childProperties?.find(child => {
                      console.log('child.key', child?.key);
                      return e.target?.dataset?.option === child.key;
                    });

                    if (found) {
                      setSelectedOption(found.key);
                    }
                  }}
                >
                  {combo.name}
                </a>
              );
            }) || []}
          </div>
        </div>
      </div> */}
      <div className="d-flex align-items-center mb-2">
        {renderHeader && renderHeader()}
        <ThemedSelectList
          options={property?.childProperties?.map(combo => {
            return {
              label: combo.name,
              value: combo?.key,
            };
          })}
          onSelectChange={data => {
            const found = property?.childProperties?.find(child => {
              return data === child.key;
            });

            if (found) {
              setSelectedOption(found.key);
            }
          }}
          // classNames={`${modalIsOpen ? 'w-100 mb-2' : ''} mr-1plus rounded`}
          hiddenValue={selectedProperty?.name || defaultLabel}
        />
      </div>
      {/* <select
        name="bulkAction"
        className="form-control"
        id="exampleFormControlSelect1"
        onChange={async e => {
          const found = property?.childProperties?.find(child => {
            return e.target.value === child.key;
          });

          if (found) {
            setSelectedOption(found.key);
          }
        }}
      >
        {property?.childProperties?.map(combo => {
          return (
            <option key={combo?.key} value={combo.key}>
              {combo.name}
            </option>
          );
        }) || []}
      </select> */}
      {blockType === 'virtualTour' && blockData && (
        <VirtualTour
          propertyVirtualTour={blockData}
          thumbnailVersion="large"
          withIcon={true}
        />
      )}
      {blockType === 'image' && blockData?.floorPlan && (
        <Media siteFileInstance={blockData?.floorPlan.fileInstance} />
      )}
      {blockType === 'slider' && blockData?.files && (
        <Slider id={blockData?.id} featuredFiles={blockData?.files} />
      )}
    </>
  );
};
export default BlockSelectList;
