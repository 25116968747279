export const overflowIndicator = () => {
  $('[data-overflow-indicator]').hide();
  const scrollableRowWidth = $('[data-scrollable-row]').width();
  const fixedRowWidth = $('[data-fixed-row]').width();
  const initialScrollLeftPos = $('[data-fixed-row]').scrollLeft();
  const totalFixedRowAndScrollLeftWidth = initialScrollLeftPos + fixedRowWidth;
  const toggleIndicatorDisplay = (
    totalFixedRowAndScrollLeftWidth,
    scrollableRowWidth
  ) => {
    if (totalFixedRowAndScrollLeftWidth === scrollableRowWidth) {
      $('[data-overflow-indicator]').hide();
      $('#property-features-scroll-table').css('width', '100%');
    } else if (totalFixedRowAndScrollLeftWidth + 1 < scrollableRowWidth) {
      $('[data-overflow-indicator]').show();
    } else {
      $('[data-overflow-indicator]').hide();
      // $('#property-features-scroll-table').css('width', 'max-content');
    }
  };
  toggleIndicatorDisplay(totalFixedRowAndScrollLeftWidth, scrollableRowWidth);
  const onScroll = () => {
    const scrollLeftPos = $('[data-fixed-row]').scrollLeft();
    const _scrollableRowWidth = $('[data-scrollable-row]').width();
    const _fixedRowWidth = $('[data-fixed-row]').width();
    const totalFixedRowAndScrollLeftWidth = scrollLeftPos + _fixedRowWidth;
    toggleIndicatorDisplay(
      totalFixedRowAndScrollLeftWidth,
      _scrollableRowWidth
    );
    const $dropdownMenu = $('[data-sticky-jump-menu] .dropdown-menu');
    const $dropdownMenuClone = $('[data-clone]');
    var menuItemPos = $dropdownMenu.closest('[data-jump-menu-item]').position();
    if (menuItemPos) {
      $dropdownMenuClone.css({
        left: menuItemPos.left,
        top: menuItemPos.top + 30,
      });
    }
  };

  $('[data-fixed-row]').on('scroll', onScroll);
  $(window).on('resize', onScroll);
};
