import React, { Component, useEffect, useState, useRef } from 'react';
// import DateRangePicker from 'react-daterange-picker';
import DateRangePicker from '../DaterangePicker/src/index.js';
import WithLocation from '../WithLocation';
import Moment from 'moment-timezone';

import { extendMoment } from 'moment-range';
import { Query, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Router, Link } from '@reach/router';
import '../DaterangePicker/src/css/react-calendar.scss';

const CALENDAR_DATA = gql`
  query PropertyData($key: String) {
    property(where: { key: $key }) {
      name
      id
      sitePage {
        slug
      }
      vacancies: vacanciesFromCalendar {
        start
        end
        minStay
        nightsAvailable
      }
      calendar {
        start
        end
        availability
        checkInRestriction
        checkOutRestriction
        minPriorNotify
        minStay
      }
    }
  }
`;
const QUOTE_DATA = gql`
  query PropertyQuote($propertyId: Int, $checkIn: Date, $checkOut: Date, $promoCode: String) {
    propertyQuote(
      where: { propertyId: $propertyId, checkIn: $checkIn, checkOut: $checkOut, promoCode: $promoCode }
    ) {
      baseRate
      rate
      cleaningFee
      petFee

      
      damageDeposit
      avgPerNight
      totalNights

      availablePromo {
        terms
      }
      promoDiscount {
        percent
        baseRateDiscount
        baseRateDiscountApplied
        rate
        tax {
          lodgingTax
          countyTax
          stateTax
        }
      }      
      property {
        stateTaxRate
        countyTaxRate
        petFee
      }
    }
  }
`;
const moment = extendMoment(Moment);
const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const Picker = ({ propertyKey, promo, location }) => {
    // console.log('propertyKey', propertyKey);
    // console.log('utcdate', moment('2024-07-06').utc())
    // console.log('utcdate2', moment().utc())
    // //console.log('utcdate3', moment().tz("Europe/London"))
    // console.log('utcdate4', moment().tz("America/New_York"))
    const { data, error, loading, refetch } = useQuery(CALENDAR_DATA, {
        variables: { key: propertyKey },
    });

    const property = data?.property || {};
    // console.log('property', property);
    const [selectedRange, setSelectedRange] = useState({ value: null });
    const [selectedStart, setSelectedStart] = useState({ value: null });
    const params = new URLSearchParams(location.location.search);

    const [defaultStartDate, setDefaultStartDate] = useState(params.get('checkIn') ? new Date(params.get('checkIn')) : new Date());
    const propertyQuoteTableRef = useRef(null);
    const propertyQuoteDiscountRef = useRef(null);
    const minimumStayNotMetRef = useRef(null);
    console.log('selectedRange', selectedRange)
    // const {
    //     data: quoteData,
    //     error: quoteError,
    //     loading: quoteLoading,
    // } = useQuery(QUOTE_DATA, {
    //     variables: {
    //         propertyId: data?.property.id,
    //         checkIn: selectedRange.value?.start,
    //         checkOut: selectedRange.value?.end,
    //         promoCode: promo
    //     },
    //     skip: selectedRange.value == null,
    // });
    // if (quoteLoading && minimumStayNotMetRef.current) {
    //     minimumStayNotMetRef.current.style.display = 'none';
    // }
    // console.log('quoteData', quoteData);
    const [refreshCalendarKey, setRefreshCalendarKey] = useState(Date.now());
    const [ranges, setRanges] = useState([]);
    const [hasEndDate, setHasEndDate] = useState(false);
    const [flexibilityInDays, setFlexibilityInDays] = useState(0);

    // const validationErrors =
    //     quoteError?.graphQLErrors[0]?.extensions?.exception?.validationErrors;
    //if (validationErrors) setFormFeedback(validationErrors);

    if (loading) return null;

    // const getNumberOfConsecutiveDaysAvailable = (
    //     dateKey,
    //     availabilityCalendar
    // ) => {
    //     const allDates = Object.keys(availabilityCalendar);
    //     const dateRange = allDates.slice(allDates.indexOf(dateKey));
    //     const consecutiveDates = [];
    //     dateRange.some(date => {
    //         consecutiveDates.push(availabilityCalendar[date]);
    //         return availabilityCalendar[date].availability === 'N';
    //     });
    //     return consecutiveDates.slice(0, consecutiveDates.length - 1).length;
    // };

    // const getAvailabilityCalendar = property => {
    //     const {
    //         availability: availabilityString,
    //         checkInRestriction: checkInRestrictionString,
    //         checkOutRestriction: checkOutRestrictionString,
    //         minStay: minStayString,
    //         minPriorNotify: minPriorNotifyString,
    //     } = property.calendar;
    //     // console.log('property.calendar', property.calendar)
    //     const availability = availabilityString.split('');
    //     const checkInRestriction = checkInRestrictionString.split('');
    //     const checkOutRestriction = checkOutRestrictionString.split('');
    //     const minStay = minStayString.split(',');
    //     const minPriorNotify = minPriorNotifyString.split(',');
    //     const calendarStartDate = property.calendar.start;
    //     const availabilityCalendarBase = checkInRestriction.reduce(
    //         (acc, day, index) => {
    //             const dateKey = moment(calendarStartDate)
    //                 .utc()
    //                 .add(index, 'days')
    //                 .format('YYYY-MM-DD');
    //             acc[`${dateKey}`] = {
    //                 dateKey,
    //                 availability: availability[index],
    //                 checkInRestriction: checkInRestriction[index],
    //                 checkOutRestriction: checkOutRestriction[index],
    //                 minStay: minStay[index],
    //                 minPriorNotify: minPriorNotify[index],
    //             };

    //             return acc;
    //         },
    //         {}
    //     );

    //     const availabilityCalendar = Object.keys(availabilityCalendarBase).reduce(
    //         (acc, dateKey) => {
    //             const numberOfConsecutiveDaysAvailable = getNumberOfConsecutiveDaysAvailable(
    //                 dateKey,
    //                 availabilityCalendarBase
    //             );
    //             const daysFromNow =
    //                 moment(dateKey)
    //                     .utc()
    //                     .diff(moment().utc(), 'days') + 1;
    //             acc[dateKey] = {
    //                 ...availabilityCalendarBase[dateKey],
    //                 numberOfConsecutiveDaysAvailable,
    //                 minPriorNotifyFail:
    //                     daysFromNow < availabilityCalendarBase[dateKey].minPriorNotify
    //                         ? true
    //                         : false,
    //             };
    //             return acc;
    //         },
    //         {}
    //     );

    //     return availabilityCalendar;
    // };
    // console.log('acal', getAvailabilityCalendar(property));
    // const availabilityCalendarDictionary = getAvailabilityCalendar(property);
    // const availabilityCalendarValues = Object.values(
    //     availabilityCalendarDictionary
    // );




    const handleSelect = (range, states) => {
        // range is a moment-range object
        console.log('handleSelect:', range);
        if (propertyQuoteTableRef.current) {

            minimumStayNotMetRef.current.style.display = 'none';
            propertyQuoteTableRef.current.style.display = 'block';

        }
        if (propertyQuoteDiscountRef.current) {
            propertyQuoteDiscountRef.current.style.display = 'block';
        }

        setSelectedRange({
            value: range,
            states: states,
        });
    };
    const handleSelectStart = date => {
        if (propertyQuoteTableRef.current) {
            propertyQuoteTableRef.current.style.display = 'none';
        }
        if (propertyQuoteDiscountRef.current) {
            propertyQuoteDiscountRef.current.style.display = 'none';
        }

    };
    const stateDefinitions = {
        available: {
            color: null,
            label: 'Available',
        },
        nocheckin: {
            disabled: true,
            selectable: false,
            label: 'No Checkin',
        },
        enquire: {
            color: '#ffd200',
            label: 'Enquire',
        },
        unavailable: {
            selectable: false,
            color: '#cccccc',
            label: 'Unavailable',
        },
    };

    const availabilityCalendarToVacancies = property => {
        const { start, availability: availabilityString } = property.calendar;
        let day = start;
        const calendar = availabilityString.split('').reduce((acc, availability) => {
            if (availability === 'Y') {
                acc.push({
                    start: day, // formatDate(day),
                    resourceId: property.id,
                });
            }
            day = addDays(day, 1);
            return acc;
        }, []);
        return calendar;
    };

    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };


    const vacancies = property?.vacancies || {};
    const availabilityCalendar = property?.calendar;
    const vacanciesFromCalendar = availabilityCalendarToVacancies(property);

    // console.log('data', data);
    // console.log('vacancies', vacancies);
    // const availableRanges = vacancies
    //     .filter(vacancy => vacancy.nightsAvailable >= vacancy.minStay)
    //     .map(vacancy => {
    //         return {
    //             state: 'available',
    //             //range: moment.range(moment(vacancy.start), moment(vacancy.end)),
    //             range: moment.range(moment(vacancy.start).tz("America/New_York").format('YYYY-MM-DD'), moment(vacancy.end).tz("America/New_York").format('YYYY-MM-DD')),
    //             checkInRestrictions: 'NNNYYYYYYY',
    //         };
    //     });

    const dateRanges = [

        // ...availableRanges,
    ];

    return (
        <>

            <DateRangePicker
                key={refreshCalendarKey}
                numberOfCalendars={2}
                initialMonth={defaultStartDate.getMonth()}
                initialYear={defaultStartDate.getFullYear()}

                onRenderDateTitle={({ date }) => {
                    // const calendarData =
                    //     availabilityCalendarDictionary[date.format('YYYY-MM-DD')];
                    return '';
                }}
                isDateSelectableOverride={({ date, state }) => {
                    return true;
                }}
                onHighlightDatePreFlight={({ date, state }) => {
                    return true;
                }}
                onDateCellClassNames={({ date, ...props }) => { }}
                selectionType="range"
                minimumDate={new Date()}
                stateDefinitions={stateDefinitions}
                //  dateStates={dateRanges}
                defaultState="available"
                showLegend={false}
                value={selectedRange.value}

                onSelect={handleSelect}
                onSelectStart={handleSelectStart}
                onDateClick={({ isSelectable }) => {
                    // console.log('onDateClick:selectedRange.value', selectedRange.value)
                    // console.log('onDateClick', props)
                    // minimumStayNotMetRef.current.style.display = isSelectable ? 'none' : 'block';
                }}
            />
            {/* 
            {!quoteLoading && validationErrors && (
                <div className="row">
                    <div className="col">
                        <span>{validationErrors[0].message}</span>
                    </div>
                </div>
            )} */}
            <div class="row">
                <div class="col col-12  col-md-6 pr-md-3">
                    <select
                        name="flexibilityInDays"
                        value={flexibilityInDays}
                        // defaultValue="2"
                        onChange={e => {
                            setFlexibilityInDays(e.target.value);
                            //   if (selectedRange.value?.start && selectedRange.value?.end) {
                            //     window.location.href = `/rentals?checkin=${selectedRange.value?.start.format(
                            //                 'YYYY-MM-DD'
                            //             )}&checkout=${selectedRange.value?.end.format('YYYY-MM-DD')}&flexibilityInDays=${flexibilityInDays}`;
                            //   }
                        }}
                        className="custom-select-list rounded form-control select-list w-100 mb-2"
                    >
                        <option value={0}>Not Flexible</option>
                        <option value={7}>Flexible for +/- 1 week</option>
                        <option value={14}>Flexible for +/- 2 weeks</option>
                    </select>


                </div>

                <div class="col col-12 d-flex align-items-start justify-content-end col-md-6">

                    <div className="text-center">
                        <div>
                            <a
                                style={{ width: "170px" }}
                                class={`btn btn-secondary rounded text-primary font-weight-bold ${(selectedRange.value ==
                                    null) &&
                                    'disabled'}`}
                                disabled={selectedRange.value == null}
                                href={`/rentals?checkIn=${selectedRange.value?.start.format(
                                    'YYYY-MM-DD'
                                )}&checkOut=${selectedRange.value?.end.format('YYYY-MM-DD')}${flexibilityInDays > 0 ? `&flexibilityInDays=${flexibilityInDays}` : ''}`}
                            // /rentals?checkIn=2024-12-18&checkOut=2024-12-25&flexibilityInDays=7
                            >
                                Done
                            </a>
                        </div>
                        <button
                            class="btn  my-1 p-0 btn-link"
                            onClick={() => {
                                setDefaultStartDate(
                                    selectedRange.value?.start
                                        ? new Date(selectedRange.value?.start)
                                        : new Date()
                                );
                                setSelectedRange({ value: null });
                                setRefreshCalendarKey(Date.now());
                            }}
                        >
                            Clear Dates
                        </button>
                    </div>
                </div>


            </div >

        </>
    );
};
export default WithLocation(Picker);
