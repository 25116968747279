// import { createContext } from "react"

// export default createContext([{}, () => {}])

import React, { Component, useState, useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import deepmerge from 'deepmerge';

// import { getTemplate } from '../../util/';

const PagePropsContext = React.createContext();
const { Provider, Consumer } = PagePropsContext;

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

const PagePropsProvider = ({ value, children }) => {
  const [pagePropsState, setPagePropsState] = useState(value);
  const setPageProps = (newValue, options) => {
    setPagePropsState(prev => {
      // prev value contains 'CMScontent.blockData', newValue does not
      // return deepmerge(prev, newValue, { arrayMerge: overwriteMerge });
      if (options.arrayMerge === 'overwrite') {
        return deepmerge(prev, newValue, { arrayMerge: overwriteMerge });
      }
      return deepmerge(prev, newValue);
    });
  };
  useEffect(() => {
    const { location } = pagePropsState;
    var searchParams = new URLSearchParams(location.search);
    const splitURLPath = (location?.pathname || '').split('/');
    const isPreviewPage =
      searchParams.get('cmspreview') ||
      splitURLPath.indexOf('cmspreview') !== -1;

    const getBroadcastChannelName = () => {
      if (searchParams.get('cmspreview')) {
        return searchParams.get('cmspreview');
      } else if (splitURLPath[2]) {
        return splitURLPath[2];
      }
      return null;
    };

    if (isPreviewPage) {
      console.log('is preview page');
      const broadcastChannel = new BroadcastChannel(getBroadcastChannelName());

      broadcastChannel.postMessage('init');
      broadcastChannel.onmessage = ({ data = {}, options = {} }) => {
        console.log(
          'context fullurl!',
          data?.data?.bhip?.property?.floorPlan?.fileInstance?.file
            ?.versions?.[0]?.fullUrl
        );
        if (data) {
          setPageProps(data, options);
        }
      };
    }

    // console.log('pagePropsState', pagePropsState);

    //   if ((location?.pathname || '').split('/').indexOf('cms-preview') !== -1) {
    //     const broadcastChannel = new BroadcastChannel(
    //       searchParams.get('cmspreview')
    //     );
    //     broadcastChannel.postMessage('init');
    //     broadcastChannel.onmessage = ({ data, options = {} }) => {
    //       console.log('onmessage', data);
    //       if (data) {
    //         setPageProps(data, options);
    //       }
    //     };
    //   }
  }, []);

  return <Provider value={[pagePropsState, setPageProps]}>{children}</Provider>;
};

// export const usePageProps = () => {
//   const pageProps = useContext(PagePropsContext)

//   console.log("pageProps", pageProps)

//   return pageProps
// }

export { PagePropsProvider, Consumer as PagePropsConsumer };
export default PagePropsContext;
