import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import LightBox from '../../../../components/LightBox';
// import photoswipe from '../../../../util/photoswipe';
import { getVersionMap } from '../../../../util/helpers';
// import Slider from 'react-slick';
import Loading from '../../../../components/Loading';

import OwlCarousel from 'react-owl-carousel2';
import './styles.scss';

// import './react-owl-styles.scss';
import 'react-owl-carousel2/lib/styles.css';

// const NextArrow = props => {
//   const { className, style, onClick } = props;
//   return (
//     <button
//       className="slick-next"
//       style={{ ...style, display: 'block' }}
//       onClick={onClick}
//     >
//       <img src="/lightbox-next.svg" width="40" height="40" alt="Next" />
//     </button>
//   );
// };

// const PrevArrow = props => {
//   const { className, style, onClick } = props;
//   return (
//     <button
//       className="slick-prev"
//       style={{ ...style, display: 'block' }}
//       onClick={onClick}
//     >
//       <img src="/lightbox-previous.svg" alt="Previous" width="40" height="40" />
//     </button>
//   );
// };

function getYoutubVideId(url) {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return 'error';
  }
}
Date.prototype.getWeek = function() {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};
const offsetImageIndex = imageIndex => {
  return imageIndex + 1 > 3 ? 0 : imageIndex + 1;
};
const FeaturedFilesSlider = React.memo(({ featuredFiles, today, id }) => {
  // const FeaturedFilesSlider = React.memo(({ featuredFiles, today }) => {

  let sliderParent = null;

  useEffect(() => {
    console.log('component render');

    // $(sliderParent)
    //   .not('.slick-initialized')
    //   .slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     fade: true,
    //     useCSS: true,
    //     adaptiveHeight: true,
    //     useTransitions: true,
    //     arrows: true,
    //     nextArrow: renderToString(<NextArrow />),
    //     prevArrow: renderToString(<PrevArrow />),
    //   });
    const slickSlides = document.querySelectorAll('.slick-slide');
    const $firstSlide = $(slickSlides[0]);

    const onPrevClick = () => {
      const $activeSlide = $('.slick-active');
      const $prevSibling = $activeSlide.prev();
      const isBeginningOfList = !$prevSibling.hasClass('slick-slide');
      if (isBeginningOfList) {
        const slickSlides = document.querySelectorAll('.slick-slide');
        const $lastSlide = $(Array.from(slickSlides)[slickSlides.length - 1]);
        $activeSlide.css('z-index', '998');
        $lastSlide.css('z-index', '999');
      } else {
        $activeSlide.css('z-index', '998');
        $prevSibling.css('z-index', '999');
      }
    };

    const onNextClick = () => {
      const $activeSlide = $('.slick-active');
      const $nextSibling = $activeSlide.next();
      const isEndOfList = !$nextSibling.hasClass('slick-slide');

      if (!isEndOfList) {
        $activeSlide.css('z-index', '998');
        $nextSibling.css('z-index', '999');
      } else {
        $activeSlide.css('z-index', '998');
        $firstSlide.css('z-index', '999');
      }
    };
    const setZindexOnActiveImage = event => {
      const slickSlides = document.querySelectorAll('.slick-slide');
      const $activeSlide = $('.slick-active');
      const direction = event.target.alt;

      direction === 'Next' ? onNextClick() : onPrevClick();

      // $activeSlide.removeClass('add-z-index')
    };
    const $activeSlide = $('.slick-active');
    $activeSlide.css('z-index', '998');
    $firstSlide.css('z-index', '999');

    $('.slick-next').on('click', setZindexOnActiveImage);
    $('.slick-prev').on('click', setZindexOnActiveImage);

    return () => {
      console.log('UNMOUNT');
      $(sliderParent).slick('unslick');
      // $(sliderParent).removeClass('slick-initialized');
      $('.slick-next').off('click', setZindexOnActiveImage);
      $('.slick-prev').off('click', setZindexOnActiveImage);
      // const slider = document.querySelector('#slider');
      // slider.innerHTML = '';
      // slider && slider.remove();
    };
  }, []); // added argument for testing

  // today.setDate(today.getDate() - 2); // Can use this to adjust date and test. (subtracts days from today)
  const imageToDisplayIndex = today.getWeek() % 4;
  const imageToDisplayIndexAdjustedForWednesdays =
    today.getDay() > 2
      ? offsetImageIndex(imageToDisplayIndex)
      : imageToDisplayIndex;
  const hasVideoLeader = featuredFiles[0].mimetype.match('video/youtube');

  const filteredFiles = featuredFiles.filter(
    (file, index) =>
      index > (hasVideoLeader ? 4 : 3) ||
      index ===
        imageToDisplayIndexAdjustedForWednesdays + (hasVideoLeader ? 1 : 0) ||
      file.mimetype.match('video/youtube')
  );

  const options = {
    items: 1,
    nav: true,
    navElement: 'button',
    navText: [
      '<img src="/lightbox-previous.svg" alt="Previous" width="40" height="40" />',
      '<img src="/lightbox-next.svg" width="40" height="40" alt="Next" />',
    ],
    rewind: true,
    autoplay: false,
    autoHeight: true,
    animateIn: 'fadeIn', // add this
    animateOut: 'fadeOut', // and this
  };

  return (
    <LightBox id={id}>
      <OwlCarousel options={options}>
        {filteredFiles.length > 0 &&
          filteredFiles.map((featuredFile, index) => {
            const Slide = () => {
              if (featuredFile.mimetype.match('video/youtube')) {
                return (
                  <div key={index} className="aspect ratio-16x9">
                    <iframe
                      src={`//www.youtube.com/embed/${getYoutubVideId(
                        featuredFile.fullUrl
                      )}${
                        featuredFile.fullUrl.includes('&') ? '&rel=0' : '?rel=0'
                      }`}
                      frameBorder="0"
                      allowFullScreen
                      title="Beach House Featured Video"
                    />
                  </div>
                );
              }
              const versionMap = getVersionMap(featuredFile);
              return (
                <figure>
                  {versionMap && Object.keys(versionMap).length > 0 && (
                    <a
                      href={featuredFile.fullUrl}
                      data-size={`${featuredFile.width}x${featuredFile.height}`}
                      data-size-mobile={`${versionMap.xlarge.width}x${versionMap.xlarge.height}`}
                      data-href-mobile={versionMap.xlarge.fullUrl}
                    >
                      <img
                        key={index}
                        style={{
                          width: '100%',
                          height: '100%',
                          flex: '1 1',
                          objectFit: 'cover',
                        }}
                        src={versionMap.large.fullUrl}
                        alt={featuredFile.alt}
                        itemProp="thumbnail"
                      />
                    </a>
                  )}
                </figure>
              );
            };

            return (
              <div
                key={`${featuredFiles?.fullUrl}_${index}`}
                className="slide-parent"
                style={{ background: '#FFF' }}
              >
                <Slide />
              </div>
            );
          })}
      </OwlCarousel>
    </LightBox>
  );
  // });
});

FeaturedFilesSlider.defaultProps = {
  today: new Date(),
};

export default FeaturedFilesSlider;
