import React, { useEffect } from 'react';

const DatePickerPrevious = ({
  currentMonth,
  selectedMonth,
  selectedYear,
  currentYear,
  id,
}) => {
  useEffect(() => {
    selectedMonth === currentMonth + 1 && selectedYear === currentYear
      ? $(`#datepicker-previous-button${id}`).addClass('disable-navigation')
      : $(`#datepicker-previous-button${id}`).removeClass('disable-navigation');
  });
  return (
    <img
      className="no-resize"
      id={`datepicker-previous-button${id}`}
      src="/previous.svg"
      alt="Previous"
      width="30"
      height="40"
    />
  );
};

export default DatePickerPrevious;
