import { useStaticQuery, graphql } from 'gatsby';

export const getSiteMeta = () => {
  const { bhip } = useStaticQuery(
    graphql`
      query SiteMeta {
        bhip {
          siteMeta {
            key
            value
          }
        }
      }
    `
  );
  const siteMeta = bhip.siteMeta.reduce((acc, metaEntry) => {
    acc[metaEntry.key] = metaEntry.value;
    return acc;
  }, {});
  return siteMeta;
};
