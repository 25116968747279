import React, { useContext } from 'react';
import {
  PagePropsConsumer,
  PagePropsProvider,
} from './Components/PagePropsContext';

export const wrapPageElement = ({ props, ...rest }) => {
  const { component } = props.pageResources;
  //  const { location } = props;
  const { location } = props;
  var searchParams = new URLSearchParams(location.search);
  const splitURLPath = (location?.pathname || '').split('/');
  const isPreviewPage =
    searchParams.get('cmspreview') || splitURLPath.indexOf('cmspreview') !== -1;
  // const isPreviewPage = searchParams.get('cmspreview') ? true : false;

  return isPreviewPage ? (
    <PagePropsProvider value={props}>
      <PagePropsConsumer>
        {([pageProps, setPageProps]) => {
          return (
            <>
              {React.createElement(component, {
                ...props,
                ...pageProps,
                key: props.path || props.pageResources.page.path,
              })}
            </>
          );
        }}
      </PagePropsConsumer>
    </PagePropsProvider>
  ) : (
    <PagePropsProvider value={props}>
      {React.createElement(component, {
        ...props,
        key: props.path || props.pageResources.page.path,
      })}
    </PagePropsProvider>
  );
};
