require('formdata-polyfill');

// if (!Object.entries) {
//   Object.entries = function(obj) {
//     var ownProps = Object.keys(obj),
//       i = ownProps.length,
//       resArray = new Array(i); // preallocate the Array
//     while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

//     return resArray;
//   };
// }

export const formDataToObject = form => {
  const data = new FormData(form);
  console.log('data', data);
  return Array.from(data.entries()).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
};

export const formDataToArray = form => {
  const data = new FormData(form);
  console.log('data', data);
  return Array.from(data.entries()).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(`${value}`);
    }
    return acc;
  }, []);
};
