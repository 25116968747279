import React, { useState, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../layouts/DefaultLayout';
import ScrollTable from '../../components/ScrollTable';
import styles from './styles.module.scss';
import Modal from '../../components/Modal';
import './styles.scss';

import { Link } from 'gatsby';
// const popoverAttributes = {
//   'data-trigger': 'click',
//   'data-container': '.list-item',
//   'data-toggle': 'popover',
//   'data-placement': 'top',
//   'data-template':
//     '<div class="popover popover-container custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>',
//   'data-content':
//     'Book multiple beach houses in a single reservation and Save. This beach house compound consists of Gemini Beach, Art on the Beach, Xanadu, Nirvana Shores, Restoration Sands, Eden Beach Retreat.',
// };

const checkForAmenityFeatures = (amenities, trueValue, falseValue) => {
  const found = amenities.find(amenity => {
    return amenity.features;
  });
  if (found) return trueValue;
  return falseValue;
};

const possibleAmenities = {
  oceanfront: true,
  riverfront: true,
  pool: true,
  hottub: true,
  elevator: true,
  dock: true,
  riverfrontRecreationalArea: true,
  resortAccess: true,
  swimSpa: true,
  puttingGreen: true,
};

const PropertyDisplay = ({ property }) => {
  useEffect(() => {
    $('[data-toggle="popover"]').popover({
      container: 'body',
    });
    $('.property-title').on('click', e => {
      if ($(e.target).hasClass('list-item')) {
        e.preventDefault();
      }
    });
    return () => {
      $('[data-toggle="popover"]').popover('dispose');
    };
  }, []);

  const childPropertyTextArray = property?.childProperties?.reduce(
    (acc, property) => {
      if (property?.name) {
        acc.push(property?.name);
      }
      return acc;
    },
    []
  );
  const childPropertyTextString =
    childPropertyTextArray && childPropertyTextArray.join(', ');

  const popoverAttributes = {
    'data-trigger': 'hover',
    // 'data-container': 'body',
    'data-toggle': 'popover',
    'data-placement': 'top',
    'data-template':
      '<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>',
    'data-content': `Book multiple beach houses in a single reservation and Save. This beach house compound consists of ${childPropertyTextString}.`,
  };

  const thumbnailUrl =
    property?.sitePage?.featuredImageSecondary?.file?.version?.fullUrl || '';
  return (
    <Link
      to={`/${property.sitePage.slug}`}
      className="property-title text-primary"
    >
      <div className="d-flex align-items-center">
        <div className="position-relative mr-1plus d-none d-lg-flex">
          <img
            className=" no-resize"
            width="92"
            height="69"
            src={thumbnailUrl}
          />
          {property.status === 'COMING_SOON' && (
            <span className="thumbnail-label text-small">Coming Soon</span>
          )}
        </div>
        <br />
        <div className="property-list ml-xl-1plus">
          <span className="text-primary font-weight-bold">{property.name}</span>
          <div>
            <span
              className="mr-1plus property-list-subtext font-weight-normal"
              title="Bedrooms"
            >
              <span className="mr-1">
                <img width="26" height="20" src="/bed.svg" alt="Bedrooms" />
              </span>
              {property.bedrooms}
            </span>
            <span
              className="mr-1plus property-list-subtext font-weight-normal"
              title="Baths"
            >
              <span className="mr-1">
                <img width="20" height="20" src="/bath.svg" alt="Bathrooms" />
              </span>
              {property.bathrooms}
            </span>
            <span
              className="mr-1plus property-list-subtext font-weight-normal"
              title="Sleeps"
            >
              <span className="mr-1">
                <img width="16" height="20" src="/sleeps.svg" alt="Sleeps" />
              </span>
              {property.sleeps}
            </span>
          </div>
          {property?.type === 'COMBO' && (
            <div className={`my-1 p-0 tooltip-link`} {...popoverAttributes}>
              <span className="list-item">Compound Property</span>
            </div>
          )}
        </div>
        {/* <div className="property-list">
          {' '}
          <span className="text-primary font-weight-bold">{property.name}</span>
          <br />
          <span className="font-weight-normal property-list-subtext">
            {property.bedrooms}BR / {property.bathrooms}BA
          </span>
          <br />
          <span className="font-weight-normal property-list-subtext">
            Sleeps: {property.sleeps}
          </span>
          <span />
        </div> */}
      </div>
    </Link>
  );
};

const orderBy = key => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

// The native sort modifies the array in place. `_.orderBy` and `_.sortBy` do not, so we use `.concat()` to
// copy the array, then sort.

const PropertyOverview = ({ pageContext, ...props }) => {
  const { properties, sitePage } = props.data.bhip;
  const [resortAccessModalIsOpen, setResortAccessModalIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [reverse, setReverse] = useState(false);
  useEffect(() => {
    $('.tooltip-link').popover();
    return () => {
      $('.tooltip-link').popover('dispose');
    };
  }, []);

  const setOrderByData = sortKey => {
    sortBy === sortKey ? setReverse(prevState => !prevState) : setReverse(true);
    setSortBy(() => sortKey);
  };

  const propertiesWithoutCombos = properties.filter(
    property =>
      property.type !== 'COMBO' &&
      property?.sitePage?.featuredImageSecondary &&
      property?.sitePage?.status === 'PUBLISHED'
  );
  const propertiesWithCombos = properties.filter(
    property =>
      property.type === 'COMBO' &&
      property?.sitePage?.featuredImageSecondary &&
      property?.sitePage?.status === 'PUBLISHED'
  );
  const sortedPropertiesByCombo = [
    ...propertiesWithoutCombos,
    ...propertiesWithCombos,
  ];
  const transformedPropertiesList = sortedPropertiesByCombo
    // &&
    // properties
    //   .filter(
    //     property =>
    //       property.type !== 'COMBO' &&
    //       (property.sitePage || {}).status === 'PUBLISHED'
    //   )
    .map(property => {
      const amenityList = property.amenities.reduce((acc, amenity) => {
        Object.keys(possibleAmenities).forEach(possibleAmenity => {
          if (!acc[possibleAmenity]) {
            if (amenity.key === 'swimSpa') {
              acc['pool'] = 1;
            } else if (amenity.features) {
              acc[amenity.key] = 1;
            } else {
              acc[possibleAmenity] = amenity.key === possibleAmenity ? 2 : 0;
            }
          }
        });

        return acc;
      }, {});
      return { ...property, ...amenityList };
    });
  const orderedProperties = reverse
    ? transformedPropertiesList
        .concat()
        .sort(orderBy(sortBy))
        .reverse()
    : transformedPropertiesList.concat().sort(orderBy(sortBy));

  return (
    <Layout searchbarLabel={'Property Features'}>
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        headTags={sitePage.headTags}
      />
      <Modal
        disableFocusTrap={true}
        title={`Resort Access Info`}
        isOpen={resortAccessModalIsOpen}
        expanded={true}
        onClose={() => setResortAccessModalIsOpen(false)}
        id="resort-modal"
      >
        <p className="mb-2">
          Included free with your rental of 7+ nights: Free Guest passes to the
          Ocean Club amenities of the nearby Marriott Hutchinson Island Resort &
          Marina (3 resort swimming pools, tennis, fitness, & golf)
        </p>
        <div>
          <img
            src="../marriot-collage_900x.jpg"
            width="9"
            height="6"
            style={{ width: '100%' }}
          />
        </div>
        <div className="text-center py-3 mt-1">
          <button
            className="btn btn-secondary text-primary w-50 font-weight-bold rounded"
            onClick={() => {
              setResortAccessModalIsOpen(false);
            }}
          >
            Close
          </button>
        </div>
      </Modal>
      <div className="container scroll-table-container mt-2">
        <div className="no-gutters-sm-down">
          <div
            className="mb-2 text-center rotate-screen-indicator"
            data-rotate-screen-indicator
          >
            <span>
              <img width="20" height="20" src="/rotate-device.svg" /> Rotate
              your device for the best display.
            </span>
          </div>
          <div id="property-overview-table-wrapper">
            <ScrollTable>
              <table
                id="property-features-scroll-table"
                className={`table ${styles.propertyOverviewTable}`}
              >
                <thead>
                  <tr className="vertical-align">
                    <th
                      id="first-header"
                      scope="col"
                      className="align-bottom text-left border-0"
                    >
                      Name
                    </th>
                    <td
                      scope="col"
                      className="align-bottom text-center border-0"
                    >
                      <b onClick={() => setOrderByData('oceanfront')}>
                        <span>Ocean</span>
                        <span className="d-none d-lg-inline"> </span>
                        <div className="d-lg-inline">
                          Front
                          {sortBy === 'oceanfront' && !reverse && (
                            <img
                              className="ml-1"
                              src="/arrow.svg"
                              width="8"
                              height="5"
                            />
                          )}
                          {sortBy === 'oceanfront' && reverse && (
                            <img
                              src="/arrow-up.svg"
                              width="8"
                              className="ml-1"
                              height="5"
                            />
                          )}
                        </div>
                      </b>
                    </td>

                    <td
                      scope="col"
                      className="align-bottom text-center border-0"
                    >
                      <b onClick={() => setOrderByData('pool')}>
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <span>Heated</span>
                          <br />
                          <span>Pool</span>

                          {sortBy === 'pool' && !reverse && (
                            <img
                              className="ml-1"
                              src="/arrow.svg"
                              width="8"
                              height="5"
                            />
                          )}
                          {sortBy === 'pool' && reverse && (
                            <img
                              src="/arrow-up.svg"
                              width="8"
                              className="ml-1"
                              height="5"
                            />
                          )}
                        </span>
                      </b>
                    </td>
                    <td
                      scope="col"
                      className="align-bottom text-center border-0"
                    >
                      <b onClick={() => setOrderByData('dock')}>
                        <br />
                        Dock
                        {sortBy === 'dock' && !reverse && (
                          <img
                            className="ml-1"
                            src="/arrow.svg"
                            width="8"
                            height="5"
                          />
                        )}
                        {sortBy === 'dock' && reverse && (
                          <img
                            src="/arrow-up.svg"
                            width="8"
                            className="ml-1"
                            height="5"
                          />
                        )}
                      </b>
                    </td>
                    <td
                      scope="col"
                      className="align-bottom text-center border-0"
                    >
                      <b onClick={() => setOrderByData('elevator')}>
                        Elevator
                        {sortBy === 'elevator' && !reverse && (
                          <img
                            className="ml-1"
                            src="/arrow.svg"
                            width="8"
                            height="5"
                          />
                        )}
                        {sortBy === 'elevator' && reverse && (
                          <img
                            src="/arrow-up.svg"
                            width="8"
                            className="ml-1"
                            height="5"
                          />
                        )}
                      </b>
                    </td>
                    <td
                      scope="col"
                      className="align-bottom text-center border-0 px-xl-0"
                    >
                      <b
                        style={{ whiteSpace: 'initial' }}
                        onClick={() =>
                          setOrderByData('riverfrontRecreationalArea')
                        }
                      >
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <span>Riverfront</span>
                          <br />
                          <span>Recreation Area</span>
                          {sortBy === 'riverfrontRecreationalArea' &&
                            !reverse && (
                              <img
                                className="ml-1"
                                src="/arrow.svg"
                                width="8"
                                height="5"
                              />
                            )}
                          {sortBy === 'riverfrontRecreationalArea' &&
                            reverse && (
                              <img
                                src="/arrow-up.svg"
                                width="8"
                                className="ml-1"
                                height="5"
                              />
                            )}
                        </span>
                      </b>
                    </td>
                    <td
                      scope="col"
                      className="align-bottom text-center border-0 position-relative"
                    >
                      <div
                        className="mr-2" /* style={{ whiteSpace: 'initial' }}*/
                      >
                        <b
                          className=""
                          // onClick={() => setOrderByData('resortAccess')}
                        >
                          <span onClick={() => setOrderByData('resortAccess')}>
                            Resort
                          </span>
                          <span className="d-none d-lg-inline"> </span>

                          {/* <span>Access</span> */}

                          <div className="d-lg-inline">
                            <span
                              className="align-bottom"
                              onClick={() => setOrderByData('resortAccess')}
                            >
                              Access
                            </span>
                            <a
                              onClick={() => {
                                setResortAccessModalIsOpen(true);
                              }}
                              style={{ cursor: 'pointer' }}
                              tabIndex="0"
                              id="more-info"
                              className="ml-1  position-absolute"
                            >
                              <img
                                src="/more-info.svg"
                                width="22"
                                height="22"
                              />
                            </a>
                            {sortBy === 'resortAccess' && !reverse && (
                              <img
                                className="ml-1"
                                src="/arrow.svg"
                                width="8"
                                height="5"
                              />
                            )}
                            {sortBy === 'resortAccess' && reverse && (
                              <img
                                src="/arrow-up.svg"
                                width="8"
                                className="ml-1"
                                height="5"
                              />
                            )}
                          </div>
                        </b>
                      </div>

                      {/* {sortBy === 'resortAccess' && !reverse && (
                      <img
                        className="ml-1"
                        src="/arrow.svg"
                        width="8"
                        height="5"
                      />
                    )}
                    {sortBy === 'resortAccess' && reverse && (
                      <img
                        src="/arrow-up.svg"
                        width="8"
                        className="ml-1"
                        height="5"
                      />
                    )} */}
                    </td>
                    <td
                      scope="col"
                      className="align-bottom text-center border-0"
                    >
                      <b
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={() => setOrderByData('hottub')}
                      >
                        <span>Hot</span>
                        <span className="d-none d-lg-inline"> </span>
                        <div className="d-lg-inline">
                          Tub
                          {sortBy === 'hottub' && !reverse && (
                            <img
                              className="ml-1"
                              src="/arrow.svg"
                              width="8"
                              height="5"
                            />
                          )}
                          {sortBy === 'hottub' && reverse && (
                            <img
                              src="/arrow-up.svg"
                              width="8"
                              className="ml-1"
                              height="5"
                            />
                          )}
                        </div>
                      </b>
                    </td>
                    <td
                      scope="col"
                      className="align-bottom text-center border-0"
                    >
                      <b onClick={() => setOrderByData('riverfront')}>
                        <span>River</span>
                        <span className="d-none d-lg-inline"> </span>
                        <div className="d-lg-inline">
                          Front
                          {sortBy === 'riverfront' && !reverse && (
                            <img
                              className="ml-1"
                              src="/arrow.svg"
                              width="8"
                              height="5"
                            />
                          )}
                          {sortBy === 'riverfront' && reverse && (
                            <img
                              src="/arrow-up.svg"
                              width="8"
                              className="ml-1"
                              height="5"
                            />
                          )}
                        </div>
                      </b>
                    </td>

                    <td
                      scope="col"
                      className="align-bottom text-center border-0"
                    >
                      <b onClick={() => setOrderByData('puttingGreen')}>
                        Putting Green
                        {sortBy === 'puttingGreen' && !reverse && (
                          <img
                            className="ml-1"
                            src="/arrow.svg"
                            width="8"
                            height="5"
                          />
                        )}
                        {sortBy === 'puttingGreen' && reverse && (
                          <img
                            src="/arrow-up.svg"
                            width="8"
                            className="ml-1"
                            height="5"
                          />
                        )}
                      </b>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {orderedProperties &&
                    orderedProperties.map(property => {
                      return (
                        <tr
                          key={property?.id}
                          className="align-middle text-left"
                        >
                          <th className="align-middle text-primary property-features-combo-th">
                            <PropertyDisplay property={property} />
                          </th>
                          <td className="align-middle text-center">
                            {property.amenities.find(
                              amenity => amenity.key === 'oceanfront'
                            ) ? (
                              <img
                                src="/checkmark.svg"
                                width="18"
                                height="13"
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td
                            style={{ whiteSpace: 'nowrap' }}
                            className="align-middle text-center"
                          >
                            {property.amenities.find(
                              amenity => amenity.key === 'swimSpa'
                            ) ? (
                              'Plunge Pool'
                            ) : property.amenities.find(
                                amenity => amenity.key === 'pool'
                              ) ? (
                              <img
                                src="/checkmark.svg"
                                width="18"
                                height="13"
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="align-middle text-center">
                            {property.amenities.find(
                              amenity => amenity.key === 'dock'
                            )
                              ? checkForAmenityFeatures(
                                  property.amenities,
                                  <div className="position-relative">
                                    <span>
                                      <img
                                        src="/checkmark.svg"
                                        width="18"
                                        height="13"
                                      />
                                    </span>{' '}
                                    <span
                                      className={`${styles.asterisk} position-absolute`}
                                    >
                                      *
                                    </span>
                                  </div>,
                                  <img
                                    src="/checkmark.svg"
                                    width="18"
                                    height="13"
                                  />
                                )
                              : ''}
                          </td>
                          <td className="align-middle text-center">
                            {property.amenities.find(
                              amenity => amenity.key === 'elevator'
                            ) ? (
                              <img
                                src="/checkmark.svg"
                                width="18"
                                height="13"
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="align-middle text-center">
                            {property.amenities.find(
                              amenity =>
                                amenity.key === 'riverfrontRecreationalArea'
                            ) ? (
                              <img
                                src="/checkmark.svg"
                                width="18"
                                height="13"
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="align-middle text-center">
                            {property.amenities.find(
                              amenity => amenity.key === 'resortAccess'
                            ) ? (
                              <img
                                src="/checkmark.svg"
                                width="18"
                                height="13"
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="align-middle text-center">
                            {property.amenities.find(
                              amenity => amenity.key === 'hottub'
                            ) ? (
                              <img
                                src="/checkmark.svg"
                                width="18"
                                height="13"
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="align-middle text-center">
                            {property.amenities.find(
                              amenity => amenity.key === 'riverfront'
                            ) ? (
                              <img
                                src="/checkmark.svg"
                                width="18"
                                height="13"
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="align-middle text-center">
                            {property.amenities.find(
                              amenity => amenity.key === 'puttingGreen'
                            ) ? (
                              <img
                                src="/checkmark.svg"
                                width="18"
                                height="13"
                              />
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </ScrollTable>
          </div>
          <p className="mb-5 mt-2 ml-2 ml-lg-0">* Shared Dock</p>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PropertyOverview($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      properties {
        status
        key
        name
        bedrooms
        bathrooms
        sleeps
        type
        amenities {
          name
          key
          features {
            key
          }
        }
        sitePage {
          slug
          status
          featuredImageSecondary {
            alt
            file {
              version(where: { key: "small" }) {
                fullUrl
              }
            }
          }
        }
        ... on BHIP_PropertyCombo {
          childProperties {
            name
            key
          }
        }
      }
    }
  }
`;
export default PropertyOverview;
