import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { Router, Location } from '@reach/router';
// import { PropertyTypeSelectConsumer } from '../../templates/page-rentals';
import PropertySelectProvider, {
  PropertySelectConsumer,
} from '../../contexts/PropertySelect';

const SelectList = ({
  options,
  classNames,
  hiddenValue,
  name,
  id,
  // context,
  formSubmitted,
  updateFormStatus,
  onSelectChange,
}) => {
  let searchParams = { get: () => {} };
  if (typeof window !== 'undefined') {
    searchParams = new URLSearchParams(window.location.search);
  }
  useEffect(() => {
    if (!searchParams.get(name)) {
      $(`#${id}`).val('');
    }
    $(`#${id}`)
      .find(`option:not('option:selected')`)
      .each((index, value) => {
        if ($(value).val() === '') {
          $(value).text($(value).data('original-label'));
        }
      });
  }, [searchParams.get(name)]);
  const [initialValue, setInitialValue] = useState(undefined);

  return (
    <Location>
      {({ location }) => {
        const searchParams = new URLSearchParams(location.search);
        return (
          <PropertySelectProvider>
            <PropertySelectConsumer>
              {stateContext => {
                const context = {
                  state: {
                    bedrooms: stateContext?.state?.bedroomsState,
                    baths: stateContext?.state?.bathsState,
                    sleeps: stateContext?.state?.sleepsState,
                  },
                };
                // if (context) {
                //   $(`#${id}`)
                //     .find(`option`)
                //     .each(function(index, value) {
                //       if ($(value).data('original-label') === 'Any') {
                //         // if ($(value).val() !== context.state[name]) {
                //         $(value).text($(value).data('original-label'));
                //         $(value).attr('hidden', false);
                //         // }
                //       }
                //     });
                // }
                const getControlledSelectListValue = () => {
                  if (context.state[name] || context.state[name] === '') {
                    return context.state[name];
                  } else if (searchParams.get(name)) {
                    return searchParams.get(name);
                  }
                  return '';
                };
                const selectListValueProp = {};
                if (stateContext) {
                  selectListValueProp.value = getControlledSelectListValue();
                } else {
                  selectListValueProp.defaultValue = searchParams.get(name);
                }
                return (
                  <div
                    className={`position-relative ${
                      styles.selectList
                    } w-100 h-100 ${classNames ? classNames : ''}`}
                  >
                    {/* <NavDropdown
                    className={`position-absolute d-inline-block ${
                      styles.arrowDown
                    }`}
                  /> */}

                    <label htmlFor={id} className="sr-only">
                      {hiddenValue}
                    </label>
                    <select
                      // defaultValue={searchParams.get(name)}
                      className="custom-select-list form-control select-list rounded"
                      // id="sel1"
                      onChange={event => {
                        onSelectChange(event.target.value);
                        stateContext &&
                          stateContext.setPropertyTypeValue(
                            name,
                            event.target.value
                          );
                        // setInitialValue(event.target.value);
                        const $select = $(event.target);
                        $select.find(`option`).each(function(index, value) {
                          if (index > 0) {
                            $(this).attr('hidden', false);
                          }
                        });

                        // added 'if' to resolve 'Any' option from disappearing when toggling between modal and header form
                        if (event.target.value !== '') {
                          $select
                            .find(`[value="${$select.val()}"]`)
                            .attr('hidden', true);
                        }
                        const $selectedOption = $select.find('option:selected');
                        const currentTextLabel = $selectedOption.text();
                        $select
                          .find(`option:not('option:selected')`)
                          .each((index, value) => {
                            $(value).text($(value).data('original-label'));
                          });
                        // added 'if' to resolve 'Any' option from disappearing when toggling between modal and header form
                        if (event.target.value !== '') {
                          $selectedOption.text(
                            `${hiddenValue} ${currentTextLabel}`
                          );
                        }
                      }}
                      type="select"
                      name={name}
                      id={id}
                      {...selectListValueProp}
                    >
                      <option data-default-value value="" hidden>
                        {hiddenValue}
                      </option>
                      {options.map(option => {
                        return (
                          <option
                            key={option.value}
                            value={option.value}
                            data-original-label={option.label}
                            // hidden={searchParams.get(name) === option.value}
                            hidden={
                              searchParams.get(name) === option.value ||
                              (!searchParams.get(name) && option.value === '')
                                ? true
                                : false
                            }
                          >
                            {searchParams.get(name) === option.value
                              ? `${hiddenValue} ${option.label}`
                              : option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                );
              }}
            </PropertySelectConsumer>
          </PropertySelectProvider>
        );
      }}
    </Location>
  );
};

SelectList.defaultProps = {
  onSelectChange: () => {},
};

export default SelectList;
