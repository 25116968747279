import https from 'https';
import http from 'http';
import fetch from 'isomorphic-fetch';
import Promise from 'bluebird';

export default ({ uri }) => {
  // const agent = (() => {
  //   if (uri.includes('https:')) {
  //     return new https.Agent({ keepAlive: true });
  //   }
  //   return new http.Agent({ keepAlive: true });
  // })();
  return {
    async query({ query, variables }) {
      return new Promise((resolve, reject) => {
        let attempts = 1;
        const fetch_retry = (uri, query, variables, n) => {
          const fetch_options = {
            // agent,
            timeout: 10000,
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
              query,
              variables,
            }),
          };
          return fetch(uri, fetch_options)
            .then(async result => {
              const results = await result.json();
              return resolve(results);
            })
            .catch(function(error) {
              //console.log('Error!!', error);
              if (n === 1) {
                reject(error);
              } else {
                setTimeout(() => {
                  attempts++;
                  fetch_retry(uri, query, variables, n - 1);
                }, attempts * 1000);
              }
            });
        };
        return fetch_retry(uri, query, variables, 10);
      });
    },
  };
};
