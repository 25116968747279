/**
 * Animates named anchor "Jump" menus with smooth transitions "<a href="#map">Jump to map</a>".  Also solves a browser that causes an improper "Jump" menu offset when a named anchor is in a fixed / sticky element.
 * Origin: https://stackoverflow.com/a/13067009/5767284
 */
module.exports = () => {
  (function(document, history, location) {
    var HISTORY_SUPPORT = !!(history && history.pushState);
    var smoothJumpMenus = {
      ANCHOR_REGEX: /^#[^ ]+$/,
      init: function() {
        this.scrollToCurrent();
        $(window).on('hashchange', $.proxy(this, 'scrollToCurrent'));
        $('body').on('click', 'a', $.proxy(this, 'delegateAnchors'));
      },
      getFixedOffset: function() {
        // [data-sticky-cms-observer]

        const getOffsetElement = () => {
          if ($('[data-jump-menu-wrapper]').length > 0) {
            return '[data-jump-menu-wrapper]';
          } else if ($('.sticky-row-mobile').length > 0) {
            return '.sticky-row-mobile';
          } else if ($('.sticky-row').length > 0) {
            return '.sticky-row';
          }
          return null;
        };
        const offsetElement = getOffsetElement();
        if (offsetElement) {
          if (
            window
              .getComputedStyle(document.querySelector(offsetElement))
              .getPropertyValue('position') === 'sticky'
          ) {
            return $(offsetElement).height() + 20;
          } else {
            const offset = $('.sticky-row')
              .first()
              .height();
            return offset ? offset + 20 : 0;
          }
        }
        return 0;
      },
      scrollIfAnchor: function(href, pushToHistory) {
        var match, anchorOffset;
        if (!this.ANCHOR_REGEX.test(href)) {
          return false;
        }
        match = document.getElementById(href.slice(1));
        if (match) {
          if (href.slice(1) === 'overview' || href.slice(1) === 'activities') {
            // Not a great solution, but it's a "good-enough" quick fix.
            anchorOffset = 0;
          } else {
            anchorOffset = $(match).offset().top - this.getFixedOffset();
          }
          $('html, body').animate({ scrollTop: anchorOffset });
          if (HISTORY_SUPPORT && pushToHistory) {
            history.pushState(
              {},
              document.title,
              location.pathname + location.search + href
            );
          }
        }
        return !!match;
      },
      scrollToCurrent: function(e) {
        if (this.scrollIfAnchor(window.location.hash) && e) {
          e.preventDefault();
        }
      },
      delegateAnchors: function(e) {
        var elem = e.target;
        if (this.scrollIfAnchor(elem.getAttribute('href'), true)) {
          e.preventDefault();
        }
      },
    };
    $(document).ready($.proxy(smoothJumpMenus, 'init'));
    window.smoothJumpMenus = smoothJumpMenus;
  })(window.document, window.history, window.location);
};
