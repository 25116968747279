import React, { Component, useEffect, useState } from 'react';
import PropertyDetails from '../single-property/components/PropertyDetails';
import CMSContent from '../../components/CMSContent';
import Layout from '../../layouts/JumpMenuLayout';
// import RequestInfoForm from '../../components/RequestInfoForm';
import Loadable from '@loadable/component';
const RequestInfoForm = Loadable(() =>
  import('../../components/RequestInfoForm')
); // code-splitted, on demand loaded component

import Modal from '../../components/Modal';
import SEO from '../../components/seo';

import styles from './styles.module.scss';
import Calendar from '../../components/Calendar';
import DynamicPropertyList from '../../components/DynamicPropertyList';

import { Link, graphql } from 'gatsby';
import Slider from '../../components/Slider';
import Media from '../../components/Media';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { getYoutubeVideoId } from '../../util/helpers';
import PropertyAvailability from '../../components/PropertyAvailability';

const COMBO_CALENDAR_DATA = gql`
  query {
    properties(where: { type: COMBO }) {
      id
      name
      key
      ... on PropertyCombo {
        childProperties {
          name
          calendar {
            start
            end
            availability
            minStayThresholdAlert
          }
        }
      }
    }
  }
`;

const PropertyCombo = props => {
  const { pageContext, data, location } = props;
  const { sitePage } = data.bhip;
  const properties = data?.bhip?.properties || {};
  const propertyCombos = properties.filter(
    property => property.type === 'COMBO'
  );
  const [requestInfoModalIsOpen, setRequestInfoModalIsOpen] = useState(false);
  const [selectedPropertyKey, setSelectedPropertyKey] = useState(null);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  const jumpMenuOptions = propertyCombos
    .filter(property => !property.name.includes('+'))
    .map(combo => {
      return {
        name: combo.name,
        key: combo.key,
      };
    });

  jumpMenuOptions.push({
    name: 'Custom Combinations',
    key: 'custom-combinations',
  });

  useEffect(() => {
    $('.dropdown-column').css('margin-left', 0);
    $('.dropdown-column').css('margin-right', 0);
    if (window.location.hash) {
      setTimeout(() => {
        window.smoothJumpMenus.scrollToCurrent();
      }, 500);
    }
    var showPopover = function () {
      $(this).popover('toggle');
    },
      hidePopover = function () {
        $(this).popover('hide');
      };
    $('.popover-dismiss')
      .popover({
        trigger: 'manual',
      })
      .click(showPopover)
      .blur(hidePopover);
    return () => {
      $('.popover-dismiss').popover('dispose');
    };
  }, []);

  const getCombinedChildrenAvailability = property => {
    const combinedAvailability = Array.from({
      length: property?.childProperties[0]?.calendar?.availability?.length || 0,
    }).reduce((acc, _, index1) => {
      const match = property.childProperties.find((_, index2) => {
        return (
          property.childProperties[index2].calendar.availability[index1] === 'N'
        );
      });
      acc += match ? 'N' : 'Y';
      return acc;
    }, '');

    return {
      start: property?.childProperties[0]?.calendar?.start || '',
      end: property?.childProperties[0]?.calendar?.end || '',
      availability: combinedAvailability,
      minStayThresholdAlert:
        property?.childProperties[0]?.calendar?.minStayThresholdAlert,
    };
  };

  return (
    <>
      <Layout
        searchbarLabel={sitePage.title}
        jumpMenuOptions={jumpMenuOptions}
        propertyStickyHeader={true}
        searchbarLabel={'A Home for Every Group'}
        customBottomPosition={true}
      >
        <SEO
          title={sitePage.seoTitle}
          description={sitePage.seoDescription}
          featuredImage={sitePage.featuredImage}
          headTags={sitePage.headTags}
        />
        <div className={`container`}>
          <Query query={COMBO_CALENDAR_DATA}>
            {({ data, loading, error }) => {
              return (
                <>
                  <>
                    <CMSContent blockData={sitePage.cmsContent.blockData}>
                      {sitePage.cmsContent.html}
                    </CMSContent>
                  </>

                  {propertyCombos
                    .filter(property => !property.name.includes('+'))
                    .filter(
                      property => property?.sitePage?.featuredFileCollection
                    )
                    .map((property, index) => {
                      const combos = data?.properties || [];
                      const matchedCombo =
                        combos.find(combo => combo.key === property.key) ||
                        null;
                      const combinedChildrenAvailability = matchedCombo
                        ? getCombinedChildrenAvailability(matchedCombo)
                        : null;

                      const rooms = {
                        ...property,
                        bedrooms: 0,
                        bathrooms: 0,
                        sleeps: 0,
                      };
                      let combinedComboAmenities = [];
                      const propertyComboWithCombinedRooms = property.childProperties.reduce(
                        (acc, child) => {
                          acc['bedrooms'] += child.bedrooms;
                          acc['bathrooms'] += child.bathrooms;
                          acc['sleeps'] += child.sleeps;
                          child.amenities.forEach(amenity => {
                            if (
                              !combinedComboAmenities.find(
                                item => item.key === amenity.key
                              )
                            ) {
                              combinedComboAmenities.push(amenity);
                            }
                          });
                          return { ...acc, amenities: combinedComboAmenities };
                        },
                        rooms
                      );
                      const files =
                        property?.sitePage?.featuredFileCollection.files || [];
                      const featuredVideo = files.find(
                        file => file.mimetype.indexOf('video') !== -1
                      );
                      const filteredFileCollection = files.reduce(
                        (acc, file, index) => {
                          acc.id =
                            property?.sitePage?.featuredFileCollection?.id ||
                            index;
                          if (file.mimetype.indexOf('image') !== -1) {
                            if (acc.files) {
                              acc.files.push(file);
                            } else {
                              acc.files = [file];
                            }
                          }
                          return acc;
                        },
                        {}
                      );
                      const excerpt = property?.sitePage?.excerpt || null;
                      return (
                        <div
                          key={property.key}
                          className={`${styles.propertySectionContainer} pt-3`}
                        >
                          <h2 id={property.key} className="my-0 mr-2 mb-2">
                            {property.name}
                          </h2>
                          <div className="d-block d-lg-flex justify-content-between">
                            <article
                              className={`${styles.leftColumn} flex-grow-1`}
                            >
                              <div className="aspect ratio-16x9 mb-1plus no-gutters-sm-down">
                                <iframe
                                  src={`//www.youtube.com/embed/${getYoutubeVideoId(
                                    featuredVideo?.fullUrl
                                  )}`}
                                  frameBorder="0"
                                  allowFullScreen
                                />
                              </div>

                              <div
                                id="___gatsby"
                                dangerouslySetInnerHTML={{ __html: excerpt }}
                              />
                              <div className="d-block d-lg-none">
                                <div className="d-flex align-items-center mt-3">
                                  <PropertyAvailability
                                    propertyKey={property.key}
                                    multiplePerPage={true}
                                  />
                                  {/* <a
                                  style={{ padding: '0.8rem' }}
                                  className={`w-auto btn btn-secondary rounded text-primary font-weight-bold`}
                                  href={`#availability${property.key}`}
                                >
                                  <span
                                    className={styles.checkAvailablitySubtext}
                                  >
                                    Check
                                  </span>{' '}
                                  Availability
                                </a> */}
                                </div>
                                <PropertyDetails
                                  property={propertyComboWithCombinedRooms}
                                />
                              </div>
                              <div className="mb-2">
                                <div
                                  id={`availability${property.key}`}
                                  className={`${styles.reserveDatesWrapper} d-flex align-items-center mt-3 mb-2`}
                                >
                                  <h2 className="my-0 mr-2">
                                    Availability (Reserve your Dates)
                                  </h2>
                                </div>

                                <Calendar
                                  loading={loading}
                                  propertyId={property.id}
                                  calendar={combinedChildrenAvailability}
                                />

                                <h2 className="mt-3">How to Book</h2>
                                <p>{property.additionalMessaging}</p>

                                <>
                                  <div className="row">
                                    <div className="col col-12">
                                      <p className="mb-1plus">
                                        Need help? Questions?
                                      </p>
                                    </div>

                                    <div className="col col-12 col-lg-6 col-xl-3">
                                      <button
                                        id="request-info-button"
                                        onClick={() => {
                                          window.history.replaceState(
                                            {},
                                            '',
                                            `/${sitePage.slug}/#${property.key}`
                                          );
                                          setSelectedPropertyKey(property.key);
                                          setSelectedPropertyId(property.id);
                                          setRequestInfoModalIsOpen(true);
                                        }}
                                        className={`${styles.requestInfoButton} btn btn-outline-primary mb-1plus font-weight-bold w-100`}
                                      >
                                        Request Info
                                      </button>
                                    </div>
                                  </div>

                                  <p className="">
                                    We'd love to make your vacation exceptional!
                                    Ask us about pricing, discounts, and more.
                                  </p>
                                </>
                                <h2>Highlights</h2>
                                <div id="overview">
                                  <Slider
                                    siteFileCollection={filteredFileCollection}
                                  />
                                </div>
                              </div>
                            </article>
                            <aside className={`${styles.rightColumn} ml-3`}>
                              <>
                                <div className="pb-2 d-none d-lg-flex justify-content-between align-items-center border-bottom">
                                  <PropertyAvailability
                                    propertyKey={property.key}
                                    multiplePerPage={true}
                                  />
                                  {/* <a
                                  className={`${styles.checkAvailabilityButton} btn btn-secondary rounded text-primary font-weight-bold`}
                                  href={`#availability${property.key}`}
                                >
                                  <span
                                    className={styles.checkAvailablitySubtext}
                                  >
                                    Check
                                  </span>{' '}
                                  Availability
                                </a> */}
                                </div>
                                <div className="d-none d-lg-block">
                                  <PropertyDetails
                                    hasBorders={true}
                                    property={propertyComboWithCombinedRooms}
                                  />
                                </div>
                              </>
                            </aside>
                          </div>
                        </div>
                      );
                    })}
                  <>
                    <h2 id="custom-combinations" className="mt-3 mr-2">
                      Custom Combinations
                    </h2>
                    <p className="mb-2">
                      Choose the perfect home combination for your large group
                      or family reunion. These homes are all in the same area,
                      with most directly next-door to each other.
                    </p>
                    <div className="mb-3 container px-0">
                      <div className="row">
                        <div
                          className={`${styles.combinationListColumn} single-column-property-list col col-12 col-lg-4 mb-3 mb-lg-auto`}
                        >
                          <DynamicPropertyList
                            id={'single-column'}
                            singleColumn={true}
                            properties={properties.filter(
                              property =>
                                property.type !== 'COMBO' &&
                                property.sitePage !== null &&
                                property.group !== null
                            )}
                          />
                        </div>
                        <div className="image-with-margin col col-12 col-lg-8">
                          <Media siteFileInstance={sitePage.featuredImage} />
                        </div>
                      </div>
                    </div>

                    <h2>Map View</h2>
                    <p className="mb-4">
                      Check out our{' '}
                      <Link to="/rentals?mode=map">interactive map</Link> and
                      street view to see all our Florida beach house rentals and
                      available amenities.
                    </p>
                    <h2>Contact Us</h2>
                    <p className="mb-1plus">Need help? Questions?</p>
                    <div className="col col-12 col-lg-6 col-xl-2 px-0">
                      <button
                        onClick={() => {
                          setRequestInfoModalIsOpen(true);
                          window.history.replaceState(
                            {},
                            '',
                            `/${sitePage.slug}/#custom-combinations`
                          );
                        }}
                        className={`${styles.requestInfoButton} btn btn-outline-primary mb-1plus font-weight-bold w-100`}
                      >
                        Request Info
                      </button>
                    </div>
                    <p>
                      We'd love to make your vacation exceptional! Ask us about
                      pricing, discounts, and more.
                    </p>
                  </>
                  <Modal
                    id="request-info"
                    isOpen={requestInfoModalIsOpen}
                    disableFocusTrap={true}
                    title={'Request Info'}
                    onClose={() => setRequestInfoModalIsOpen(false)}
                  >
                    <p>
                      Ask us about pricing, availability, and discounts for your
                      next vacation.
                    </p>
                    <p className="mb-2">
                      Fields marked with an{' '}
                      <span className="text-danger">*</span> are required
                    </p>
                    <RequestInfoForm targetPropertyId={selectedPropertyId} />
                  </Modal>
                </>
              );
            }}
          </Query>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query AllProperties($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      properties {
        id
        additionalMessaging
        priceOffSeason
        label
        key
        name
        description
        sleeps
        bedrooms
        bathrooms
        type
        sitePage {
          excerpt
          status
          featuredFileCollection {
            id
            files {
              mimetype
              fullUrl
              width
              height
              versions {
                fullUrl
                key
                width
                height
                mimetype
              }
            }
          }
          title
          slug
          featuredImageSecondary {
            file {
              versions {
                key
                width
                height
                fullUrl
              }
            }
          }
        }
        ... on BHIP_PropertyCombo {
          childProperties {
            # calendar {
            #   start
            #   end
            #   availability
            # }
            name
            key
            bedrooms
            bathrooms
            sleeps
            amenities {
              key
              name
            }
          }
        }
        latitude
        longitude
        group
        streetAddress1
        propertyListings {
          propertyUrl
          platform {
            name
            key
          }
        }
        amenities {
          name
          key
        }
      }
    }
  }
`;

export default PropertyCombo;
