import React from 'react';
import { graphql, StaticQuery, useStaticQuery } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage, BgImage } from 'gbimage-bridge';
import './styles.scss';

const BackgroundSection = ({ className, children }) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "hero-1-min.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
            # fluid(quality: 70, maxWidth: 2000) {
            #   ...GatsbyImageSharpFluid_withWebp
            # }
          }
        }
      }
    `
  );

  const image = getImage(desktop);

  // Use like this:
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      Tag="section"
      className={className ? className : `hero-header`}
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      // preserveStackingContext
    >
      <>{children}</>
    </BackgroundImage>
  );
};

export default BackgroundSection;
