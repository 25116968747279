import React, { useState, useEffect } from 'react';
import { Link } from "gatsby"
import styles from './styles.module.scss';
import ThemedSelectList from '../ThemedSelectList';
import PropertySearchCalendar from '../PropertySearchCalendar';
import Modal from '../Modal';
import { navigate } from '@reach/router';
import { updateUrl } from '../../util/urlHelper';
import { formDataToObject } from '../../util/formDataTransformer';

const handleSubmit = (e, onClose) => {
    const urlParams = new URLSearchParams(location.search);
    e.preventDefault();
    updateUrl('rentals', formDataToObject(e.target));
    onClose();
};

const SearchByDateForm = ({
    buttonIcon,
    modalIsOpen,
    id,
    includeOtherSearchParams,
    onClose,
}) => {
    const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false);
    return (
        <div>


            <button
                key="rental-search-button"
                id="rental-search-button"
                onClick={() => {
                    setBookingModalIsOpen(true);
                }}
                className={`
          ${styles.searchButton}
          
          btn btn-secondary text-primary font-weight-bold rounded`}
            >
                <img
                    className="mr-1plus"
                    style={{
                        verticalAlign: 'sub'
                    }}
                    width="20"
                    height="20"
                    src="/icon-calendar.svg"
                />Search Dates
            </button>
            <div className="mt-1plus">
                <Link className="text-white " href="/rentals"><u>Advanced Search</u></Link>
            </div>

            <Modal
                // expanded={true}
                className="modalSmallFixed"
                contentStyles={{ minHeight: '480px' }}
                isOpen={bookingModalIsOpen}
                title={'Search Dates'}
                onClose={() => {
                    setBookingModalIsOpen(false);
                }}
                disableFocusTrap={true}
            >
                <PropertySearchCalendar

                    onClose={() => {
                        setBookingModalIsOpen(false);
                    }}
                />
            </Modal>
        </div>
    );
};

SearchByDateForm.defaultProps = {
    onClose: () => { },
};

export default SearchByDateForm;
