import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import ThemedSelectList from '../ThemedSelectList';
import { navigate } from '@reach/router';
import { updateUrl } from '../../util/urlHelper';
import { formDataToObject } from '../../util/formDataTransformer';

const handleSubmit = (e, onClose) => {
  const urlParams = new URLSearchParams(location.search);
  e.preventDefault();
  updateUrl('rentals', formDataToObject(e.target));
  onClose();
};

const PropertySearchForm = ({
  buttonIcon,
  modalIsOpen,
  id,
  includeOtherSearchParams,
  onClose,
}) => {
  return (
    <>
      <form
        onSubmit={e => handleSubmit(e, onClose)}
        className={`${styles.searchForm} justify-content-center ${
          modalIsOpen ? 'd-lg-flex' : 'd-none'
        } d-lg-flex`}
      >
        <ThemedSelectList
          name="bedrooms"
          id="bedrooms-select"
          options={[
            { label: 'Any', value: '' },
            { label: '4+', value: '4' },
            { label: '5+', value: '5' },
            { label: '6+', value: '6' },
          ]}
          classNames={`${modalIsOpen ? 'w-100 mb-2' : ''} mr-1plus rounded`}
          hiddenValue="Bedrooms"
        />
        <ThemedSelectList
          name="baths"
          id="bathrooms-select"
          options={[
            { label: 'Any', value: '' },
            { label: '3+', value: '3' },
            { label: '4+', value: '4' },
            { label: '5+', value: '5' },
          ]}
          classNames={`${modalIsOpen ? 'w-100 mb-2' : ''} mr-1plus rounded`}
          hiddenValue="Baths"
        />
        <ThemedSelectList
          name="sleeps"
          id="sleeps-select"
          options={[
            { label: 'Any', value: '' },
            { label: '16+', value: '16' },
            { label: '18+', value: '18' },
            { label: '20+', value: '20' },
          ]}
          classNames={`${modalIsOpen ? 'w-100 mb-2' : ''} mr-1plus rounded`}
          hiddenValue="Sleeps"
        />
        <button
          id="rental-search-button"
          className={`
          ${styles.searchButton}
          ${modalIsOpen ? 'mb-2 w-100' : ''}
          btn btn-secondary text-primary font-weight-bold rounded`}
        >
          {modalIsOpen ? 'Search Rentals' : 'Search'}
        </button>
      </form>
    </>
  );
};

PropertySearchForm.defaultProps = {
  onClose: () => {},
};

export default PropertySearchForm;
