import React, { useEffect, useState } from 'react';

import CalendarPrevious from './components/CalendarPrevious';
import CalendarNext from './components/CalendarNext';
import MonthSelectlist from './components/MonthSelectList';
import gql from 'graphql-tag';
import datepicker from '../../util/jquery-ui-datepicker';
// import styles from '../../templates/single-property/styles.module.scss';
import WithLocation from '../WithLocation';
import Loading from '../Loading';
import styles from './styles.module.scss';
import { addDays } from '../../util/dateHelper';
import ReactDOM from 'react-dom';

const SinglePropertyDatePicker = ({
  propertyId,
  client,
  calendar,
  loading,
  ...props
}) => {
  const searchParams = props?.location?.location?.search;
  const params = new URLSearchParams(searchParams);
  const [calendarPropertyData, setCalendarPropertyData] = useState(null);
  const today = new Date();

  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const currentDay = today.getDate();

  // const getStartDate = () => {
  //   if (params.get('checkIn')) {
  //     const checkIn = new Date(params.get('checkIn'));
  //     if (checkIn < today) {
  //       return today;
  //     }
  //     const maxDate = new Date(currentYear + 2, currentMonth, currentDay);
  //     if (checkIn > maxDate) {
  //       return today;
  //     }
  //     return new Date(params.get('checkIn'));
  //   }
  //   return today;
  // };

  // const startDate = getStartDate();

  const startDate = calendarPropertyData?.start
    ? new Date(calendarPropertyData?.start)
    : new Date();

  const startMonth = startDate.getMonth() + 1;

  const startYear = startDate.getFullYear();
  // const startMonth =
  //   (params.get('checkIn') ? startDate.getUTCMonth() : startDate.getMonth()) +
  //   1;

  // const startYear = params.get('checkIn')
  //   ? startDate.getUTCFullYear()
  //   : startDate.getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(startMonth);
  const [selectedYear, setSelectedYear] = useState(startYear);
  // console.log('selectedMonth!', selectedMonth);

  const configureDatePicker = (
    setSelectedMonth,
    setSelectedYear,
    calendarPropertyData
  ) => {
    if (calendarPropertyData) {
      datepicker();
      const maxDate = calendarPropertyData.end
        ? new Date(calendarPropertyData.end)
        : '+2Y';
      // console.log('calendarPropertyData', calendarPropertyData);
      const availableDays = (calendarPropertyData.availability || '').split('');
      const minStayDays = (calendarPropertyData.minStay || '').split(',');
      const minStayAlertDays = (
        calendarPropertyData.minStayThresholdAlert || ''
      ).split('');

      const getMeetsMinStayRequirement = diffDays => {
        const dateRange = availableDays.slice(
          diffDays,
          diffDays + Number(minStayDays[diffDays])
        );

        if (dateRange.length === 0) return false;
        const foundUnavailableDayWithinDateRange = dateRange.find(
          date => date === 'N'
        );
        if (foundUnavailableDayWithinDateRange) {
          return false;
        }
        return true;
      };

      $(`#single-datepicker${propertyId}`).datepicker({
        numberOfMonths: 2,
        minDate: new Date(),
        maxDate,
        onChangeMonthYear: (year, month, element) => {
          setSelectedMonth(month);
          setSelectedYear(year);
        },
        defaultDate: startDate,
        // beforeShow: data => {
        //   // $('#single-datepicker').datepicker('setDate', startDate);
        // },
        beforeShowDay: function(date, props) {
          // I need to call this start date each time in this method because it's scope is disconnected from the rendering of the state of the component
          const calendarStartDate = new Date(
            calendarPropertyData?.start || null
          );
          // alternative: https://jsfiddle.net/weu5v432/

          const dateString = date.toString();

          const diffTime = Math.abs(date.getTime() - startDate.getTime());
          const diffDays = Math.floor(
            (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
              Date.UTC(
                calendarStartDate.getFullYear(),
                calendarStartDate.getMonth(),
                calendarStartDate.getDate()
              )) /
              (1000 * 60 * 60 * 24)
          );

          if (minStayAlertDays[diffDays] === 'Y') {
            return [false, `min-stay-dots reserved-full-day`];
          }

          if (
            availableDays[diffDays] === 'N' &&
            availableDays[diffDays - 1] === 'Y' &&
            minStayAlertDays[diffDays - 1] !== 'Y'
          ) {
            return [false, 'reserved-check-in'];
          } else if (
            availableDays[diffDays] === 'Y' &&
            availableDays[diffDays - 1] === 'N'
          ) {
            return [false, 'reserved-check-out'];
          }
          return [
            availableDays[diffDays] === 'Y' ? true : false,
            `${availableDays[diffDays] === 'Y' ? '' : 'reserved-full-day'}`,
          ];
        },
      });

      setCalendarPropertyData(calendarPropertyData);
    }
  };
  useEffect(() => {
    const getCalendarData = async () => {
      configureDatePicker(setSelectedMonth, setSelectedYear, calendar);
    };
    getCalendarData();
    const minStayCalendarDayCells = document.querySelectorAll(
      '.min-stay-dots .td-wrapper'
    );
    const customBoundary = document.querySelector('#boundary');
    minStayCalendarDayCells.forEach(cell => {
      minStayCalendarDayCells.forEach((element, index) => {
        $(element).tooltip({
          template: `<div class="tooltip single-property calendar-tooltip text-left" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>`,
          name: 'flip',
          html: true,
          title:
            '<span>This property is subject to minimum-stay requirements, depending on season and availability.<span> <br> <br> <span>When this home is vacant for shorter periods, the owner takes advantage of these brief vacancies to schedule maintenance and improvements.</span>',
          placement: 'bottom',
          modifiers: {
            name: 'preventOverflow',
            options: {
              placement: 'bottom-start',
            },
          },
        });
      });
    });

    /*

      $('[data-toggle="popover"]').popover({
      container: 'body',
    });
      style={{ cursor: 'pointer' }}
          tabIndex="0"
          id="more-info"
          className="ml-1plus popover-dismiss"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
          data-content="Rates vary by night, depending on season, select holidays, and other considerations. Please visit your favorite booking site for more info."

    */
    return () => {
      minStayCalendarDayCells.forEach(element => {
        if (element) {
          $(element).tooltip('dispose');
        }
      });
    };
  }, [loading || selectedMonth || selectedYear]);

  const yearRange = Array.from({
    length:
      calendarPropertyData && calendarPropertyData.length > 0
        ? new Date(calendarPropertyData.end).getFullYear() -
          new Date(calendarPropertyData.start).getFullYear()
        : 2,
  }).length;

  const dataAttribute = {};
  dataAttribute[`data-year-dropdown${propertyId}`] = 'true';

  return (
    <>
      <div
        className={`mx-auto mb-2  pt-1 pt-md-0 pb-1 d-flex justify-content-between text-center date-navigation`}
      >
        <span
          id="prev-month"
          onClick={() => {
            const date = $(`#single-datepicker${propertyId}`).datepicker(
              'getDate'
            );
            const prevMonth = new Date(date.setMonth(date.getMonth() - 1));
            $(`#single-datepicker${propertyId}`).datepicker(
              'setDate',
              new Date(prevMonth)
            );
          }}
        >
          <CalendarPrevious
            id={propertyId}
            selectedMonth={selectedMonth}
            currentMonth={currentMonth}
            selectedYear={selectedYear}
            currentYear={currentYear}
          />
        </span>

        <MonthSelectlist
          id={propertyId}
          styles={styles}
          currentYear={currentYear}
          yearRange={yearRange}
          selectedYear={selectedYear}
          currentMonth={currentMonth}
          selectedMonth={selectedMonth}
        />
        <label htmlFor={`${styles.yearSelect}`} className="sr-only">
          Year
        </label>
        <select
          {...dataAttribute}
          id={`${styles.yearSelect}`}
          className="custom-select-list calendar border-0 font-weight-bold text-primary px-2"
          // defaultValue={date.getFullYear()}
          value={selectedYear}
          onChange={e => {
            Number(e.target.value);
            const selectedDate = new Date(
              Number(e.target.value),
              Number($(`[data-month-dropdown${propertyId}]`).val()) - 1,
              1
            );

            $(`#single-datepicker${propertyId}`).datepicker(
              'setDate',
              new Date(selectedDate)
            );
          }}
        >
          <option value={currentYear}>{currentYear}</option>
          {Array.from({ length: yearRange }).map((_, index) => {
            return (
              <option key={index} value={currentYear + index + 1}>
                {currentYear + index + 1}
              </option>
            );
          })}
        </select>
        <span
          id="next-month"
          onClick={() => {
            const date = $(`#single-datepicker${propertyId}`).datepicker(
              'getDate'
            );
            const nextMonth = new Date(date.setMonth(date.getMonth() + 1));
            $(`#single-datepicker${propertyId}`).datepicker(
              'setDate',
              new Date(nextMonth)
            );
          }}
        >
          <CalendarNext
            id={propertyId}
            calendarPropertyData={calendarPropertyData}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            width={30}
          />
        </span>
      </div>
      <div className={`${styles.calendarPlaceholder} position-relative mb-1`}>
        {loading && (
          <div
            className={`position-absolute ${styles.loadingIndicatorWrapper}`}
          >
            <Loading />
          </div>
        )}
        <div
          className="pb-lg-3 pb-0"
          data-single-datepicker
          id={`single-datepicker${propertyId}`}
        />
      </div>
    </>
  );
};

export default WithLocation(SinglePropertyDatePicker);
