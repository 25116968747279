import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import { Portal, PortalWithState } from 'react-portal';
// import Modal from './Modal';
// import BodyEnd from '../BodyEnd';
// import photoswipe from '../../util/photoswipe';
// import LightBox from '../LightBox';
import photoswipe from '../../util/photoswipe';
import { getThumbnailUrl } from '../../util/helpers';

import { renderToString } from 'react-dom/server';
import { getVersionMap } from '../../util/helpers';
import PlayButton from '../../images/play-button.png';
// import slick from 'slick-carousel';
import './styles.scss';

import LightBox from '../LightBox';

function getYoutubVideId(url) {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return 'error';
  }
}

const NextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <button
      className={'slick-next'}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img width="23" height="40" src="/lightbox-next.svg" alt="Next" />
    </button>
  );
};

const PrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <button
      className={'slick-prev'}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src="/lightbox-previous.svg" alt="Previous" width="23" height="40" />
    </button>
  );
};

const Slider = ({ siteFileCollection, options, ...props }) => {
  //let sliderRef, sliderSyncRef;
  //console.log('siteFileCollection', siteFileCollection);
  const sliderRef = useRef(null);
  const sliderSyncRef = useRef(null);

  useEffect(() => {
    import('slick-carousel').then(slick => {
      if (sliderRef && sliderRef.current) {
        $(sliderRef.current).slick({
          dots: false,
          fade: true,
          // arrows: true,
          // nextArrow: renderToString(<NextArrow />),
          // prevArrow: renderToString(<PrevArrow />),
          useCSS: true,
          asNavFor: $(sliderRef.current).data('asNavFor'),
          adaptiveHeight: true,
          useTransitions: true,
        });
        photoswipe(sliderRef.current);
      }
      if (sliderSyncRef && sliderSyncRef.current) {
        $(sliderSyncRef.current).slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          arrows: true,
          nextArrow: renderToString(<NextArrow />),
          prevArrow: renderToString(<PrevArrow />),
          asNavFor: $(sliderSyncRef.current).data('asNavFor'),
          dots: false,
          centerMode: false,
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
          ],
        });
      }
    });
    return () => {
      if (sliderRef && sliderRef.current) {
        try {
          $(sliderRef.current).slick('unslick');
        } catch (err) { }
      }
      if (sliderSyncRef && sliderSyncRef.current) {
        try {
          $(sliderSyncRef.current).slick('unslick');
        } catch (err) { }
      }
    };
  }, [sliderRef, sliderSyncRef]);

  return (
    <>
      <div className="bhip-slider-parent">
        <div
          itemScope
          ref={sliderRef}
          data-slider-parent
          data-lightbox
          className="bhip-slider slick-fade-in "
          id={`bhip-slider-${siteFileCollection?.id}`}
          data-as-nav-for={`#bhip-slider-sync-${siteFileCollection?.id}`}
          itemType="http://schema.org/ImageGallery"
        >
          {siteFileCollection?.files &&
            siteFileCollection.files.map((file, index) => {
              if (file.mimetype.match('video/youtube')) {
                return (
                  <div key={index} className="aspect ratio-16x9">
                    <iframe
                      src={`//www.youtube.com/embed/${getYoutubVideId(
                        file.fullUrl
                      )}${file.fullUrl.includes('&') ? '&rel=0' : '?rel=0'}`}
                      frameBorder="0"
                      allowFullScreen
                    // title="Beach House Featured Video"
                    />
                  </div>
                );
              }
              const versionMap = getVersionMap(file);
              return (
                <figure
                  key={index}
                  className="bg-black"
                  itemProp="associatedMedia"
                  itemScope
                  itemType="http://schema.org/ImageObject"
                >
                  <a
                    href={file?.fullUrl}
                    className="lightbox-image"
                    itemProp="contentUrl"
                    data-size={`${file.width}x${file.height}`}
                    data-size-mobile={`${versionMap &&
                      versionMap.xlarge.width}x${versionMap &&
                      versionMap.xlarge.height}`}
                    data-href-mobile={versionMap && versionMap?.xlarge?.fullUrl}
                  >
                    {versionMap && (
                      <img
                        data-file-collection-slider
                        data-slider
                        src={versionMap?.large?.fullUrl}
                        alt={file.alt}
                        data-id={siteFileCollection?.id}
                        className={`mx-auto bhip-image-${siteFileCollection?.id}`}
                        itemProp="thumbnail"
                        onLoad={() => {
                          // if ($('[data-slider]').slick) {
                          //   $('[data-slider]').slick('setPosition'); // Fixes 1px height bug.
                          // }
                          try {
                            $('[data-slider-parent]').each(function () {
                              $(this).slick('setPosition');
                            });
                            //  $('[data-slider-parent]').slick('setPosition'); // Fixes 1px height bug.

                            console.log('slick:fix:success'); //.get(0).slick.setPosition()
                          } catch (err) {
                            console.log('slick:fix:fail');
                            setTimeout(() => {
                              try {
                                $('[data-slider-parent]').each(function () {
                                  $(this).slick('setPosition');
                                });
                              } catch (err) {
                                console.log('slick:fix:fail2');
                              }
                              // $('[data-slider]').slick('setPosition'); // Fixes 1px height bug.
                            }, '500');
                          }
                        }}
                      />
                    )}
                  </a>
                </figure>
              );
            })}
        </div>
        <div
          itemScope
          ref={sliderSyncRef}
          id={`bhip-slider-sync-${siteFileCollection?.id}`}
          data-as-nav-for={`#bhip-slider-${siteFileCollection?.id}`}
          className="bhip-slider-sync slick-fade-in "
          itemType="http://schema.org/ImageGallery"
        >
          {siteFileCollection?.files &&
            siteFileCollection.files.map((file, index) => {
              // if (file.mimetype.match('video/youtube')) {
              //   return (
              //     <div key={index} className="aspect ratio-16x9">
              //       <iframe
              //         src={`//www.youtube.com/embed/${getYoutubVideId(
              //           file.fullUrl
              //         )}${file.fullUrl.includes('&') ? '&rel=0' : '?rel=0'}`}
              //         frameBorder="0"
              //         allowFullScreen
              //         // title="Beach House Featured Video"
              //       />
              //     </div>
              //   );
              // }
              // getThumbnailUrl;

              const versionMap = getVersionMap(file);
              return (
                <figure
                  key={index}
                  itemProp="associatedMedia"
                  itemScope
                  itemType="http://schema.org/ImageObject"
                >
                  {file.mimetype.match('video/youtube') ? (
                    <div className="aspect ratio-4x3">
                      <img
                        src={PlayButton}
                        className="no-resize"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',

                          width: '47px',
                          height: '30px',
                          zIndex: 1,
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                      <img
                        src={getThumbnailUrl(file, file.versions, 'medium')}
                        style={{ maxHeight: '80px' }}
                        alt={file.alt}
                        itemProp="thumbnail"
                        data-id={siteFileCollection.id}
                      />
                    </div>
                  ) : (
                    <div className="aspect ratio-4x3">
                      <img
                        src={versionMap?.medium?.fullUrl}
                        alt={file.alt}
                        itemProp="thumbnail"
                        data-id={siteFileCollection.id}
                      />
                    </div>
                  )}
                </figure>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default Slider;
{
  /* <div className="bhip-slider-parent">
<div itemScope data-slider data-lightbox className="bhip-slider" id="bhip-slider-{{siteFileCollectionId}}" itemType="http://schema.org/ImageGallery">
  {{#siteFiles}}
      <figure itemProp="associatedMedia" itemScope itemType="http://schema.org/ImageObject">
        <a href="{{{file.url}}}" className="lightbox-image" itemProp="contentUrl" data-size="{{file.width}}x{{file.height}}">
          <img
          src="{{{file.resizedUrlLarge}}}"
          {{#if alt}}
          alt="{{alt}}"
          {{/if}}
          data-id="{{id}}" className="bhip-image-{{id}}" itemProp="thumbnail"/>
        </a>
      </figure>
  {{/siteFiles}}
</div>
<div itemScope data-slider-sync="#bhip-slider-{{siteFileCollectionId}}" className="bhip-slider-sync" itemType="http://schema.org/ImageGallery">
  {{#siteFiles}}
  <figure itemProp="associatedMedia" itemScope itemType="http://schema.org/ImageObject">
    <img
      src="{{{file.resizedUrlLarge}}}"
      itemProp="thumbnail"
    />
  </figure>
  {{/siteFiles}}
</div>
</div> */
}
