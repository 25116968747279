import React, { Component, useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const PropertyQuoteContext = React.createContext();

const PropertyQuoteProvider = ({ children }) => {
  const [propertyQuote, setPropertyQuote] = useState(null);
  const [prepaidDiscount, setPrepaidDiscount] = useState('YES');
  const [errors, setErrors] = useState(null);
  // const [bathsState, setBaths] = useState(null);
  // const [sleepsState, setSleeps] = useState(null);
  // const setPropertyTypeValue = (name, value) => {
  //   switch (name) {
  //     case 'bedrooms':
  //       setBedrooms(value);
  //       break;
  //     case 'baths':
  //       setBaths(value);
  //       break;
  //     case 'sleeps':
  //       setSleeps(value);
  //       break;
  //   }
  // };

  return (
    <PropertyQuoteContext.Provider
      value={{
        state: {
          propertyQuote,
          prepaidDiscount,
          errors,
        },
        setPrepaidDiscount: prepaidDiscount => {
          setErrors(null);
          setPrepaidDiscount(prepaidDiscount);
        },
        setPropertyQuote: propertyQuote => {
          setErrors(null);
          setPropertyQuote(propertyQuote);
        },
        setErrors: errors => setErrors(errors),
        // setPropertyTypeValue: (name, value) =>
        //   setPropertyTypeValue(name, value),
      }}
    >
      <>{children}</>
    </PropertyQuoteContext.Provider>
  );
};

const PropertyQuoteConsumer = PropertyQuoteContext.Consumer;

export default PropertyQuoteProvider;
export { PropertyQuoteConsumer, PropertyQuoteContext };
