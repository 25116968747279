import React, { useState } from 'react';

const getGuestCheckListLabel = (guestGroupTypes, groupIds) => {
  const label = guestGroupTypes.reduce((acc, item, index) => {
    const found = groupIds.find(id => id === item.id);
    if (found) {
      acc += `${item.label}, `;
    }
    return acc;
  }, '');
  // label.reverse()
  return label.length > 1
    ? label.slice(0, label.length - 2)
    : 'Check all that apply';
};

const GroupCheckListForm = ({ formikProps, guestGroupTypes, onSubmit }) => {
  const { values, setFieldValue } = formikProps;
  const [groupIds, setGroupIds] = useState([]);

  return (
    <div className="p-2">
      {guestGroupTypes.map(groupType => {
        return (
          <div
            className="form-check custom-control custom-checkbox"
            key={groupType.id}
          >
            <input
              // defaultChecked={true}
              className="form-check-input custom-control-input"
              type="checkbox"
              value={groupType.id}
              id={`groupType_${groupType.id}`}
              name={`groupType_${groupType.id}`}
              onClick={e => {
                if (e.target.checked) {
                  setGroupIds(prevState => [...prevState, groupType.id]);
                } else {
                  const updatedGroupIds = [
                    ...groupIds.slice(0, groupIds.indexOf(groupType.id)),
                    ...groupIds.slice(groupIds.indexOf(groupType.id) + 1),
                  ];
                  setGroupIds(updatedGroupIds);
                }
              }}
            />
            <label
              className={`form-check-label custom-control-label`}
              htmlFor={`groupType_${groupType.id}`}
            >
              {groupType.label}
            </label>
          </div>
        );
      })}

      <button
        id="groups-apply-button"
        className="btn btn-outline-primary mt-2"
        onClick={() => {
          setFieldValue('guestGroupIds', groupIds);
          onSubmit(getGuestCheckListLabel(guestGroupTypes, groupIds));
        }}
        type="button"
        data-close
      >
        Apply
      </button>
    </div>
  );
};

export default GroupCheckListForm;
