import React, { useEffect, useState } from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import GQLClient from './util/GQLClient';
import gql from 'graphql-tag';

import { blocks as cmsBlocks } from '../../src/util/cms/blocks'; // TODO: Ideally, this would be a Dynamic Import. How can we get value from gatsby-config of parent application? OR, we can standardize the plugin definition file (e.g. bhip-cms-config.js in project root)
// import usePage from "./Components/usePage"
const BLOCK_REGEX = `<!-- bhip:(.*?) (.*?)-->(.*?)<!-- \/bhip:(.*?) -->`;

const getBlockMetaData = block => {
  const blockMatch = block.match(new RegExp(BLOCK_REGEX, 's'));
  const blockName = blockMatch[1];
  const blockOptions = blockMatch[2];
  const blockDefinition = blocks.find(block => block.name === blockName);
  const blockDataKey = getBlockDataKey(blockDefinition, blockOptions);
  return {
    name: blockName,
    options: JSON.parse(blockOptions),
    definition: blockDefinition,
    dataKey: blockDataKey,
  };
};

const BLOCK_DATA_CACHE = {};
export const transformBlock = async (block, disableCache) => {
  const gqlClient = GQLClient({
    uri: `${process.env.BHIP_API_URL}/graphql`, // ${process.env.BHIP_API_URL}
  });
  const blockMeta = getBlockMetaData(block);
  const { name, options, definition, dataKey } = blockMeta;

  if ((dataKey && !BLOCK_DATA_CACHE[dataKey]) || disableCache) {
    const blockData = await gqlClient.query({
      query: definition.graphql,
      variables: options,
    });

    BLOCK_DATA_CACHE[dataKey] = blockData;
  }
  //const BlockComponent = definition.component();
  const componentData = dataKey ? BLOCK_DATA_CACHE[dataKey].data : {};
  const component = () => {
    const props = {
      ...componentData,
      ...options,
    };
    const BlockComponent = definition.component(props);
    return typeof BlockComponent === 'object' ? (
      BlockComponent
    ) : (
      <BlockComponent {...props} />
    );
  };

  return {
    ...blockMeta,
    originalBlock: block,
    data: componentData,
    component,
  };
};

const parseJSON = jsonString => {
  try {
    return JSON.parse(jsonString);
  } catch (err) {
    return {};
  }
};
const findBlock = node => {
  const foundBlock = cmsBlocks.find(block => {
    const regEx = new RegExp(`\\sbhip:${block.name}`);
    if (node.data.match(regEx)) {
      return true;
    }
  });
  if (foundBlock) {
    const optionsRegEx = new RegExp(`\\sbhip:${foundBlock.name} (.*)`);
    const optionsMatch = node.data.match(optionsRegEx);
    const options = optionsMatch ? parseJSON(optionsMatch[1]) : {};
    return {
      ...foundBlock,
      options,
    };
  }

  return null;
};

const getChildren = node => {
  // console.log('node.type', node.type);
  if (node.type === 'tag') {
    //  console.log('RETURNING', node);
    return node;
  }
  return getChildren(node.next);
};

export const getBlockDataKey = (block, blockOptions) => {
  if (!block.graphql) return null;
  const gqlData = gql`
    ${block.graphql}
  `;
  const variableDefinitions = gqlData.definitions.find(
    definition => definition.kind === 'OperationDefinition'
  ).variableDefinitions;

  const blockOptionsObj =
    typeof blockOptions === 'string' ? parseJSON(blockOptions) : blockOptions;

  const blockDataKey = variableDefinitions
    .reduce(
      (acc, variableDefinition) => {
        acc.push(
          `${variableDefinition.variable.name.value}-${
            blockOptionsObj[variableDefinition.variable.name.value]
          }`
        );

        return acc;
      },
      [block.name]
    )
    .join('-');
  return blockDataKey;
};

const BlockComponentWrapper = ({ foundBlock, blockData }) => {
  const blockDataKey = getBlockDataKey(foundBlock, foundBlock.options);
  const foundBlockData = blockDataKey
    ? blockData.find(item => item.key === blockDataKey)
    : {};

  const [blockDataState, setBlockDataState] = useState(foundBlockData);

  useEffect(() => {
    let gqlClient = null;
    if (window) {
      const urlParams = new URLSearchParams(location.search);
      // const cmspreview = urlParams.get('cmspreview');
      var searchParams = new URLSearchParams(location.search);
      const splitURLPath = (location?.pathname || '').split('/');
      const isPreviewPage =
        urlParams.get('cmspreview') ||
        splitURLPath.indexOf('cmspreview') !== -1;

      if (
        isPreviewPage &&
        blockDataKey &&
        blockDataKey !== blockDataState?.key
        // Object.keys(blockDataState || {}).length === 0
      ) {
        gqlClient = GQLClient({
          uri: `${process.env.BHIP_API_URL}/graphql`,
        }); // TODO: Obtain from plugin options
        //console.log('foundBlock.graphql', foundBlock.graphql);
        // console.log('foundBlock.options', foundBlock.options);
        gqlClient
          .query({
            query: `${foundBlock.graphql}`,
            variables: foundBlock.options,
          })
          .then(result => {
            setBlockDataState({
              data: JSON.stringify(result.data),
              key: blockDataKey,
            });
          });
        // console.warn(`Block data not found for ${blockDataKey}`);
      }
    }
    return () => {
      gqlClient = null;
    };
  });
  if (blockDataKey && Object.keys(blockDataState || {}).length === 0)
    return null;
  const props = {
    ...parseJSON(blockDataState.data),
    ...foundBlock.options,
  };
  const BlockComponent = foundBlock.component(props);

  return typeof BlockComponent === 'object' ? (
    BlockComponent
  ) : (
    <BlockComponent {...props} />
  );
};

export const CMSContent = ({ children, blockData, ...props }) => {
  // const foundBlocks = []
  //const page = usePage()

  function transform(node, index) {
    if (node.type === 'comment') {
      const foundBlock = findBlock(node);

      if (foundBlock) {
        // let buff = new Buffer.alloc(
        //   `${foundBlock.name}${JSON.stringify(foundBlock.options)}${index}`
        // );
        // let uniqueKey = buff.toString('base64');

        // start added code addressing 'Buffer' is not defined in the browser:
        let uniqueKey;
        if (typeof window !== 'undefined') {
          uniqueKey = btoa(
            `${foundBlock.name}${JSON.stringify(foundBlock.options)}${index}`
          );
        } else {
          uniqueKey = Buffer.from(
            `${foundBlock.name}${JSON.stringify(foundBlock.options)}${index}`
          ).toString('base64');
        }

        // end added code

        return (
          <React.Fragment key={`${uniqueKey}`}>
            <BlockComponentWrapper
              foundBlock={foundBlock}
              blockData={blockData}
            />
          </React.Fragment>
        );
      }

      return null;
    } else {
      // console.log('node', node);
    }
  }
  const transformedContent = ReactHtmlParser(children, {
    transform,
  });

  return transformedContent;
};

export const blocks = cmsBlocks;

export { default as PagePropsContext } from './Components/PagePropsContext';
