import React, { Component } from 'react';
import { graphql, navigate } from 'gatsby';
import { Link } from 'gatsby';

import SEO from '../../components/seo';
import Layout from '../../layouts/DefaultLayout';

import WithLocation from '../../components/WithLocation';
import './styles.scss';

class PostsPage extends Component {
  render() {
    const { pageContext, data } = this.props;
    const getPagingRoute = currentPage => {
      if (currentPage === 2) {
        return '';
      } else if (currentPage > 1) {
        return `/${currentPage - 1}`;
      }
      return '';
    };
    {
      /* <link rel="prev" href="http://www.example.com/topic/page/2/" />
<link rel="next" href="http://www.example.com/topic/page/4/" />     */
    }
    const edges = data.bhip.sitePagesConnection.edges;

    return (
      <Layout searchbarLabel="News">
        <SEO
          title={pageContext.seoTitle ? pageContext.seoTitle : 'News'}
          description={pageContext.seoDescription}
          headTags={pageContext.headTags}
        />
        <div className="container pt-3">
          {/* <div dangerouslySetInnerHTML={{ __html: pageContext.content }} /> */}
          <div className="row">
            {edges.map((edge, index) => {
              const post = edge.node;
              const publishDate = new Date(post.publishDate);
              const dateFormated = `${new Date(post.publishDate).toLocaleString(
                'en-us',
                {
                  month: 'long',
                }
              )} ${publishDate.getDate()}, ${publishDate.getFullYear()}`;
              const { versions = [] } = (post.featuredImage || {}).file || {};
              var thumbnailVersions = versions.reduce((acc, version) => {
                acc[version.key] = { ...version };
                return acc;
              }, {});

              return (
                <div key={index} className="col-sm-12 col-md-6 col-lg-4 pb-2 ">
                  <div className="card shadow-sm rounded h-100">
                    <div className="aspect ratio-16x9">
                      <Link to={`/${post.slug}`}>
                        <img
                          className="card-img-top"
                          src={
                            thumbnailVersions?.medium?.fullUrl ||
                            'https://via.placeholder.com/640x360'
                          }
                        />
                      </Link>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <p className="font-weight-bolder mb-1">
                          <Link to={`/${post.slug}`}>{post.title}</Link>
                        </p>
                        <p>{post.excerpt}</p>
                      </div>
                      <footer style={{ bottom: '15px', position: 'absolute' }}>
                        <div className="text-muted small">{dateFormated}</div>
                      </footer>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row">
            <div className="col">
              <nav aria-label="Navigate Recent Posts">
                <ul className="pagination">
                  <li
                    className={`page-item ${pageContext.currentPage === 1 &&
                      'disabled'}`}
                  >
                    <Link
                      className="page-link"
                      to={`/news${getPagingRoute(pageContext.currentPage)}`}
                      tabIndex="-1"
                    >
                      Previous
                    </Link>
                  </li>
                  {Array.from({ length: pageContext.numPages }).map((_, i) => {
                    const pagePath = i === 0 ? `/news` : `/news/${i + 1}`;
                    return (
                      <li
                        key={i}
                        className={`page-item ${pageContext.currentPage ===
                          i + 1 && 'active'}`}
                      >
                        <Link className="page-link" to={pagePath}>
                          {i + 1}
                        </Link>
                      </li>
                    );
                  })}
                  <li
                    className={`page-item ${pageContext.currentPage ===
                      pageContext.numPages && 'disabled'}`}
                  >
                    <Link
                      className="page-link"
                      to={`/news/${pageContext.currentPage + 1}`}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

// const PostsPage = ({ pageContext, ...props }) => {
//   console.log('pageContext', pageContext);
//   console.log('props', props);
//   return (
//     <Layout searchbarLabel={pageContext.title}>
//       <SEO title={pageContext.seoTitle} />
//       <div className="container pt-3">
//         <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
//       </div>
//     </Layout>
//   );
// };

// export const query = graphql`
//  {
//   bhip {
//     properties {
//       id
//     }
//   }
// }
// `

export const query = graphql`
  query SitePostListing($skip: Int = 0, $first: Int = 5) {
    bhip {
      sitePagesConnection(
        where: { type: "post", status_in: PUBLISHED }
        skip: $skip
        first: $first
        orderBy: publishDate_DESC
      ) {
        edges {
          node {
            title
            excerpt
            slug
            content
            type
            seoTitle
            seoDescription
            publishDate
            featuredImage {
              alt
              file {
                versions {
                  key
                  fullUrl
                  mimetype
                }
              }
            }
          }
        }
      }
    }
  }
`;

// export const query = graphql`
//  {
//   bhip {
//     properties {
//       id
//     }
//   }
// }
// `
export default WithLocation(PostsPage);
