import React from 'react';
import { Layers } from 'react-feather';
import './styles.scss';
import RequestInfoForm from '../../components/RequestInfoForm';
const removeBlockContextMenuOption = {
  name: 'remove',
  type: 'remove',
  icon: 'close',
  label: 'Remove',
};

export const blocks = [
  {
    name: 'embed',
    type: 'embed',
    component: props => {
      // return require('../../components/Embed').default;
      return React.createElement(
        require('../../components/Embed').default,
        props
      );
    },
    // edit: {
    //   buttonIcon: 'toc',
    //   text: 'Form',
    // },
    // contextMenu: {
    //   name: 'sticky-jump-menu',
    //   menuOptions: [removeBlockContextMenuOption],
    //   update: element => {
    //     console.log('cms element', element);
    //     return element.dataset.stickyJumpMenu ? 'remove' : '';
    //   },
    // },
  },
  {
    name: 'last-minute-deals',
    type: 'last-minute-deals',
    component: props => {
      // return require('../../components/Embed').default;
      return React.createElement(
        require('../../components/LastMinuteDeals').default,
        props
      );
    },
    // edit: {
    //   buttonIcon: 'toc',
    //   text: 'Form',
    // },
    // contextMenu: {
    //   name: 'sticky-jump-menu',
    //   menuOptions: [removeBlockContextMenuOption],
    //   update: element => {
    //     console.log('cms element', element);
    //     return element.dataset.stickyJumpMenu ? 'remove' : '';
    //   },
    // },
  },
  {
    name: 'sticky-jump-menu',
    type: 'sticky-jump-menu',
    component: () => {
      return require('../../components/JumpMenu').default;
    },
    edit: {
      buttonIcon: 'toc',
      text: 'Form',
    },
    contextMenu: {
      name: 'sticky-jump-menu',
      menuOptions: [removeBlockContextMenuOption],
      update: element => {
        return element.dataset.stickyJumpMenu ? 'remove' : '';
      },
    },
  },
  {
    name: 'forms/contact-us',
    type: 'request-info-form',
    component: props => {
      return <RequestInfoForm {...props} />;
    },
    edit: {
      buttonIcon: 'toc',
      text: 'Form',
    },
    contextMenu: {
      name: 'request-info-form',
      menuOptions: [removeBlockContextMenuOption],
      update: element => {
        return element.dataset.requestInfoForm ? 'remove' : '';
      },
    },
  },
  {
    name: 'gallery',
    type: 'file-collection-gallery',
    component: () => {
      return require('../../components/Gallery').default;
    },
    toolbarMenuItems: [
      {
        type: 'file-collection-gallery',
        className: 'add-gallery',
        label: 'Gallery',
        name: 'gallery',
        customIcon: false,
      },
    ],
    contextMenu: {
      name: 'file-collection-gallery',
      menuOptions: [
        {
          name: 'file-collection-gallery',
          icon: 'gallery',
          type: 'edit',
          label: 'Edit Gallery',
        },
        removeBlockContextMenuOption,
        // {
        //   name: 'remove-gallery',
        //   type: 'remove',
        //   icon: 'gallery',
        //   label: 'Remove Gallery',
        // },
      ],
      update: element => {
        return element.dataset.fileCollectionGallery
          ? 'file-collection-gallery remove'
          : '';
      },
    },
    graphql: `
      query SiteFileCollection($siteFileCollectionId: Int) {
        siteFileCollection(where: {id: $siteFileCollectionId}) {
          id
          title
          files {
            siteFileInstanceId
            id
            fullUrl
            width
            height
            target
            destination
            caption
            alt
            sort
            mimetype
            versions {
              id
              key
              width
              height
              fullUrl
              mimetype
            }
          }
        }
      }
    `,
  },
  {
    name: 'image',
    type: 'file-instance',
    component: () => {
      return require('../../components/Media').default;
    },
    // edit: {
    //   buttonIcon: 'edit-image',
    //   text: 'Image',
    // },
    toolbarMenuItems: [
      {
        type: 'file-instance',
        className: 'add-image',
        label: 'Add Image',
        name: 'image',
        customIcon: false,
      },
    ],
    contextMenu: {
      name: 'file-instance',
      menuOptions: [
        {
          name: 'file-instance',
          type: 'edit',
          icon: 'image',
          label: 'Edit Image',
        },
        removeBlockContextMenuOption,
        // {
        //   name: 'remove-image',
        //   type: 'remove',
        //   icon: 'close',
        //   label: 'Remove Image',
        // },
      ],
      update: element => {
        return element.dataset.fileInstance ? 'file-instance remove' : '';
      },
    },
    graphql: `
    query SiteFileInstance($siteFileInstanceId: Int) {
      siteFileInstance(where: {id: $siteFileInstanceId}) {
        id
        alt
        caption
        destination
        file {
          mimetype
          id
          width
          height
          fullUrl
          versions {
            id
            key
            width
            height
            fullUrl
          }
        }
      }
    }
    `,
  },
  {
    name: 'slider',
    type: 'file-collection-slider',
    component: () => {
      return require('../../components/Slider').default;
    },
    // edit: {
    //   buttonIcon: 'layers',
    //   text: 'Slider',
    // },
    toolbarMenuItems: [
      {
        type: 'file-collection-slider',
        className: 'add-slider',
        label: 'Slider',
        name: 'layers',
        customIcon: true,
        icon: Layers,
      },
    ],
    contextMenu: {
      name: 'file-collection-slider',
      menuOptions: [
        {
          name: 'file-collection-slider',
          type: 'edit',
          customIcon: true,
          icon: 'layers',
          label: 'Edit Slider',
        },
        removeBlockContextMenuOption,
        // {
        //   name: 'remove-slider',
        //   type: 'remove',
        //   customIcon: true,
        //   icon: 'layers',
        //   label: 'Remove Slider',
        // },
      ],
      update: element => {
        return element.dataset.slider ? 'file-collection-slider remove' : '';
      },
    },

    graphql: `
      query SiteFileCollection($siteFileCollectionId: Int) {
        siteFileCollection(where: {id: $siteFileCollectionId}) {
          id
          title
          files {
            id
            fullUrl
            width
            height
            target
            destination
            caption
            alt
            sort
            mimetype
            versions {
              id
              key
              width
              height
              fullUrl
              mimetype
            }
          }
        }
      }
    `,
  },
];
