import React from 'react';
import './styles.scss';

const Loading = ({ type }) => {
  return (
    <>
      {type === 'dots' ? (
        <div className="dot-pulse"></div>
      ) : (
        <div className="loader m-auto"></div>
      )}
    </>
  );
};

const Dots = () => {
  return (
    <>
      <div className="loading-dots">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </>
  );
};

export default Loading;
export { Dots };
