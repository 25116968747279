module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-bhip-cms/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://api.beachhousesinparadise.com/graphql","fieldName":"bhip","typeName":"BHIP","blockDefinitionFile":"/app/src/util/cms/blocks.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
