import React, { useEffect } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { navigate, graphql } from 'gatsby';
// import RequestInfoForm from '../../components/RequestInfoForm';
import AvailabilityCalendar from './components/AvailabilityCalendar';
import Loading from '../../components/Loading';
import SEO from '../../components/seo';
import Layout from '../../layouts/DefaultLayout';
import { addDays } from '../../util/dateHelper';

// import { Calendar } from '@fullcalendar/core';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
// import bootstrapPlugin from '@fullcalendar/bootstrap';
// import flatMap from 'lodash/flatmap';
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timeline/main.css';
// import '@fullcalendar/resource-timeline/main.css';
// import './styles.scss';

const availabilityCalendarToEvents = property => {
  const { start, availability: availabilityString } = property.calendar;
  let day = start;
  const calendar = availabilityString.split('').reduce((acc, availability) => {
    if (availability === 'N') {
      acc.push({
        start: day, // formatDate(day),
        resourceId: property.id,
      });
    }
    day = addDays(day, 1);
    return acc;
  }, []);
  return calendar;
};

const PROPERTIES_QUERY = gql`
  query Properties {
    properties {
      id
      key
      label
      title: name
      type
      sort
      bedrooms
      bathrooms
      sleeps
      ... on PropertyCombo {
        childProperties {
          name
          key
        }
      }
      calendar {
        start
        end
        availability
        checkInAvailability
        checkOutAvailability
        minStay
        minStayThresholdAlert
      }
      sitePage {
        slug
        title
        status
        featuredImageSecondary {
          file {
            version(where: { key: "small" }) {
              fullUrl
            }
          }
        }
      }
    }
  }
`;

const AvailabilityCalendarPage = ({ data, pageContext }) => {
  const { sitePage } = data.bhip;
  return (
    <Layout searchbarLabel={sitePage.title}>
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        headTags={sitePage.headTags}
      />
      <div
        className="mt-2 rotate-screen-indicator text-center"
        data-rotate-screen-indicator
      >
        <span>
          <img width="20" height="20" src="/rotate-device.svg" /> Rotate your
          device for the best display.
        </span>
      </div>
      <div className="container mt-1 mt-lg-1plus mb-3 scroll-table-container">
        <Query query={PROPERTIES_QUERY} fetchPolicy="no-cache">
          {({ data, loading, error }) => {
            if (loading)
              return (
                <div className="mt-2">
                  <Loading />
                </div>
              );
            if (error) return <p>Error: ${error.message}</p>;
            const properties = data?.properties;

            const filteredPropertiesWithoutCombos = properties.filter(
              property =>
                property.type !== 'COMBO' &&
                property.sitePage != null &&
                property.calendar.availability
            );

            const comboProperties = properties.filter(property => {
              return (
                property.type === 'COMBO' &&
                property?.sitePage?.slug &&
                property.sitePage?.status === 'PUBLISHED'
              );
            });

            const propertiesForDisplay = [
              ...filteredPropertiesWithoutCombos,
              ...comboProperties,
            ];

            const resources = propertiesForDisplay;
            const events = propertiesForDisplay.reduce((acc, property) => {
              const events = availabilityCalendarToEvents(property);
              acc.push(...events);
              return acc;
            }, []);

            return (
              <div className="no-gutters-sm-down">
                <AvailabilityCalendar
                  resources={resources}
                  events={events}
                  days={14}
                />
              </div>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

export default AvailabilityCalendarPage;
export const query = graphql`
  query Availability($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
    }
  }
`;
