import React from 'react';
import SEO from '../components/seo';
import Layout from '../layouts/DefaultLayout';

const NotFoundPage = ({ pageContext, ...props }) => {
  return (
    <Layout searchbarLabel="Welcome">
      <SEO title="Welcome" description="Welcome" />
      <div className="container mt-3"></div>
    </Layout>
  );
};

export default NotFoundPage;
