import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../layouts/DefaultLayout';
import SEO from '../../components/seo';
import VirtualTour from '../../components/VirtualTour';

import styles from './styles.module.scss';
import { Link } from 'gatsby';

const VirtualTours = ({ data, pageContext }) => {
  const { properties, sitePage } = data.bhip;
  return (
    <Layout searchbarLabel={sitePage.title}>
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        headTags={sitePage.headTags}
      />
      <div className={`container`}>
        <p className="mt-3 mb-0">
          Take virtual tours of all our properties via MatterPort 3D by clicking
          each image.
        </p>
        {properties &&
          properties
            .filter(
              property =>
                property.sitePage !== null &&
                property?.virtualTours[0] &&
                property.sitePage.status === 'PUBLISHED'
            )
            .map(property => {
              // const { alt } = property?.virtualTours[0]?.thumbnailImage || {};

              // const version =
              //   property?.virtualTours[0]?.thumbnailImage?.file?.version || {};
              // const virtualToursUrl = property?.virtualTours[0]?.url || '';
              // const { fullUrl } = version;
              return (
                <div
                  key={property.name}
                  className={`px-0 ${styles.innerContainer}`}
                >
                  <h2 className="pt-3">"{property.name}"</h2>
                  <div className="row">
                    <div
                      className={`col-12 col-lg-6 mb-3 ${styles.virtualTourColumn}`}
                    >
                      <VirtualTour
                        propertyVirtualTour={property?.virtualTours[0]}
                        withIcon={true}
                      />
                    </div>
                    <div className="col-12 col-lg-6">
                      <p className="mb-3">{property.sitePage.excerpt}</p>
                      <Link
                        to={`/${property.sitePage.slug}`}
                        className={`${styles.detailsLink} btn btn-outline-primary font-weight-bold mb-4`}
                      >
                        View More Details
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query VirtualTourPage($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      properties {
        name
        key
        virtualTours {
          url
          additionalMessaging
          thumbnailImage {
            alt
            destination
            # caption
            file {
              versions {
                key
                fullUrl
                height
                width
              }
            }
          }
        }
        sitePage {
          status
          excerpt
          slug
        }
      }
    }
  }
`;

export default VirtualTours;
