import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import { Portal, PortalWithState } from 'react-portal';
// import Modal from './Modal';
// import BodyEnd from '../BodyEnd';
// import photoswipe from '../../util/photoswipe';

import LightBox from '../LightBox';
import LazyImage from '../LazyImage';
import { getVersionMap } from '../../util/helpers';

const Gallery = ({
  siteFileCollection,
  align,
  size,
  columns,
  customStyles,
  ...props
}) => {
  //let galleryParent = null;
  // useEffect(() => {
  //   photoswipe(galleryParent);
  // }, []);
  // const galleryRef = React.createRef();

  const isSingleColumn = Number(columns) === 1;
  return (
    <>
      <LightBox>
        <ul
          // ref={el => (galleryParent = el)}
          // ref={galleryRef}
          className={`wrapper bhip-block-gallery align${
            isSingleColumn && !align ? 'center' : align
          } ${!isSingleColumn ? 'is-cropped' : ''} columns-${columns}`}
          style={
            isSingleColumn
              ? { maxWidth: '1024px', marginLeft: 'auto', marginRight: 'auto' }
              : {}
          }
          itemScope
          itemType="http://schema.org/ImageGallery"
        >
          {siteFileCollection &&
            siteFileCollection?.files
              .filter(file => file?.mimetype?.includes('image/'))
              .map((file, index) => {
                const versionMap = getVersionMap(file) || {};
                const thumbnail = isSingleColumn
                  ? versionMap?.large
                  : versionMap?.medium;
                const aspectRatioPadding =
                  (thumbnail.height / thumbnail.width) * 100;
                const style = {
                  paddingTop: `${aspectRatioPadding}%`,
                };
                return (
                  <li key={index} className={`blocks-gallery-item`}>
                    <figure
                      itemProp="associatedMedia"
                      itemScope
                      itemType="http://schema.org/ImageObject"
                    >
                      <a
                        href={file?.fullUrl}
                        className={`lightbox-image aspect`}
                        itemProp="contentUrl"
                        data-size={`${file?.width}x${file?.height}`}
                        data-size-mobile={`${versionMap &&
                          versionMap.xlarge.width}x${versionMap &&
                          versionMap.xlarge.height}`}
                        data-href-mobile={
                          versionMap && versionMap?.xlarge?.fullUrl
                        }
                        style={style}
                      >
                        {versionMap && (
                          <LazyImage
                            partOfCollection={true}
                            collectionType={'gallery'}
                            src={versionMap.tiny.fullUrl}
                            srcset={`${thumbnail.fullUrl} ${thumbnail.width}w`}
                            alt={file.alt}
                            data-id={siteFileCollection.id}
                            className={`bhip-image-${file.id}`}
                            itemProp="thumbnail"
                          />
                        )}
                      </a>
                    </figure>
                  </li>
                );
              })}
        </ul>
      </LightBox>
    </>
  );
};

Gallery.defaultProps = {
  columns: 3,
};

// Gallery.propTypes = {
//   data: PropTypes.object,
//   align: PropTypes.string,
//   size: PropTypes.string,
//   columns: PropTypes.integer,
// };

export default Gallery;
