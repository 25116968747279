import React, { useState } from 'react';
import SEO from '../../components/seo';
import Layout from '../../layouts/DefaultLayout';
import CMSContent from '../../components/CMSContent';
import FAQEntryList from '../../components/FAQEntryList';
import Modal from '../../components/Modal';
import SharePhotosForm from '../page-share-photos/components/SharePhotosForm';
import ShareFeedbackForm from './components/ShareFeedbackForm';
import { graphql } from 'gatsby';

const ShareFeedback = ({ pageContext, location, ...props }) => {
  const { data } = props;
  const { sitePage } = data.bhip;
  const properties = data?.bhip?.properties || null;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [token, setToken] = useState(null);
  return (
    <Layout searchbarLabel={sitePage.title}>
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        featuredImage={sitePage.featuredImage}
        headTags={sitePage.headTags}
      />
      <div className="container mt-3">
        <div className="mb-3">
          <CMSContent blockData={sitePage.cmsContent.blockData}>
            {sitePage.cmsContent.html}
          </CMSContent>
        </div>
        <ShareFeedbackForm
          properties={properties}
          location={location}
          onSuccess={token => {
            setToken(token);
            setModalIsOpen(true);
          }}
          token={token}
          create={true}
        />
      </div>
      <Modal
        disableFocusTrap={true}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        expanded={true}
        title={
          <span style={{ fontSize: '20px' }}>
            Thank you! Would you like to provide us feedback about your stay?
          </span>
        }
      >
        <SharePhotosForm
          create={false}
          onSuccess={() => setModalIsOpen(true)}
          token={token}
        />
      </Modal>
    </Layout>
  );
};
export const query = graphql`
  query ShareFeedback($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      properties {
        id
        name
      }
    }
  }
`;

export default ShareFeedback;
