import React from 'react';
import { Location } from '@reach/router';
import queryString from 'query-string';

/* this could use a refactor so location.location isn't needed. All implementations would need to be updated, but it shoud follow this model
https://medium.com/@chrisfitkin/how-to-get-query-string-parameter-values-in-gatsby-f714161104f
Note the destructuring  ({location, navigation, search })
*/
function withLocation(WrappedComponent) {
  return props => (
    <Location>
      {location => (
        <WrappedComponent
          location={location}
          search={
            location.location.search
              ? queryString.parse(location.location.search)
              : {}
          }
          {...props}
        />
      )}
    </Location>
  );
}

export default withLocation;
