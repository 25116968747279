import React, { useState } from 'react';
import Modal from '../../../../components/Modal';
import PropertySearchForm from '../../../../components/PropertySearchForm';

const MobileSearchForm = () => {
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        key="mobile-search-button"
        id="mobile-search-button"
        className="btn btn-primary d-inline-block d-lg-none font-weight-bold text-primary btn btn-secondary rounded"
        onClick={() => setSearchModalIsOpen(true)}
      >
        Search Rentals
      </button>
      <Modal
        isOpen={searchModalIsOpen}
        title={'Beach House Rentals'}
        onClose={() => setSearchModalIsOpen(false)}
      >
        <PropertySearchForm
          modalIsOpen={searchModalIsOpen}
          id="header-search-form"
        />
      </Modal>
    </>
  );
};

export default MobileSearchForm;
