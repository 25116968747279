import React, { useState } from 'react';
import styles from './styles.module.scss';

const GuestCounterInput = ({ formData, formikProps, domKey, onChange }) => {
  const { values, touched, errors } = formikProps;
  const [inputValue, setInputValue] = useState(formData.value);
  return (
    <div
      className="form-group d-flex justify-content-between align-items-center mb-3 guest-counter-input"
      key={domKey}
    >
      <label htmlFor={formData.key}>
        <span>{inputValue === '' ? '0' : inputValue}</span>{' '}
        <span>{formData.label}</span>
        {/* <div className="small text-danger mt-1">
          {touched.numberOfAdults &&
            errors.numberOfAdults &&
            errors.numberOfAdults}
        </div> */}
      </label>
      <div>
        <button
          disabled={inputValue === null || inputValue === 0}
          className={`btn btn-link p-0`}
          type="button"
        >
          <img
            src={
              inputValue === '' || inputValue === 0
                ? '/minus-disabled.svg'
                : '/minus.svg'
            }
            alt="minus"
            // id={`datepicker-next-button${id}`}
            width="48"
            height="48"
            className="no-resize"
            onClick={() => {
              setInputValue(prevValue => {
                onChange(Number(prevValue) - 1);
                return Number(prevValue) - 1;
              });
            }}
          />
        </button>
        <button
          type="button"
          disabled={Number(formData.value) === 99}
          className={`btn btn-link p-0 ${styles.plusButton}`}
          onClick={() => {
            setInputValue(prevValue => {
              onChange(Number(prevValue) + 1);
              return Number(prevValue) + 1;
            });
          }}
        >
          <img
            src={Number(inputValue) === 99 ? '/plus-disabled.svg' : '/plus.svg'}
            alt="Plus"
            // id={`datepicker-next-button${id}`}
            width="48"
            height="48"
            className="no-resize counter-button"
          />
        </button>
      </div>
    </div>
  );
};

const getGuestInfoSelectLabel = values => {
  const guestInfoLabelMap = {
    numberOfAdults: 'Adults',
    numberOfChildren: 'Children',
    numberOfPets: 'Pets',
    numberOfVehicles: 'Vehicles',
    numberOfVisitors: 'Visitors',
  };
  const label = values.reduce((acc, item, index) => {
    if (Number(item.value) > 0) {
      acc += `${item.value} ${guestInfoLabelMap[item.key]}, `;
    }

    return acc;
  }, '');
  // label.reverse()
  return label.length > 1 ? label.slice(0, label.length - 2) : 'Guest info';
};

const GuestCounterForm = ({ formikProps, onSubmit }) => {
  const { touched, errors, values, setFieldValue } = formikProps;

  const [guestFormData, setGuestFormData] = useState([
    { label: 'Adults', value: values.numberOfAdults, key: 'numberOfAdults' },
    {
      label: 'Children',
      value: values.numberOfChildren,
      key: 'numberOfChildren',
    },
    { label: 'Pets', value: values.numberOfPets, key: 'numberOfPets' },
    {
      label: 'Vehicles',
      value: values.numberOfVehicles,
      key: 'numberOfVehicles',
    },
    {
      label: 'Visitors',
      value: values.numberOfVisitors,
      key: 'numberOfVisitors',
    },
  ]);

  return (
    <div className="p-2">
      {guestFormData.map((formData, index) => {
        return (
          <React.Fragment key={formData.key}>
            <GuestCounterInput
              domKey={formData.key}
              formData={formData}
              formikProps={formikProps}
              onChange={inputValue => {
                const updatedGuestFormData = guestFormData.reduce(
                  (acc, item, index) => {
                    if (item.key === formData.key) {
                      acc.push({ ...formData, value: inputValue });
                    } else {
                      acc.push(item);
                    }
                    return acc;
                  },
                  []
                );
                setGuestFormData(updatedGuestFormData);
              }}
            />
          </React.Fragment>
        );
      })}

      <button
        className="btn btn-outline-primary"
        onClick={() => {
          guestFormData.forEach(item => {
            setFieldValue(item.key, item.value);
          });
          onSubmit(getGuestInfoSelectLabel(guestFormData), guestFormData);
        }}
        type="button"
        data-close
      >
        Apply
      </button>
    </div>
  );
};

export default GuestCounterForm;
