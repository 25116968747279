import React from 'react';

const AddressDisplay = ({ siteMeta }) => {
  return (
    <>
      <p className="mr-2 mb-0">{siteMeta.siteName}</p>
      <p className="mr-2 mb-0">{siteMeta.primaryAddress}</p>
    </>
  );
};

export default AddressDisplay;
