import React from 'react';
import { graphql } from 'gatsby';
// import { Link } from "gatsby"

import SEO from '../../components/seo';
import LazyImage from '../../components/LazyImage';
import BeachHouseCompoundsText from '../../components/BeachHouseCompoundsText';
import Layout from '../../layouts/DefaultLayout';
import { Link } from 'gatsby';
import styles from './styles.module.scss';
import CMSContent from '../../components/CMSContent';

import { getYoutubeVideoId } from '../../util/helpers';

const VideoTours = ({ pageContext, data }) => {
  const { properties, sitePage } = data.bhip;
  const propertiesWithoutCombos = properties.filter(
    property =>
      property?.type !== 'COMBO' &&
      property?.sitePage?.featuredFileCollection &&
      property?.sitePage?.status === 'PUBLISHED'
  );
  const propertiesWithCombos = properties.filter(
    property =>
      property?.type === 'COMBO' &&
      property?.sitePage?.featuredFileCollection &&
      property?.sitePage?.status === 'PUBLISHED'
  );
  const sortedPropertiesByCombo = [
    ...propertiesWithoutCombos,
    ...propertiesWithCombos,
  ];

  return (
    <Layout searchbarLabel={sitePage.title}>
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        headTags={sitePage.headTags}
      />
      <div className={`container`}>
        {sortedPropertiesByCombo &&
          sortedPropertiesByCombo
            // .filter(
            //   property =>
            //     property.type === 'STANDARD' &&
            //     property?.sitePage?.status === 'PUBLISHED'
            // )
            .filter(property => property?.sitePage?.status)
            .map((property, index) => {
              const files =
                property?.sitePage?.featuredFileCollection?.files || [];
              const videoFile =
                files.find(file => file.mimetype === 'video/youtube') || null;
              if (!videoFile) {
                return null;
              }
              const { fullUrl } = videoFile;
              return (
                <div
                  key={property.id}
                  className={`px-0 ${styles.innerContainer}`}
                >
                  {sortedPropertiesByCombo[index]?.type === 'COMBO' &&
                    sortedPropertiesByCombo[index - 1]?.type === 'STANDARD' && (
                      <div className="mt-2">
                        <BeachHouseCompoundsText />
                      </div>
                    )}
                  <h2 className="pt-3">"{property.name}"</h2>
                  <div key={property.id} className="row">
                    <div className="col-12 col-lg-6 mb-4">
                      {fullUrl && (
                        <a
                          className="aspect ratio-16x9 no-gutters-sm-down"
                          href={`//www.youtube.com/embed/${getYoutubeVideoId(
                            fullUrl
                          )}`}
                        >
                          <LazyImage
                            src={`https://img.youtube.com/vi/${getYoutubeVideoId(
                              fullUrl
                            )}/default.jpg`}
                            iframeSrc={`//www.youtube.com/embed/${getYoutubeVideoId(
                              fullUrl
                            )}`}
                          />
                        </a>
                      )}
                    </div>
                    <div className="col-12 col-lg-6">
                      <p className="mb-3">
                        {' '}
                        <CMSContent blockData={sitePage?.cmsContent?.blockData}>
                          {property.sitePage.excerpt}
                        </CMSContent>
                      </p>
                      <Link
                        to={`/${property.sitePage.slug}`}
                        className={`${styles.detailsLink} btn btn-outline-primary font-weight-bold mb-4`}
                      >
                        View More Details
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query VideoTourPage($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      properties {
        id
        name
        type
        sitePage {
          status
          featuredFileCollection {
            files {
              mimetype
              fullUrl
            }
          }
          excerpt
          slug
        }
      }
    }
  }
`;
export default VideoTours;
