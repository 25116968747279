export const objectIsEmpty = obj => Object.keys(obj).length === 0;

export const groupBy = (arr, key) => {
  return arr.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
};

export const getYoutubeVideoId = url => {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = (url || '').match(regExp);

  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return 'error';
  }
};

export const getThumbnailUrl = (file = {}, versions = [], pluck = 'small') => {
  const groupedVersions = Array.isArray(versions)
    ? groupBy(versions, 'key')
    : versions;
  if ((file?.mimetype || '').includes('image/')) {
    return (groupedVersions[pluck] || file).fullUrl;
  }
  if ((file?.mimetype || '').includes('video/youtube')) {
    return `https://img.youtube.com/vi/${getYoutubeVideoId(
      file.fullUrl
    )}/default.jpg`;
  }
  return null;
};

export const getVersionMap = file => {
  if (!file?.versions) return null;

  return file?.versions.reduce((acc, version) => {
    acc[version.key] = version;
    return acc;
  }, {});
};

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};
