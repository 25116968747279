import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
// // import { Portal, PortalWithState } from 'react-portal';
// // import Modal from './Modal';
// // import BodyEnd from '../BodyEnd';
// // import photoswipe from '../../util/photoswipe';
// // import LightBox from '../LightBox';
// import photoswipe from '../../util/photoswipe';
// import { renderToString } from 'react-dom/server';
import { getVersionMap } from '../../util/helpers';
import './styles.scss';

const VirtualTour = ({
  propertyVirtualTour,
  thumbnailVersion = 'medium',
  withIcon,
}) => {
  if (!propertyVirtualTour) return null;
  const {
    url,
    additionalMessaging,
    thumbnailImage,
    property,
  } = propertyVirtualTour;

  const versionMap = thumbnailImage && getVersionMap(thumbnailImage.file);

  return (
    <>
      {property && (
        <div>
          Take the {property.name}{' '}
          <a
            href={url}
            onClick={
              additionalMessaging
                ? () => {
                    alert(additionalMessaging);
                  }
                : null
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            virtual tour
          </a>{' '}
          via MatterPort 3D (or click the following image)
        </div>
      )}

      <figure
        className={`mx-0 bhip-block-image no-gutters-sm-down position-relative d-inline-block h-100 w-100`}
      >
        <a
          href={url}
          onClick={
            additionalMessaging
              ? () => {
                  alert(additionalMessaging);
                }
              : null
          }
          target="_blank"
          rel="noopener noreferrer"
          className="aspect ratio-7x4 h-100 w-100"
        >
          {versionMap && versionMap[thumbnailVersion] && (
            <div
              className={`${withIcon && 'image-figure'} ${property &&
                'property'}`}
            >
              <img
                src={versionMap[thumbnailVersion].fullUrl}
                alt={thumbnailImage.alt && 'Virtual Tour'}
              />
            </div>
          )}
        </a>
        {property && (
          <figcaption>
            To explore {property.name} in 3D, click the image
          </figcaption>
        )}
      </figure>
    </>
  );
};

VirtualTour.defaultProps = {
  withIcon: false,
};
export default VirtualTour;
