import React, { useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import CMSContent from '../../components/CMSContent';
import FAQEntryList from '../../components/FAQEntryList';
import Layout from '../../layouts/DefaultLayout';

import styles from './styles.module.scss';
import { Link } from 'gatsby';

import './styles.scss';

const PostPage = ({ data, pageContext, location }) => {
  const params = new URLSearchParams(location.search);
  const isPreview = params.get('cmspreview') ? true : false;
  const { sitePage } = data.bhip;

  const loadedImage = useRef(null);
  const featuredImage = sitePage.featuredImage;

  const getImageUrl = windowWidth => {
    // not serving xlarge and bigger images to improve LCP
    if (!featuredImage) {
      return 'https://via.placeholder.com/640x360';
    }
    // if (windowWidth >= 1900) {
    //   return featuredImage.file.fullUrl;
    // } else if (windowWidth >= 1600) {
    //   const { fullUrl } = featuredImage.file.versions.find(
    //     version => version.key === 'xlarge'
    //   );
    //   return fullUrl;
    // } else
    if (windowWidth >= 1024) {
      const { fullUrl } = featuredImage.file.versions.find(
        version => version.key === 'large'
      );
      return fullUrl;
    } else if (windowWidth >= 640) {
      const { fullUrl } = featuredImage.file.versions.find(
        version => version.key === 'medium'
      );
      return fullUrl;
    }
    const { fullUrl } = featuredImage.file.versions.find(
      version => version.key === 'small'
    );
    return fullUrl;
  };

  const handleWindowResize = () =>
    setLoadedImageAttributes(getImageUrl($('body').innerWidth()), loadedImage);

  const setLoadedImageAttributes = (imageUrl, loadedImage) => {
    loadedImage.current &&
      loadedImage.current.setAttribute(
        'style',
        `background-image: url('${imageUrl}');`
      );
    loadedImage?.current?.classList?.add(styles.imageFadeIn);
  };
  useEffect(() => {
    const hdLoaderImg = new Image();
    const url = sitePage?.featuredImage?.file?.fullUrl;
    hdLoaderImg.src = url ? url : 'https://via.placeholder.com/640x360';
    hdLoaderImg.onload = () => {
      // load image attributes into ref on page load
      setLoadedImageAttributes(
        getImageUrl($('body').innerWidth()),
        loadedImage
      );
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
      hdLoaderImg.onload = null;
    };
  }, [featuredImage]);

  const publishDate = sitePage.publishDate;
  const postPublishDate = publishDate ? new Date(publishDate) : new Date();
  const dateFormated = `${new Date(postPublishDate).toLocaleString('en-us', {
    weekday: 'long',
  })}, ${new Date(postPublishDate).toLocaleString('en-us', {
    month: 'long',
  })} ${postPublishDate.getDate()} ${postPublishDate.getFullYear()}`;
  const preLoadImages = sitePage?.featuredImage?.file;
  const preLoadImage = preLoadImages
    ? preLoadImages.versions.find(version => version.key === 'tiny')?.fullUrl ||
      'https://via.placeholder.com/640x360'
    : 'https://via.placeholder.com/640x360';
  // const heroProps = {};
  // if (!isPreview) {
  //   heroProps.ref = imageLoadedElem => (loadedImage.current = imageLoadedElem);
  // }

  return (
    <Layout subheader={false} searchbarLabel={sitePage.title}>
      {/* <img src={featuredImage?.file?.fullUrl} /> */}
      <SEO
        title={sitePage.seoTitle}
        description={sitePage.seoDescription}
        featuredImage={sitePage.featuredImage}
        headTags={sitePage.headTags}
      />
      <div className={`${styles.imageContainer} mb-2`}>
        <div
          className={`${styles.heroImage} ${styles.imageLoaded}`}
          ref={imageLoadedElem => (loadedImage.current = imageLoadedElem)}
        />
        <div
          className={`${styles.heroImage} ${styles.imagePreload}`}
          style={{
            backgroundImage: `url(${preLoadImage})`,
            // height: '320px',
          }}
        />
      </div>
      {/* <div className={`${styles.imageContainer} mb-2`}>
        <div
          className={`${styles.heroImage} ${styles.imageLoaded}`}
          {...heroProps}
        />
        <div
          className={`${styles.heroImage} ${styles.imagePreload}`}
          style={{
            backgroundImage: `url(${
              isPreview ? featuredImage.file.fullUrl : preLoadImage
            })`,
          }}
        />
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-9 mb-3">
            <h1 className="font-weight-normal">{sitePage.title}</h1>
            <div
              className={`${styles.postSubHeader} d-flex justify-content-start`}
            >
              <p id="post-sub-header">{dateFormated}</p>
            </div>
            <article id="cms-content-container" className="col-12 pr-lg-3 p-0">
              <CMSContent blockData={sitePage.cmsContent.blockData}>
                {sitePage.cmsContent.html}
              </CMSContent>
              <FAQEntryList
                faqEntries={sitePage.faqEntries}
                className="mt-4"
              ></FAQEntryList>
            </article>
          </div>
          <aside className="col-12 col-lg-3">
            <p className="font-weight-bold mb-2">
              You might also be interested in
            </p>
            <>
              {sitePage.relatedSitePages.reduce((acc, post, index) => {
                const publishDate = new Date(post.publishDate);
                const dateFormated = `${new Date(
                  post.publishDate
                ).toLocaleString('en-us', {
                  month: 'long',
                })} ${publishDate.getDate()}, ${publishDate.getFullYear()}`;

                const { versions = [] } =
                  (post.featuredImage || {})?.file || {};
                var thumbnailVersions = versions.reduce((acc, version) => {
                  acc[version.key] = { ...version };
                  return acc;
                }, {});
                if (index <= 2) {
                  acc.push(
                    <div
                      key={post.slug}
                      className="card shadow-sm rounded mb-3"
                    >
                      <div className="aspect ratio-16x9">
                        <Link to={`/${post.slug}`}>
                          <img
                            className="card-img-top"
                            src={
                              thumbnailVersions?.medium?.fullUrl ||
                              'https://via.placeholder.com/640x360'
                            }
                          />
                        </Link>
                      </div>
                      <div className="card-body">
                        <div className="card-text">
                          <p className="font-weight-bolder mb-1">
                            <Link to={`/${post.slug}`}>{post.title}</Link>
                          </p>
                          <div className="text-muted small">{dateFormated}</div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return acc;
              }, [])}

              <Link to="/news">See all news</Link>
            </>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SinglePost($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...RelatedSitePages
        ...SitePageData
      }
    }
  }
`;

export default PostPage;
