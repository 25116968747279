import React, { useEffect } from 'react';

const DatePickerNext = ({
  calendarPropertyData,
  selectedMonth,
  selectedYear,
  id,
}) => {
  useEffect(() => {
    selectedMonth ===
      new Date(
        calendarPropertyData ? calendarPropertyData.end : ''
      ).getMonth() +
        1 &&
    selectedYear ===
      new Date(
        calendarPropertyData ? calendarPropertyData.end : ''
      ).getFullYear()
      ? $(`#datepicker-next-button${id}`).addClass('disable-navigation')
      : $(`#datepicker-next-button${id}`).removeClass('disable-navigation');
  });
  return (
    <img
      src="/next.svg"
      alt="Next"
      id={`datepicker-next-button${id}`}
      width="30"
      height="40"
      className="no-resize"
    />
  );
};

export default DatePickerNext;
