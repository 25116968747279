import React, { useEffect } from 'react';
import PropertySearchbarHeader from '../../components/PropertySearchbarHeader';
import HeaderMenu from '../../components/HeaderMenu';
import Footer from '../../components/Footer';
import JumpMenu from '../../components/JumpMenu';
import { Modal as LastMinuteDealsModal } from '../../components/LastMinuteDeals';
// import Modal from '../components/Modal';

const SinglePropertyLayout = ({
  children,
  postChildrenComponents,
  jumpMenuOptions,
  showPropertyLegalText,
  customBottomPosition,
  ...props
}) => {
  return (
    <>
      <HeaderMenu />
      <div className={`bg-primary`}>
        <div className="container">
          <div className="row">
            <div className="col">
              <PropertySearchbarHeader withModal={true} {...props} />
            </div>
          </div>
        </div>
      </div>
      <div className="sticky-row sticky-shadow">
        <div className="container bg-white mb-2">
          <div className="row">
            <div className={`col py-0plus`}>
              {/* <PropertyJumpMenu guestBook={hasGuestBook} /> */}
              <JumpMenu jumpMenuOptions={jumpMenuOptions} />
            </div>
          </div>
        </div>
      </div>
      <main>{children}</main>
      <Footer />
      {postChildrenComponents}
    </>
  );
};

SinglePropertyLayout.defualtProps = {
  jumpMenuOptions: [],
  customBottomPosition: false,
};

export default SinglePropertyLayout;
